import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@pbds/typography'
import { Link } from 'gatsby'
import { PrimaryButton } from '@pbds/buttons'
import { StyledBox } from './ComponentStyled'

const ElementMediaOverlay = ({ heading, subHeading, linkHref, linkText }) => (
  <StyledBox
    maxWidth={560}
    flexAlign="middleCenter"
    paddingX={['--spacing-02', '--spacing-03']}
  >
    {heading && (
      <Typography.Heading
        color="white"
        textAlign="center"
        variant="--heading-1"
        marginY={['--spacing-02', '--spacing-02', '--spacing-03']}
      >
        {heading}
      </Typography.Heading>
    )}

    {subHeading && (
      <Typography.Body
        color="white"
        textAlign="center"
        marginBottom="--spacing-04"
        marginX={['--spacing-02', '--spacing-03']}
      >
        {subHeading}
      </Typography.Body>
    )}

    {linkText && (
      <PrimaryButton
        to={linkHref}
        rightIcon="playButton"
        useInverseColors={true}
        buttonSize="--large-button"
        isFullWidthOnMobile={false}
        buttonType={linkHref ? Link : null}
      >
        {linkText}
      </PrimaryButton>
    )}
  </StyledBox>
)

ElementMediaOverlay.defaultProps = {
  heading: '',
  subHeading: '',
  linkHref: '',
  linkText: '',
}

ElementMediaOverlay.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  linkHref: PropTypes.string,
  linkText: PropTypes.string,
}

export default ElementMediaOverlay
