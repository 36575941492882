import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image/withIEPolyfill'
import Grid from '@pbds/grid'
import Box from '@pbds/box'
import Link from '@pbds/link'
import Typography from '@pbds/typography'
import {
  imageShape,
  imageDefaultProps,
  structuredTextShape,
} from '../../shared/proptypes'

const { Row, Col } = Grid
const { Heading, Body, Deck } = Typography

const BlockMissionIcon = ({ heading, image, structuredParagraph }) => {
  const paragraphs = structuredParagraph?.value?.document?.children || []

  const elemRenderer = (ele, i) => {
    if (ele.type === 'span') {
      return <span key={i}>{ele.value}</span>
    } else if (ele.type === 'link') {
      return (
        <Link style={{ display: 'inline-block' }} to={ele.url} key={i}>
          {ele.children.map(elemRenderer)}
        </Link>
      )
    }
  }
  const paragraphRenderer = (paragraph, i) => (
    <Deck key={i}>{paragraph.children.map(elemRenderer)}</Deck>
  )

  return (
    <Box
      as="section"
      paddingY={['--spacing-08', '--spacing-09', '--spacing-10']}
    >
      <Grid>
        <Row lgReverse={true} verticalAlign="middle">
          <Col xs={0} lg={1} />
          <Col xs={4} xsOffset={4} lg={3} lgOffset={1} horizontalAlign="center">
            <Box
              maxWidth={['180px', '180px', '180px', '220px']}
              margin={['0 auto', '0 auto', '0 auto', '0 0']}
              paddingBottom={[
                '--spacing-07',
                '--spacing-07',
                '--spacing-07',
                0,
              ]}
            >
              {image.fluid ? (
                <Img
                  width="100%"
                  fluid={image.fluid}
                  alt={image.alt}
                  role="presentation"
                />
              ) : (
                <img
                  src={image.url}
                  alt={image.alt}
                  width="100%"
                  role="presentation"
                />
              )}
            </Box>
          </Col>
          <Col xs={12} lg={5} horizontalAlign={{ xs: 'center', lg: 'left' }}>
            <Grid margin={false} gutter={false}>
              <Row>
                <Col xs={12} md={8} mdOffset={2} lg={12} lgOffset={0}>
                  <Heading
                    variant="--heading-2"
                    paddingBottom={[
                      '--spacing-02',
                      '--spacing-02',
                      '--spacing-02',
                      '--spacing-04',
                    ]}
                  >
                    {heading}
                  </Heading>
                </Col>
                <Col xs={12} md={8} mdOffset={2} lg={12} lgOffset={0}>
                  {paragraphs.map(paragraphRenderer)}
                </Col>
              </Row>
            </Grid>
          </Col>
          <Col xs={0} lg={2} />
        </Row>
      </Grid>
    </Box>
  )
}

BlockMissionIcon.defaultProps = {
  heading: '',
  body: '',
  structuredParagraph: {
    value: {
      document: {
        children: [],
      },
    },
  },
  image: { ...imageDefaultProps, url: '' },
}

BlockMissionIcon.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.string,
  structuredParagraph: structuredTextShape,
  image: { ...imageShape, url: PropTypes.string },
}

export default BlockMissionIcon
