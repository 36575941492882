import React from 'react'

const GrayHotdog = ({ className }) => {
  return (
    <svg
      className={className}
      width="420"
      height="148"
      viewBox="0 0 420 148"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        d="M346.044 147.111H74.683C33.9167 147.111 0.863281 114.202 0.863281 73.6087C0.863281 33.0204 33.9167 0.111328 74.683 0.111328H346.044C386.815 0.111328 419.863 33.0204 419.863 73.6087C419.863 114.202 386.815 147.111 346.044 147.111Z"
        fill="#F2ECE4"
      />
    </svg>
  )
}

export default GrayHotdog
