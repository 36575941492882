import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import favicon from './favicon/favicon.ico'
import renderTapJoy from './renderTapJoy'

function Seo({
  lang,
  title,
  description,
  keywords,
  hasTapjoyCode,
  ogTitle,
  ogDecription,
  ogImage,
}) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | Paybright`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: 'keywords',
          content: keywords.map(keyword => keyword.keyword),
        },
        {
          property: `og:title`,
          content: ogTitle,
        },
        {
          property: `og:description`,
          content: ogDecription,
        },
        {
          name: 'og:site_name',
          content: 'Paybright',
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: 'og:url',
          content: '#',
        },
        {
          name: 'facebook-domain-verification',
          content: 'oa6jvdpjs3k9u3az120htnpthj1v66',
        },
        {
          property: 'og:image',
          content: ogImage.url,
        },

        // for flexoffers (affiliate marketing)
        {
          name: 'fo-verify',
          content: '2ac31576-9718-442e-85dd-9876babc56fb',
        },
      ]}
    >
      <link rel="icon" href={favicon} />
      {/* defining our schema for google profile */}
      <script
        src="https://tag.demandbase.com/6dae68e9a5745319.min.js"
        id="demandbase_js_lib"
        async
      ></script>
      <script type="application/ld+json">
        {`{ "@context": "http://schema.org",
        "@type": "Organization",
        "name": "PayBright",
        "url": "https://www.paybright.com/en/",
        "sameAs": [ "https://www.instagram.com/paybright/?hl=en",
          "https://www.linkedin.com/company/paybright-canada" ]
        }`}
      </script>
      <script src="https://secure.leadforensics.com/js/202937.js"></script>
      <noscript>
        {`<img alt="" src="https://secure.leadforensics.com/202937.png" style="display:none;" />`}
      </noscript>
      {hasTapjoyCode && renderTapJoy()}
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: 'en',
  title: '',
  description: ``,
  keywords: [{ keyword: '' }],
  ogTitle: '',
  ogDecription: '',
  ogImage: {
    url: '',
  },
}

Seo.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.object),
  ogTitle: PropTypes.string,
  ogDecription: PropTypes.string,
  ogImage: PropTypes.shape({
    url: PropTypes.string,
  }),
}

export default Seo
