import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import Typography from '@pbds/typography'
import Card from '@pbds/card'
import Link from '@pbds/link'
import Grid from '@pbds/grid'
import Box from '@pbds/box'

import Img from 'gatsby-image/withIEPolyfill'

const { Heading, Body } = Typography
const { Row, Col } = Grid

const BlockCardTextLeftImageRight = ({
  headerText,
  paragraphText,
  linkHref,
  linkText,
  image,
}) => {
  const theme = useContext(ThemeContext)
  return (
    <Box
      as="section"
      backgroundColor={theme.colors['--background-02']}
      paddingY={['--spacing-08', '--spacing-08', '--spacing-09']}
      flexAlign="middleCenter"
    >
      <Grid>
        <Row>
          <Col xs={12} mdOffset={2} md={8} xlOffset={1} xl={5}>
            <Card
              paddingX={[
                '--spacing-04',
                '--spacing-04',
                '--spacing-05',
                '--spacing-05',
                '--spacing-07',
              ]}
              paddingY={['--spacing-07']}
            >
              <Heading
                textAlign={['center', 'center', 'center', 'left']}
                variant="--heading-2"
              >
                {headerText}
              </Heading>
              <Body
                variant="--body-01-regular"
                textAlign={['center', 'center', 'center', 'left']}
                paddingTop={['--spacing-03']}
              >
                {paragraphText}
              </Body>
              <Body
                textAlign={['center', 'center', 'center', 'left']}
                paddingTop={['--spacing-03']}
              >
                <Link variant="primary-right" target="_blank" to={linkHref}>
                  {linkText}
                </Link>
              </Body>
            </Card>
          </Col>
          <Col xs={12} mdOffset={2} md={8} xlOffset={0} xl={5}>
            <Box flexAlign="middleCenter">
              <Img
                style={{ width: '100%' }}
                fluid={image.fluid}
                alt={image.alt}
              />
            </Box>
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}

export default BlockCardTextLeftImageRight
