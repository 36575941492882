import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image/withIEPolyfill'
import Grid from '@pbds/grid'
import Box from '@pbds/box'
import { PrimaryButton } from '@pbds/buttons'
import Typography from '@pbds/typography'
import { Link } from 'gatsby'

import Circle from '../../svgs/circle.svg'
import { imageShape, imageDefaultProps } from '../../shared/proptypes'

import {
  ContainerSectionStyled,
  HotDogGridStyled,
  HotDogCircleHolderStyled,
  HotDogCircleStyled,
  HotDogHolderStyled,
  GatsbyImageHolder,
} from './ComponentsStyled'

const { Row, Col } = Grid
const { Heading, Deck } = Typography

export const SideImage = ({ image, position }) => {
  const posProps =
    position === 'top'
      ? {
          flexAlign: 'topCenter',
          paddingTop: ['0', '0', '--spacing-05', '0', '0'],
        }
      : {
          flexAlign: 'bottomCenter',
          paddingBottom: ['0', '0', '--spacing-05', '0', '0'],
        }
  return (
    <GatsbyImageHolder
      minHeight={['auto', '25rem']}
      height="100%"
      {...posProps}
    >
      <Img fluid={image.fluid} alt={image.alt} style={{ maxWidth: '100%' }} />
    </GatsbyImageHolder>
  )
}

SideImage.propTypes = {
  image: imageShape,
  position: PropTypes.oneOf(['top', 'bottom']),
}

SideImage.defaultProps = {
  image: imageDefaultProps,
  position: 'top',
}

const BlockTextCenteredTwoImagesBanner = ({
  headerText,
  paragraphText,
  buttonText,
  buttonHref,
  leftImage,
  rightImage,
}) => {
  return (
    <ContainerSectionStyled>
      <Grid>
        <Row horizontalAlign="center">
          <Col xs={6} sm={3} mdOffset={0} md={2}>
            {leftImage.fluid.src && (
              <SideImage image={leftImage} position="bottom" />
            )}
          </Col>
          <Col xs={12} sm={6} xlOffset={1} xl={4}>
            <Box
              flexAlign="middleCenter"
              height="100%"
              paddingTop={[
                '--spacing-02',
                '--spacing-07',
                '--spacing-07',
                '--spacing-09',
                '--spacing-10',
              ]}
              paddingBottom={[
                '0',
                '--spacing-07',
                '--spacing-07',
                '--spacing-09',
                '--spacing-10',
              ]}
            >
              <Heading variant="--heading-2" marginBottom="--spacing-03">
                {headerText}
              </Heading>
              <Deck variant="--deck-regular" marginBottom="--spacing-04">
                {paragraphText}
              </Deck>
              <PrimaryButton
                to={buttonHref}
                buttonType={Link}
                buttonSize="--large-button"
                width="auto"
              >
                {buttonText}
              </PrimaryButton>
            </Box>
          </Col>
          <Col xs={0} sm={3} md={2} xlOffset={1} xl={2}>
            {rightImage.fluid.src && (
              <SideImage image={rightImage} position="top" />
            )}
          </Col>
          {/* Space to show hotdog shape on mobile */}
          <Col xs={true} sm={0}>
            <Box height="8rem" />
          </Col>
        </Row>
      </Grid>

      <HotDogGridStyled>
        <Grid>
          <Row horizontalAlign="center">
            <Col xs={4} sm={3} mdOffset={0} md={2} xl={1}>
              <HotDogHolderStyled>
                <HotDogCircleHolderStyled>
                  <HotDogCircleStyled src={Circle} />
                </HotDogCircleHolderStyled>
              </HotDogHolderStyled>
            </Col>

            <Col
              xsOffset={2}
              xs={6}
              smOffset={6}
              sm={3}
              mdOffset={7}
              md={2}
              xlOffset={8}
              xl={1}
            >
              <HotDogHolderStyled>
                <HotDogCircleHolderStyled reverse={true}>
                  <HotDogCircleStyled reverse={true} src={Circle} />
                </HotDogCircleHolderStyled>
              </HotDogHolderStyled>
            </Col>
          </Row>
        </Grid>
      </HotDogGridStyled>
    </ContainerSectionStyled>
  )
}

BlockTextCenteredTwoImagesBanner.defaultProps = {
  headerText: '',
  paragraphText: '',
  buttonHref: '',
  buttonText: '',
  leftImage: imageDefaultProps,
  rightImage: imageDefaultProps,
}

BlockTextCenteredTwoImagesBanner.propTypes = {
  headerText: PropTypes.string,
  paragraphText: PropTypes.string,
  buttonHref: PropTypes.string,
  buttonText: PropTypes.string,
  leftImage: imageShape,
  rightImage: imageShape,
}

export default BlockTextCenteredTwoImagesBanner
