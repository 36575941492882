import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@pbds/typography'
import rehypeReact from 'rehype-react'
import Link from '@pbds/link'
import Grid from '@pbds/grid'
import Box from '@pbds/box'
import { PrimaryButton } from '@pbds/buttons'
import styled from 'styled-components'

const { Row, Col } = Grid

const { Heading, Body } = Typography

const Table = ({ children }) => <table width="100%">{children}</table>

const StyledUl = styled.ul`
  margin-block-start: 0;
  margin-block-end: 0;
`

const StyledOl = styled.ol`
  margin-block-start: 0;
  margin-block-end: 0;
`

const UnorderedList = ({ children }) => <StyledUl>{children}</StyledUl>
const OrderedList = ({ children }) => <StyledOl>{children}</StyledOl>

const SharedBox = ({ children }) => (
  <Box marginBottom="--spacing-04">{children}</Box>
)

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    p: props => (
      <SharedBox>
        <Body {...props} variant="--body-01-regular" />
      </SharedBox>
    ),
    h1: props => (
      <Box marginBottom="--spacing-04">
        <Heading {...props} variant="--heading-1" />
      </Box>
    ),
    h2: props => (
      <Box marginBottom="--spacing-04">
        <Heading {...props} variant="--heading-2" />
      </Box>
    ),
    h3: props => (
      <SharedBox>
        <Body {...props} variant="--body-01-emphasized" as="h3" />
      </SharedBox>
    ),
    h4: props => (
      <SharedBox>
        <Heading {...props} variant="--heading-4" />
      </SharedBox>
    ),
    img: props => (
      <SharedBox>
        <img alt="" {...props} width="100%" />
      </SharedBox>
    ),
    a: props => <Link to={props.href} {...props} />,
    button: props => (
      <SharedBox>
        <PrimaryButton buttonType="a" {...props} />
      </SharedBox>
    ),
    ul: props => <UnorderedList {...props} />,
    ol: props => <OrderedList {...props} />,
    table: props => <Table {...props} />,
  },
}).Compiler

const BlockMarkdown = ({ markdownNode, maxWidth, headerText }) => {
  return (
    <Box
      marginX="auto"
      maxWidth={maxWidth}
      paddingY={[
        '--spacing-08',
        '--spacing-08',
        '--spacing-08',
        '--spacing-09',
      ]}
    >
      <Grid>
        {headerText ? (
          <Heading
            variant="--heading-2"
            textAlign="center"
            marginBottom="--spacing-07"
            flexAlign="middleCenter"
          >
            {headerText}
          </Heading>
        ) : null}
        <Row>
          <Col>{renderAst(markdownNode.childMarkdownRemark.htmlAst)}</Col>
        </Row>
      </Grid>
    </Box>
  )
}

BlockMarkdown.propTypes = {
  headerText: PropTypes.string,
}

BlockMarkdown.defaultProps = {
  headerText: '',
}

export default BlockMarkdown
