import styled from 'styled-components'
import Box from '@pbds/box'

export const CarouselStyleBox = styled(Box)(
  ({ theme }) => `
      .react-multiple-carousel__arrow{
          background-color: ${theme.colors['--background-dark']};

          &::before {
            margin-top: 3px;
          }
      }
  
      .react-multiple-carousel__arrow--right {
          right: 0;
          transform: translateY(-${theme.space['--spacing-03']});
          &::before {
            margin-left: 3px;
          }
      }
  
      .react-multiple-carousel__arrow--left {
          left: 0;
          transform: translateY(-${theme.space['--spacing-03']});
          &::before {
            margin-right: 3px;
          }
      }
  
      .react-multi-carousel-dot button {
          border: none;
          background-color: ${theme.colors['--ui-background-active']};
          margin-right: 12px; // Size of dot from carousel package
      }
  
      .react-multi-carousel-dot--active button {
          background-color: ${theme.colors['--background-dark']};
      }
  `
)
