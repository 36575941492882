import styled from 'styled-components'
import Typography from '@pbds/typography'
import Box from '@pbds/box'

const { Body } = Typography

export const ButtonWrapper = styled(Box)(
  ({ theme }) => `
  @media screen and (min-width: ${theme.breakpoints.md}) {
    display: none;
  }
  > button, a {
    width: 100% !important;
  }
`
)

export const DividerWrapper = styled(Box)(
  ({ theme }) => `
    @media screen and (min-width: ${theme.breakpoints.md}) {
      display: none;
    }
  `
)

export const StyledBody = styled(Body)(
  ({ theme }) => `
  color: ${theme.colors['--text-dark']} !important;
  &:hover {
    color: ${theme.colors['--text-01']} !important;
  }
`
)

export const NavLink = styled('li')(
  ({ theme }) => `
    cursor: pointer;
    border-radius: ${theme.radii['--corner-radius-4']};
    @media screen and (min-width: ${theme.breakpoints.sm}) {
      padding: ${theme.space['--spacing-03']} 12px;
    }
    padding: ${theme.space['--spacing-03']} ${theme.space['--spacing-02']};
    margin-bottom: ${theme.space['--spacing-02']};
    &:hover {
      background-color: ${theme.colors['--ui-background-hover']};
      > a > span {
        text-decoration: none;
        > p {
          color: ${theme.colors['--text-01']} !important;
        }
      }
      
    }
  `
)

export const StyledBox = styled(Box)(
  ({ theme }) => `
  > a {
    color: ${theme.colors['--text-dark']};
    &:hover {
      color: ${theme.colors['--text-01']};
    }
  }
`
)

export const StyledUL = styled.ul`
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  list-style-type: none;
`
