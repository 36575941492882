import React from 'react'
import PropTypes from 'prop-types'
import getBlockFromName from './BlockRepo'
import BlockIdentifier from './BlockIdentifier'

const BlockLoader = props => {
  const { id, __typename, originalId } = props
  const Renderer = getBlockFromName(id)
  if (Renderer && process.env.GATSBY_APP_ENV === 'staging') {
    // BlockIdentifier is to help find block names in staging environment
    return (
      <BlockIdentifier contentType={__typename} id={originalId}>
        <Renderer {...props} />
      </BlockIdentifier>
    )
  }
  if (Renderer) return <Renderer {...props} />
  return process.env.GATSBY_APP_ENV === 'production' ? (
    <div />
  ) : (
    <div>{id} not found</div>
  )
}

BlockLoader.propTypes = {
  id: PropTypes.string.isRequired,
}

export default BlockLoader
