import React, { createContext, useState } from 'react'

const ActionsContext = createContext()

const ActionsContextProvider = ({ children }) => {
  const [modalActive, setModalActive] = useState(false)
  return (
    <ActionsContext.Provider value={{ modalActive, setModalActive }}>
      {children}
    </ActionsContext.Provider>
  )
}

export { ActionsContextProvider }

export default ActionsContext
