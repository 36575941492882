import Box from '@pbds/box'
import styled from 'styled-components'
import { beigeBread } from '@pbds/core-colors'
import BackgroundXLInverted from './backgrounds/background-xl-inverted.svg'
import BackgroundXL from './backgrounds/background-xl.svg'
import BackgroundMDInverted from './backgrounds/background-md-inverted.svg'
import BackgroundMD from './backgrounds/background-md.svg'

export const StyledBox = styled(Box)(
  ({ theme, isColorSchemeInverted }) => `
    @media screen and (min-width: ${theme.breakpoints.md}) {
      padding:31px 16px;
      padding: 31px clamp(5px, 3.5vw, 35px);
      background-image: url(${
        isColorSchemeInverted ? BackgroundMDInverted : BackgroundMD
      });
      background-position: left center;
      background-color: ${
        isColorSchemeInverted ? theme.colors['--background-dark'] : beigeBread
      }
    }
    @media screen and (min-width: ${theme.breakpoints.xl}) {
        padding:70px 40px;
      background-image: url(${
        isColorSchemeInverted ? BackgroundXLInverted : BackgroundXL
      });
      background-position: center bottom;
      background-color: ${
        isColorSchemeInverted ? theme.colors['--background-03'] : beigeBread
      }
    }
    background-repeat: no-repeat;
    
  `
)

export const StyledImageContainer = styled(Box)(
  ({ theme }) => `
      @media screen and (min-width: ${theme.breakpoints.md}) {
          svg {
            width: 225px;
            height:auto;
            }
      }
      @media screen and (min-width: ${theme.breakpoints.xl}) {
          svg {
            width: 245px;
            height:auto;
            }
      }
    `
)

export const StyledDiv = styled('div')(
  ({ theme }) => `
    border-radius: ${theme.radii['--corner-radius-8']};
    overflow:hidden;
    @media screen and (min-width: ${theme.breakpoints.md}) {
    box-shadow: ${theme.elevation['--elevation-regular-1']};
    }
  `
)
