import React from 'react'
import Grid from '@pbds/grid'
import NavPrimary from '@pbds/nav-primary'
import { NavWrapper, StyledNavItem } from '../styles'

const { Row, Col } = Grid

const NavSection = ({
  categoriesOptions,
  handleCategoryChange,
  selectedCategory,
}) => {
  return (
    <NavWrapper>
      <Grid>
        <Row>
          <Col>
            <NavPrimary type="secondary">
              <ul>
                {categoriesOptions.map(category => (
                  <li key={category.value}>
                    {
                      // eslint-disable-next-line
                      <StyledNavItem
                        isSelected={selectedCategory === category.value}
                        onClick={() => handleCategoryChange(category.value)}
                      >
                        {category.text}
                      </StyledNavItem>
                    }
                  </li>
                ))}
              </ul>
            </NavPrimary>
          </Col>
        </Row>
      </Grid>
    </NavWrapper>
  )
}

export default NavSection
