import Box from '@pbds/box'
import styled from 'styled-components'
import background from './assets/background.svg'

export const SectionBox = styled(Box)(
  ({ theme }) => `
  position: relative;

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    background-image: url(${background});
    background-position: center;
    background-size: auto 100%;
  }
`
)
