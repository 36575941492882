import * as React from 'react'
import PropTypes from 'prop-types'
import { PrimaryButton, SecondaryButton } from '@pbds/buttons'
import Card from '@pbds/card'
import Link from '@pbds/link'
import Box from '@pbds/box'
import Typography from '@pbds/typography/lib/Typography'
import { Tooltip, ClickAwayListener, withStyles } from '@material-ui/core'

const { Body } = Typography

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: 'transparent',
    padding: 0,
  },
})(Tooltip)

export const ElementExpandableButton = ({ buttonText, buttonLinks = [] }) => {
  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setTimeout(setOpen.bind(null, false), 200)
  }

  const handleTooltipToggle = () => {
    setOpen(!open)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div
        style={{
          position: 'relative',
          padding: 0,
        }}
      >
        <CustomTooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableHoverListener
          disableTouchListener
          style={{ padding: 0 }}
          title={
            <Card
              style={{ margin: 0 }}
              marginTop="--spacing-04"
              paddingTop="--spacing-05"
              paddingBottom="--spacing-03"
              paddingX="--spacing-07"
              elevation="--elevation-regular-3"
            >
              <Box style={{ pointerEvents: 'auto' }} flexAlign="middleCenter">
                {Array.isArray(buttonLinks) &&
                  buttonLinks.map(item => {
                    return item.isButton ? (
                      <Box marginBottom="--spacing-03">
                        <SecondaryButton buttonType="a" href={item.linkHref}>
                          {item.linkText}
                        </SecondaryButton>
                      </Box>
                    ) : (
                      <Box marginBottom="--spacing-03">
                        <Link to={item.linkHref}>
                          <Body variant="--body-01-semi-emphasized">
                            {item.linkText}
                          </Body>
                        </Link>
                      </Box>
                    )
                  })}
              </Box>
            </Card>
          }
        >
          <PrimaryButton onClick={handleTooltipToggle}>
            <span style={{ userSelect: 'none' }}>{buttonText}</span>
          </PrimaryButton>
        </CustomTooltip>
      </div>
    </ClickAwayListener>
  )
}

ElementExpandableButton.defaultProps = {
  buttonText: '',
  buttonLinks: [{ linkText: '', linkHref: '', isButton: false }],
}

ElementExpandableButton.propTypes = {
  buttonLinks: PropTypes.arrayOf(
    PropTypes.shape({
      linkText: PropTypes.string,
      linkHref: PropTypes.string,
      isButton: PropTypes.bool,
    })
  ),
}
