import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@pbds/typography'
import Grid from '@pbds/grid'
import Box from '@pbds/box'

const { Row, Col } = Grid
const { Heading, Deck } = Typography

const BlockTitleWithDate = ({ title, dateText }) => (
  <Box marginTop={[48, 48, 72, 72, 96]} marginBottom={[32]}>
    <Grid>
      <Row>
        <Col>
          <Box marginBottom={[8, 8, 12, 12, 16]}>
            <Heading variant="--heading-1" as="h1">
              {title}
            </Heading>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col>
          <Deck variant="--deck-semi-emphasized" as="span">
            {dateText}
          </Deck>
        </Col>
      </Row>
    </Grid>
  </Box>
)

BlockTitleWithDate.defaultProps = {
  title: '',
  dateText: '',
}

BlockTitleWithDate.propTypes = {
  title: PropTypes.string,
  dateText: PropTypes.string,
}

export default BlockTitleWithDate
