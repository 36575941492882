import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@pbds/typography'
import Grid from '@pbds/grid'
import Box from '@pbds/box'

import ElementTextImage, {
  elementTextImagePropTypes,
} from '../../elements/ElementTextImage'

import { imageDefaultProps } from '../../shared/proptypes'

const { Row, Col } = Grid
const { Heading } = Typography

const BlockIconsText = ({ headerText, elements }) => (
  <Grid>
    <Row>
      <Col mdOffset={3} md={6}>
        <Heading
          variant="--heading-2"
          textAlign="center"
          marginBottom="--spacing-06"
          paddingX={['0', '--spacing-09', '--spacing-02', '--spacing-07']}
        >
          {headerText}
        </Heading>
      </Col>
    </Row>
    <Row>
      <Col xlOffset={1} xl={10}>
        <Grid margin={false}>
          <Row>
            {elements.map(card => (
              <Col xs={12} sm={6} lg={4}>
                <Box
                  paddingX={['0', '--spacing-03', '--spacing-06']}
                  marginBottom={[
                    '--spacing-05',
                    '--spacing-05',
                    '--spacing-07',
                    '--spacing-08',
                  ]}
                >
                  <ElementTextImage {...card} />
                </Box>
              </Col>
            ))}
          </Row>
        </Grid>
      </Col>
    </Row>
  </Grid>
)

BlockIconsText.defaultProps = {
  headerText: '',
  elements: [
    {
      image: imageDefaultProps,
      headerText: '',
      paragraphText: '',
      imageWidth: '',
    },
    {
      image: imageDefaultProps,
      headerText: '',
      paragraphText: '',
      imageWidth: '',
    },
    {
      image: imageDefaultProps,
      headerText: '',
      paragraphText: '',
      imageWidth: '',
    },
    {
      image: imageDefaultProps,
      headerText: '',
      paragraphText: '',
      imageWidth: '',
    },
    {
      image: imageDefaultProps,
      headerText: '',
      paragraphText: '',
      imageWidth: '',
    },
    {
      image: imageDefaultProps,
      headerText: '',
      paragraphText: '',
      imageWidth: '',
    },
  ],
}

BlockIconsText.propTypes = {
  headerText: PropTypes.string,
  elements: PropTypes.arrayOf(elementTextImagePropTypes),
}

export default BlockIconsText
