import React from 'react'
import styled from 'styled-components'
import Box from '@pbds/box'
import Grid from '@pbds/grid'
import Typography from '@pbds/typography'
import Link from '@pbds/link'
import PropTypes from 'prop-types'
import GreenFallbackImage from '../../../assets/FallbackMediaImageGreen.png'
import PurpleFallbackImage from '../../../assets/FallbackMediaImagePurple.png'
import { imageShape, imageDefaultProps } from '../../shared/proptypes'
import { StyledImage, StyledLinkBox } from './ComponentsStyled'

const { Row, Col } = Grid
const { Heading } = Typography

const getFallbackImage = index => {
  return index % 2 === 0 ? GreenFallbackImage : PurpleFallbackImage
}

export const CardWrapper = styled.div`
  display: block;
  height: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex-direction: column;
`

const MediaCardCol = ({ image, titleText, linkHref, linkText }, index) => (
  <Col xs={12} sm={6} lg={4}>
    <CardWrapper>
      <StyledLinkBox
        flexAlign="topLeft"
        flexDirection="column"
        as="a"
        href={linkHref}
        target="_blank"
        rel="noopener noreferrer"
        borderRadius="--corner-radius-8"
        backgroundColor="--background-light"
        padding="--spacing-03"
        paddingBottom="--spacing-04"
      >
        <StyledImage
          fluid={
            image.fluid && image.fluid.src
              ? image.fluid
              : {
                  src: getFallbackImage(index),
                }
          }
          alt={image.alt}
          role="presentation"
        />
        <Heading
          variant="--heading-4"
          marginBottom="--spacing-07"
          marginTop="--spacing-03"
        >
          {titleText}
        </Heading>
        <div style={{ marginTop: 'auto' }}>
          <Link variant="primary-right" target="_blank" to={linkHref}>
            {linkText}
          </Link>
        </div>
      </StyledLinkBox>
    </CardWrapper>
  </Col>
)

const BlockMediaCard = ({ articles }) => (
  // Spacing for box shadow to be shown
  <Box as="section" paddingTop="--spacing-02">
    <Grid>
      <Row>
        <Col mdOffset={1} md={10}>
          <Grid margin={false}>
            <Row>{articles.map(MediaCardCol)}</Row>
          </Grid>
        </Col>
      </Row>
    </Grid>
  </Box>
)

BlockMediaCard.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      image: imageShape,
      linkText: PropTypes.string,
      titleText: PropTypes.string,
      linkHref: PropTypes.string,
    })
  ),
}

BlockMediaCard.defaultProps = {
  articles: [
    {
      image: imageDefaultProps,
      linkText: '',
      titleText: '',
      linkHref: '',
    },
  ],
}

export default BlockMediaCard
