import styled from 'styled-components'
import DesktopBlobs from './Images/desktop-blobs.svg'
import Box from '@pbds/box'

export const ContainerStyled = styled('div')(
  ({ theme, hasBackground, backgroundColor }) => `
  @media screen and (min-width: ${theme.breakpoints.sm}) {
    background-image: ${hasBackground ? ` url(${DesktopBlobs})` : `none`};
    background-size: cover;
    background-repeat: no-repeat;
  }
   background-color ${backgroundColor}
`
)

export const StyledFlexContainer = styled(Box)(
  ({ theme }) => `
      margin-bottom: 28px;
      &.has-siblings {
          margin-bottom:0;
      }
      display:flex;
      width:100%;
      justify-content: center;
      flex-direction: column;
      >*:nth-of-type(2){
          margin: 28px 0 0 0;
      }
      @media screen and (min-width: ${theme.breakpoints.xl}) {
          flex-direction: row;
          &.has-siblings {
            margin-bottom:28px;
          }
          
          >*:nth-of-type(2){
              margin:0 0 0 32px;
          }
      }
      `
)
