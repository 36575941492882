export default function gtmEvent({ event, label, action, category, value }) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event,
    label,
    action,
    category,
    value,
  })
}
