import React from 'react'
import styled from 'styled-components'
import Typography from '@pbds/typography'
import PropTypes from 'prop-types'
import Grid from '@pbds/grid'
import { beigeBread50 } from '@pbds/core-colors'

const h1YMargin = ['--spacing-07', '--spacing-08', '--spacing-09']
const h2YMargin = ['--spacing-04', '--spacing-05', '--spacing-07']

const BlockSingleHeading = ({ headerText, isH2, hasGreyBackground }) => {
  const StyledSection = styled.div`
    background-color: ${hasGreyBackground ? beigeBread50 : 'transparent'};
  `

  return (
    <StyledSection>
      <Grid>
        <Grid.Row horizontalAlign="center">
          <Grid.Col xs={12} lg={6}>
            <Typography.Heading
              variant={isH2 ? '--heading-2' : '--heading-1'}
              marginY={isH2 ? h2YMargin : h1YMargin}
              marginX={['--spacing-03', '--spacing-04']}
              as="h2"
              textAlign="center"
            >
              {headerText}
            </Typography.Heading>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </StyledSection>
  )
}

export default BlockSingleHeading

BlockSingleHeading.propTypes = {
  headerText: PropTypes.string,
  isH2: PropTypes.bool,
  hasGreyBackground: PropTypes.bool,
}

BlockSingleHeading.defaultProps = {
  headerText: '',
  isH2: false,
  hasGreyBackground: false,
}
