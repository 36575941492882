import Box from '@pbds/box'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'

export const StyledImage = styled(Img)(
  ({ theme }) => `
    width: 100%;
    border-radius: ${theme.radii['--corner-radius-8']};
    min-height: 180px; // From figma design
    `
)

export const StyledLinkBox = styled(Box)(
  ({ theme }) => `
    position: relative;
    text-decoration: none;
    box-shadow: ${theme.elevation['--elevation-regular-3']};
    color: ${theme.colors['--text-dark']};
    height: 100%;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: inherit;
      opacity: 0;
      box-shadow: ${theme.elevation['--elevation-emphasized-4']};
      transition: opacity 0.2s ease;
    }
  
    &:hover:before {
      opacity: 0.5;
    }
  `
)
