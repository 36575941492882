import Box from '@pbds/box'
import { hexToRgba } from '@pbds/utils'
import styled from 'styled-components'
import { MAX_SCREEN_WIDTH } from '../../shared/constants'

export const Edge = styled.div(
  ({ theme, side, backgroundColor }) => `
  display: none;
  position: absolute;
  top: 0;
  bottom: -${theme.space['--spacing-04']};
  width: 100px;
  z-index: 1;
  background: linear-gradient(
    to ${side},
    ${hexToRgba(backgroundColor, 0)},
    ${hexToRgba(backgroundColor, 1)}
  );
  ${side}: -1px;
  @media screen and (min-width: ${MAX_SCREEN_WIDTH}) {
    display: block;
  }
`
)

Edge.defaultProps = {
  theme: { space: {} },
  side: 'left',
  backgroundColor: '',
}

export const MarqueeBox = styled(Box)`
  position: relative;
`
