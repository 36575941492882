import React from 'react'

const Hamburger = ({ onClick }) => {
  return (
    <div tabIndex={0} role="button" onClick={onClick} onKeyDown={onClick}>
      <svg
        width="23"
        height="22"
        viewBox="0 0 23 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="web--svg_open_menu"
      >
        <title id="web--svg_open_menu">Menu</title>
        <rect
          x="0.850586"
          y="0.366638"
          width="21.3333"
          height="3.67816"
          rx="1.83908"
          fill="#4A4AF4"
        />
        <rect
          x="0.850586"
          y="9.19421"
          width="21.3333"
          height="3.67816"
          rx="1.83908"
          fill="#4A4AF4"
        />
        <rect
          x="0.850586"
          y="18.0219"
          width="21.3333"
          height="3.67816"
          rx="1.83908"
          fill="#4A4AF4"
        />
      </svg>
    </div>
  )
}

export default Hamburger
