import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { PrimaryButton } from '@pbds/buttons'
import Grid from '@pbds/grid'
import Box from '@pbds/box'

const BlockSimpleButton = ({ buttonText, buttonHref }) => {
  const isInternalLink = buttonHref.startsWith('/')
  return (
    <Grid>
      <Grid.Row>
        <Grid.Col xs={0} sm={12}>
          <Box flexAlign="middleLeft" paddingY="--spacing-01">
            <PrimaryButton
              to={isInternalLink ? buttonHref : null}
              href={isInternalLink ? null : buttonHref}
              buttonType={isInternalLink ? Link : 'a'}
              rightIcon="rightArrow"
              buttonSize="--small-button"
              isFullWidthOnMobile={false}
            >
              {buttonText}
            </PrimaryButton>
          </Box>
        </Grid.Col>
        <Grid.Col xs={12} sm={0}>
          <Box flexAlign="middleLeft" paddingX="--spacing-01">
            <PrimaryButton
              to={isInternalLink ? buttonHref : null}
              href={isInternalLink ? null : buttonHref}
              buttonType={isInternalLink ? Link : 'a'}
              rightIcon="rightArrow"
              buttonSize="--large-button"
              isFullWidthOnMobile={false}
            >
              {buttonText}
            </PrimaryButton>
          </Box>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  )
}
export default BlockSimpleButton

BlockSimpleButton.propTypes = {
  buttonHref: PropTypes.string,
  buttonText: PropTypes.string,
}

BlockSimpleButton.defaultProps = {
  buttonHref: '',
  buttonText: '',
}
