import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@pbds/typography'
import Grid from '@pbds/grid'
import Box from '@pbds/box'

const { Row, Col } = Grid
const { Heading, Body } = Typography

const BlockSimpleHeading = ({ text, isH3 }) => (
  <Box marginBottom={[isH3 ? 24 : 32]}>
    <Grid>
      <Row>
        <Col>
          {isH3 ? (
            <Body variant="--body-01-emphasized" as="h3">
              {text}
            </Body>
          ) : (
            <Heading variant="--heading-2" as="h2">
              {text}
            </Heading>
          )}
        </Col>
      </Row>
    </Grid>
  </Box>
)

BlockSimpleHeading.defaultProps = {
  text: '',
  isH3: false,
}

BlockSimpleHeading.propTypes = {
  text: PropTypes.string,
  isH3: PropTypes.bool,
}

export default BlockSimpleHeading
