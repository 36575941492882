import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@pbds/grid'
import Box from '@pbds/box'
import Typography from '@pbds/typography'
import Card from '@pbds/card'
import styled from 'styled-components'
import { beigeBread25 } from '@pbds/core-colors'

// specific request from design for background image, may not 100% align with design system
const MetricContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.space['--spacing-04']};
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
`

const MetricDisplay = ({ image, text }) => {
  return (
    <MetricContainer backgroundImage={image}>
      <Box flexAlign="middleCenter">
        <Typography.Numerical marginY="--spacing-05">
          {text}
        </Typography.Numerical>
      </Box>
    </MetricContainer>
  )
}

const BlockMetricsCard = ({ headerText, cards }) => (
  <Box
    as="section"
    backgroundColor={beigeBread25}
    paddingY={['--spacing-08', '--spacing-09']}
  >
    <Box
      maxWidth={['100%', '100%', '100%', '75vw']}
      marginX={['0', '0', '0', 'auto']}
    >
      <Grid>
        <Grid.Row>
          <Grid.Col xs={12} sm={12} md={0}>
            <Box width={['100%', '80%']} marginX={[0, 'auto']}>
              {headerText ? (
                <Typography.Heading
                  variant="--heading-2"
                  textAlign="center"
                  paddingX={['--spacing-03', '--spacing-08']}
                  marginBottom={['--spacing-05', '--spacing-07']}
                >
                  {headerText}
                </Typography.Heading>
              ) : null}
              {cards.map(
                ({
                  percentBackgroundImage,
                  percentage,
                  prefix,
                  headerText,
                  paragraphText,
                }) => (
                  <Box mb={['--spacing-03', '--spacing-04']}>
                    <Card
                      elevation="--elevation-regular-3"
                      padding={['--spacing-03', '--spacing-05']}
                    >
                      <Grid gutter={false} margin={false}>
                        <Grid.Row verticalAlign="middle">
                          <Grid.Col xs={4} sm={4}>
                            <MetricDisplay
                              image={percentBackgroundImage.url}
                              text={`${prefix ?? ''}${percentage}%`}
                            />
                          </Grid.Col>
                          <Grid.Col xs={8} sm={8}>
                            <Box
                              marginLeft={[
                                '--spacing-02',
                                '--spacing-03',
                                '--spacing-05',
                              ]}
                            >
                              {headerText ? (
                                <Typography.Heading
                                  variant="--heading-4"
                                  textAlign="left"
                                  marginBottom={[0, '--spacing-02']}
                                >
                                  {headerText}
                                </Typography.Heading>
                              ) : null}
                              <Typography.Body textAlign="left" margin={0}>
                                {paragraphText}
                              </Typography.Body>
                            </Box>
                          </Grid.Col>
                        </Grid.Row>
                      </Grid>
                    </Card>
                  </Box>
                )
              )}
            </Box>
          </Grid.Col>
          {headerText ? (
            <Grid.Col xs={0} sm={0} md={6} mdOffset={3}>
              <Typography.Heading
                variant="--heading-2"
                textAlign="center"
                marginBottom="--spacing-07"
              >
                {headerText}
              </Typography.Heading>
            </Grid.Col>
          ) : null}
          {cards.map(
            ({
              percentBackgroundImage,
              percentage,
              prefix,
              headerText,
              paragraphText,
              description,
            }) => (
              <Grid.Col xs={0} sm={0} md={4} key={description}>
                <Box display="flex" height="100%">
                  <Card
                    elevation="--elevation-regular-3"
                    paddingX="--spacing-04"
                    paddingY="--spacing-07"
                  >
                    <Box flexAlign="middleCenter">
                      <MetricDisplay
                        image={percentBackgroundImage.url}
                        text={`${prefix ?? ''}${percentage}%`}
                      />
                      {headerText ? (
                        <Typography.Heading
                          variant="--heading-4"
                          textAlign="center"
                        >
                          {headerText}
                        </Typography.Heading>
                      ) : null}
                      <Typography.Body textAlign="center" margin="--spacing-02">
                        {paragraphText}
                      </Typography.Body>
                    </Box>
                  </Card>
                </Box>
              </Grid.Col>
            )
          )}
        </Grid.Row>
      </Grid>
    </Box>
  </Box>
)

BlockMetricsCard.defaultProps = {
  headerText: '',
  cards: new Array(3).fill(
    {
      headerText: '',
      paragraphText: '',
      percentage: '',
      prefix: '',
      percentBackgroundImage: {
        url: '',
      },
    },
    0,
    3
  ),
}

BlockMetricsCard.propTypes = {
  headerText: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      paragraphText: PropTypes.string,
      percentage: PropTypes.string,
      prefix: PropTypes.string,
      percentBackgroundImage: PropTypes.shape({
        url: PropTypes.string,
      }),
    })
  ),
}

export default BlockMetricsCard
