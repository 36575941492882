/*
 *
 * getImageRules function
 *
 * This gets the width, heights and absolute positions for each image in the hero banner at each breakpoint.
 * There are going to be nine images per hero category, each matching up to one of the indexes below (array is 0-indexed)
 * Each image has a fixed width/height set by design, and this is scaled down for smaller viewports.
 * The scaling is calculated from the instruction on the figma design.
 * The breakpoints supported are xs, md, and xl.
 * If there is not an object for an image at a particular breakpoint, it will not show at that breakpoint.
 *
 */
export function getImageRules(index) {
  let width,
    height = 0
  let xs,
    md,
    xl = {}

  switch (index) {
    case 0:
      width = '200px'
      height = '185px'
      xs = {
        top: '-75px',
        right: '-50px',
        scale: 0.533, // md.scale/1.25
      }
      md = {
        top: '-85px',
        left: '60px',
        scale: 0.666, // 1/1.5
      }
      xl = {
        top: '-55px',
        left: '-60px',
        scale: 1,
      }
      break
    case 1:
      width = '280px'
      height = '290px'
      xs = {
        top: '-145px',
        left: '-100px',
        scale: 0.444, // md.scale/1.5
      }
      md = {
        top: '20px',
        left: '-90px',
        scale: 0.666, // 1/1.5
      }
      xl = {
        top: '195px',
        left: '-178px',
        scale: 1,
      }
      break
    case 2:
      width = '250px'
      height = '220px'
      xs = {
        top: '150px',
        left: '-120px',
        scale: 0.444, // md.scale/1.5
      }
      md = {
        bottom: '-85px',
        left: '0px',
        scale: 0.666, // 1/1.5
      }
      xl = {
        bottom: '-75px',
        left: '100px',
        scale: 1,
      }
      break
    case 3:
      width = '235px'
      height = '200px'
      xl = {
        top: '-63px',
        left: 'calc(50% - 120px)',
        scale: 1,
      }
      break
    case 4:
      width = '340px'
      height = '310px'
      xs = {
        bottom: '-50px',
        right: '-150px',
        scale: 0.286, // md.scale/1.75
      }
      md = {
        bottom: '-50px',
        right: '-130px',
        scale: 0.5, // 1/2
      }
      xl = {
        bottom: '-130px',
        left: 'calc(50% - 100px)',
        scale: 1,
      }
      break
    case 5:
      width = '315px'
      height = '260px'
      xs = {
        left: '-70px',
        bottom: '-110px',
        scale: 0.333, // md.scale/1.5
      }
      md = {
        top: '-70px',
        right: '-70px',
        scale: 0.5, // 1/2
      }
      xl = {
        top: '128px',
        right: '208px',
        scale: 1,
      }
      break
    case 6:
      width = '200px'
      height = '200px'
      xl = {
        bottom: '15px',
        right: '100px',
        scale: 1,
      }
      break
    case 7:
      width = '325px'
      height = '280px'
      xl = {
        top: '-37px',
        right: '-140px',
        scale: 1,
      }
      break
    case 8:
      width = '200px'
      height = '175px'
      xl = {
        bottom: '98px',
        right: '-104px',
        scale: 1,
      }
      break
    default:
  }
  return {
    width,
    height,
    xs,
    md,
    xl,
  }
}
