import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@pbds/grid'
import Typography from '@pbds/typography'
import Box from '@pbds/box'
import { imageShape, imageDefaultProps } from '../../shared/proptypes'

import { SideImageHolderStyled } from './ComponentsStyled'
import ElementCarouselRegular from '../../elements/ElementCarouselRegular'
const { Row, Col } = Grid
const { Heading, Body } = Typography

const PersonCard = slide => (
  <Box
    key={slide.name}
    paddingTop="--spacing-02"
    paddingBottom={[
      '--spacing-07',
      '--spacing-07',
      '--spacing-07',
      '--spacing-06',
    ]}
    paddingX={['--spacing-03', '--spacing-03', '--spacing-03', '--spacing-08']}
  >
    <Grid margin={false} gutter={false}>
      <Row>
        <Col xs={12} sm={4} smOffset={4} lgOffset={0} lg={4}>
          <Box
            padding="--spacing-03"
            marginBottom={['--spacing-04', '--spacing-04', '--spacing-04', 0]}
            maxWidth={['13rem', 'auto']}
            marginX="auto"
          >
            <SideImageHolderStyled
              fluid={slide.image.fluid}
              alt={slide.image.alt}
            />
          </Box>
        </Col>
        <Col xs={12} sm={8} smOffset={2} lgOffset={0} lg={8}>
          <Box
            flexAlign={[
              'middleCenter',
              'middleCenter',
              'middleCenter',
              'middleLeft',
            ]}
            height="100%"
            paddingLeft={[0, 0, 0, '--spacing-03']}
            paddingRight={[0, 0, 0, '--spacing-05']}
          >
            <Heading
              variant="--heading-4"
              paddingBottom="--spacing-04"
              textAlign={['center', 'center', 'center', 'left']}
            >
              {slide.quoteText}
            </Heading>
            <Body
              variant="--body-01-semi-emphasized"
              textAlign={['center', 'center', 'center', 'left']}
            >
              {slide.name}
            </Body>
            <Body
              variant="--body-01-regular"
              textAlign={['center', 'center', 'center', 'left']}
            >
              {slide.jobTitle}
            </Body>
          </Box>
        </Col>
      </Row>
    </Grid>
  </Box>
)

const BlockPersonQuoteCarousel = ({ slides }) => (
  <>
    <Box as="section" paddingBottom="--spacing-03">
      <Grid margin={false}>
        <Row>
          <Col lgOffset={1} lg={10}>
            <ElementCarouselRegular
              removeArrowOnDeviceType={['mobile', 'tablet']}
            >
              {slides.map(PersonCard)}
            </ElementCarouselRegular>
          </Col>
        </Row>
      </Grid>
    </Box>
  </>
)

BlockPersonQuoteCarousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      image: imageShape,
      quoteText: PropTypes.string,
      name: PropTypes.string,
      jobTitle: PropTypes.string,
    })
  ),
}

BlockPersonQuoteCarousel.defaultProps = {
  slides: [
    {
      image: imageDefaultProps,
      quoteText: '',
      name: '',
      jobTitle: '',
    },
  ],
}

export default BlockPersonQuoteCarousel
