import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { ThemeContext } from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import { Link } from 'gatsby'
import Grid from '@pbds/grid'
import Typography from '@pbds/typography'
import Card from '@pbds/card'
import Box from '@pbds/box'
import { beigeBread25 } from '@pbds/core-colors'
import { PrimaryButton } from '@pbds/buttons'
import {
  imageShape,
  imageFixedShape,
  imageDefaultProps,
  imageFixedDefaultProps,
} from '../../shared/proptypes'

import { SideImageHolderStyled } from './ComponentsStyled'
import ElementCarouselRegular from '../../elements/ElementCarouselRegular'
const { Row, Col } = Grid
const { Heading, Deck, Body } = Typography

const PartnerCard = (slide, index) => (
  <Box
    key={slide.partnerName}
    paddingTop="--spacing-03"
    paddingBottom="--spacing-07"
    paddingX="--spacing-04"
  >
    <Card
      backgroundColor={beigeBread25}
      padding={['--spacing-03', '--spacing-03', '--spacing-04', '--spacing-05']}
    >
      <Grid margin={false}>
        <Row>
          <Col xs={12} sm={6} xl={5}>
            <SideImageHolderStyled
              fluid={slide.image.fluid}
              alt={slide.image.alt}
            />
          </Col>
          <Col>
            <Box
              flexAlign={['middleCenter', 'middleLeft']}
              height="100%"
              paddingX={[0, 0, 0, '--spacing-03']}
            >
              <Box
                maxWidth="100px"
                paddingBottom="--spacing-04"
                paddingTop={['--spacing-05', 0]}
              >
                <Img
                  fixed={slide.merchantLogo.fixed}
                  alt={slide.merchantLogo.alt}
                />
              </Box>
              <Body
                variant="--body-01-regular"
                paddingBottom="--spacing-04"
                textAlign={['center', 'left']}
              >
                {slide.quoteText}
              </Body>
              <Body variant="--body-01-semi-emphasized">
                {slide.partnerName}
              </Body>
              <Body variant="--body-01-regular" paddingBottom="--spacing-04">
                {slide.partnerTitle}
              </Body>

              {slide.buttonHref && slide.buttonText && (
                <Box paddingBottom={['--spacing-02', '--spacing-03']}>
                  <PrimaryButton
                    to={slide.buttonHref}
                    buttonSize="--large-button"
                    width="auto"
                    rightIcon="rightArrow"
                    buttonType={Link}
                  >
                    {slide.buttonText}
                  </PrimaryButton>
                </Box>
              )}
            </Box>
          </Col>
        </Row>
      </Grid>
    </Card>
  </Box>
)

const BlockCaseStudiesSlider = ({ headerText, paragraphText, slides }) => {
  const theme = useContext(ThemeContext)
  return (
    <>
      <Box as="section" paddingBottom="--spacing-03">
        <Grid>
          <Row>
            <Col mdOffset={2} md={8} lgOffset={3} lg={6}>
              <Heading
                variant="--heading-2"
                textAlign="center"
                paddingBottom="--spacing-02"
              >
                {headerText}
              </Heading>
              <Deck textAlign="center" paddingBottom="--spacing-05">
                {paragraphText}
              </Deck>
            </Col>
          </Row>
        </Grid>

        <Grid margin={false}>
          <Row>
            <Col lgOffset={1} lg={10}>
              {/* Negative margin to offset the padding we have to give to the carousel items to accomodate the shadow  */}
              <ElementCarouselRegular
                marginX={[0, 0, 0, `-${theme.space['--spacing-04']}`]}
                marginY={`-${theme.space['--spacing-03']}`}
              >
                {slides.map(PartnerCard)}
              </ElementCarouselRegular>
            </Col>
          </Row>
        </Grid>
      </Box>
    </>
  )
}

BlockCaseStudiesSlider.propTypes = {
  headerText: PropTypes.string,
  paragraphText: PropTypes.string,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      image: imageShape,
      merchantLogo: imageFixedShape,
      quoteText: PropTypes.string,
      partnerName: PropTypes.string,
      partnerTitle: PropTypes.string,
      buttonHref: PropTypes.string,
      buttonText: PropTypes.string,
    })
  ),
}

BlockCaseStudiesSlider.defaultProps = {
  headerText: '',
  paragraphText: '',
  slides: [
    {
      image: imageDefaultProps,
      merchantLogo: imageFixedDefaultProps,
      quoteText: '',
      partnerName: '',
      partnerTitle: '',
      buttonHref: '',
      buttonText: '',
    },
  ],
}

export default BlockCaseStudiesSlider
