import React from 'react'
import { Link } from 'gatsby'
import { PrimaryButton } from '@pbds/buttons'
import Typography from '@pbds/typography'
import Box from '@pbds/box'
import styled from 'styled-components'
import { coralJam, yellowDijon } from '@pbds/core-colors'
import Card from '@pbds/card'
import Divider from '@pbds/divider'
import PBDSLink from '@pbds/link'
import ChecklistItem from '@pbds/checklist-item'
import Grid from '@pbds/grid'
import Img from 'gatsby-image/withIEPolyfill'
import PropTypes from 'prop-types'
import { imageShape, imageDefaultProps } from '../../shared/proptypes'
import theme from '@pbds/theme'

const { Row, Col } = Grid

const { Heading, Deck, Body } = Typography

const ChecklistItemBox = styled(Box)`
  > div {
    margin-bottom: ${({ theme }) => theme.space['--spacing-03']};
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const CardsBox = styled(Box)`
  position: relative;
  top: ${({ theme }) => theme.space['--spacing-09']};
`

const CardBox = styled(Box)`
  position: relative;
`
const CardImageContainer = styled(Box)`
  position: absolute;
  z-index: 1;
  bottom: calc(100% - ${({ theme }) => theme.space['--spacing-09']});
  width: 150px;
  left: 50%;
  transform: translateX(-50%);
`

const Circle = styled('div')(
  ({ theme, index }) => `
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  z-index: 1;
  top: -${theme.space['--spacing-08']};
  ${
    index === 0
      ? ` left: -${theme.space['--spacing-07']};
         background-color: ${coralJam};`
      : `right: -${theme.space['--spacing-07']};
         background-color: ${yellowDijon};`
  }
  & + div {
    /* CARD STYLING */
    position: relative;
    z-index: 2;
    height: 100%;
    padding: 0 ${theme.space['--spacing-03']};
    @media screen and (min-width: ${theme.breakpoints.sm}) {
      padding: 0 ${theme.space['--spacing-04']};
    }
    @media screen and (min-width: ${theme.breakpoints.md}) {
      padding: 0 ${theme.space['--spacing-05']};
    }
  }
`
)

const RenderCard = ({
  headerText,
  paragraphText,
  linkText,
  linkHref,
  firstNoteText,
  secondNoteText,
  thirdNoteText,
  image,
  index,
}) => (
  <CardBox
    height={['auto', 'auto', '100%']}
    marginX="auto"
    marginBottom={[
      index === 0 ? '--spacing-09' : 0,
      index === 0 ? '--spacing-09' : 0,
      0,
    ]}
  >
    <Circle index={index} />
    <Card elevation="--elevation-regular-2">
      <CardImageContainer>
        <Img fluid={image.fluid} alt={image.alt} />
      </CardImageContainer>
      <Box
        flexAlign="middleCenter"
        paddingBottom="--spacing-05"
        paddingTop="--spacing-09"
      >
        <Heading variant="--heading-3">{headerText}</Heading>
        <Body
          variant="--body-01-regular"
          textAlign="center"
          marginTop="--spacing-02"
        >
          {paragraphText}
        </Body>

        {linkText && (
          <Box marginTop="--spacing-03">
            <PBDSLink variant="primary-right" to={linkHref} buttonType={Link}>
              {linkText}
            </PBDSLink>
          </Box>
        )}
      </Box>
      <Divider />
      <ChecklistItemBox paddingY="--spacing-05">
        {firstNoteText && (
          <ChecklistItem semiEmphasized={true} labelText={firstNoteText} />
        )}
        {secondNoteText && (
          <ChecklistItem semiEmphasized={true} labelText={secondNoteText} />
        )}
        {thirdNoteText && (
          <ChecklistItem semiEmphasized={true} labelText={thirdNoteText} />
        )}
      </ChecklistItemBox>
    </Card>
  </CardBox>
)

const BlockPaymentOption = ({
  headerText,
  subHeading,
  paragraphText,
  subParagraph,
  cards,
  backgroundColor,
  buttonHref,
  buttonText,
  location,
}) => {
  const params = location && location.search
  return (
    <Box as="section" paddingBottom="7rem" overflow="hidden">
      <Box
        paddingTop={['--spacing-06', '--spacing-07']}
        flexAlign="middleCenter"
        backgroundColor={backgroundColor.hex}
      >
        <Grid>
          {headerText && (
            <Row horizontalAlign="center">
              <Col xs={8} sm={12}>
                <Heading variant="--heading-3" marginBottom="--spacing-04">
                  {headerText}
                </Heading>
              </Col>
            </Row>
          )}
          <Row horizontalAlign="center">
            <Col xs={12} md={6}>
              <Heading variant={headerText ? '--heading-1' : '--heading-2'}>
                {subHeading}
              </Heading>
            </Col>
          </Row>
          <Row horizontalAlign="center">
            <Col xs={12} sm={8} md={10} lg={6}>
              <Deck variant="--deck-regular" marginY="--spacing-04">
                {paragraphText}
              </Deck>
            </Col>
          </Row>
          <Row horizontalAlign="center">
            <Box marginBottom={[0, 0, `-${theme.space['--spacing-07']}`]}>
              {buttonText && (
                <PrimaryButton
                  isFullWidthOnMobile={false}
                  buttonSize="--large-button"
                  buttonType={Link}
                  to={buttonHref + params}
                >
                  {buttonText}
                </PrimaryButton>
              )}
              {subParagraph && (
                <Deck variant="--deck-regular" marginBottom="--spacing-04">
                  {subParagraph}
                </Deck>
              )}
            </Box>
          </Row>
          <CardsBox>
            <Row horizontalAlign="center">
              {cards.map((card, index) => (
                <Col
                  xs={12}
                  sm={8}
                  md={6}
                  lg={5}
                  xl={4}
                  lgOffset={index === 1 ? 1 : 0}
                  xlOffset={index === 1 ? 2 : 0}
                  horizontalAlign="left"
                >
                  <RenderCard
                    key={card.image.fluid.src}
                    index={index}
                    {...card}
                  />
                </Col>
              ))}
            </Row>
          </CardsBox>
        </Grid>
      </Box>
    </Box>
  )
}

export default BlockPaymentOption

BlockPaymentOption.propTypes = {
  headerText: PropTypes.string,
  subHeading: PropTypes.string,
  paragraphText: PropTypes.string,
  subParagraph: PropTypes.string,
  backgroundColor: {
    hex: PropTypes.string,
  },
  buttonText: PropTypes.string,
  buttonHref: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      image: imageShape,
      headerText: PropTypes.string,
      paragraphText: PropTypes.string,
      linkText: PropTypes.string,
      linkHref: PropTypes.string,
      firstNoteText: PropTypes.string,
      secondNoteText: PropTypes.string,
      thirdNoteText: PropTypes.string,
    })
  ),
}

BlockPaymentOption.defaultProps = {
  headerText: '',
  subHeading: '',
  paragraphText: '',
  subParagraph: '',
  backgroundColor: {
    hex: '',
  },
  buttonText: '',
  buttonHref: '',
  cards: [
    {
      image: imageDefaultProps,
      headerText: '',
      paragraphText: '',
      linkText: '',
      linkHref: '',
      firstNoteText: '',
      secondNoteText: '',
      thirdNoteText: '',
    },
  ],
}
