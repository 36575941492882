const getUrlParameter = ({ name, search }) => {
  const val = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  const regex = new RegExp('[\\?&]' + val + '=([^&#]*)')
  const results = regex.exec(search)
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

const triggerValues = {
  salesforce: 'salesforceCallback',
}

const resolveTriggerValue = triggerValue => triggerValues[triggerValue] || null

const listen = ({ location, setFormSuccess }) => {
  if (typeof window === 'undefined') return
  window.addEventListener(
    'message',
    event => {
      if (event.origin !== location.origin) return
      const { data } = event
      if (data === 'salesforceCallback') {
        setFormSuccess(true)
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },
    false
  )
}

const post = ({ location, setFormSuccess }) => {
  const { search } = location
  if (typeof window === 'undefined' || !search) return
  const triggerValue = getUrlParameter({ name: 'crm', search })
  const message = resolveTriggerValue(triggerValue)
  if (!message) return
  if (typeof setFormSuccess === 'function') {
    setFormSuccess(true)
  }
  window.parent.postMessage(message, '*')
}

export { post, listen }
