import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

import { ShadowStyled, ImageShadowHolderStyled } from './ComponentsStyled'

const TRANSITION_DURATION_S = { MIN: 2.5, MAX: 3 }

function getRandomNumberBetween(min, max) {
  return Math.floor(Math.random() * max) + min
}

function getRandomDelay(max_s) {
  return Math.random() * max_s
}

/**
 * Adds a shadow to the child element passed.
 *
 * You have the option to add a floating effect and/or a subtle rotate effect
 */
const ElementImageShadow = ({
  floatEffect,
  rotateEffect,
  duration,
  delay,
  shadowInset,
  children,
}) => {
  let imageAnimation = {}
  let shadowAnimation = {}

  if (floatEffect) {
    imageAnimation = { ...imageAnimation, y: [-7, 0, -7] }
    shadowAnimation = {
      ...shadowAnimation,
      scale: [0.8, 1, 0.8],
      opacity: [0.6, 1, 0.6],
    }
  }

  if (rotateEffect) {
    imageAnimation = { ...imageAnimation, rotate: [-1, 1, -1] }
  }

  if (!duration && Object.keys(imageAnimation).length > 0) {
    duration = getRandomNumberBetween(
      TRANSITION_DURATION_S.MIN,
      TRANSITION_DURATION_S.MAX
    )
  }

  if (!delay && Object.keys(imageAnimation).length > 0) {
    delay = getRandomDelay(1.5)
  }

  return (
    <ImageShadowHolderStyled shadowInset={shadowInset}>
      <motion.div
        animate={imageAnimation}
        transition={{
          duration,
          delay,
          yoyo: Infinity,
          ease: 'easeInOut',
        }}
      >
        {children}
      </motion.div>
      <motion.div
        animate={shadowAnimation}
        transition={{
          duration,
          delay,
          yoyo: Infinity,
          ease: 'easeInOut',
        }}
      >
        <ShadowStyled inset={shadowInset} />
      </motion.div>
    </ImageShadowHolderStyled>
  )
}

ElementImageShadow.propTypes = {
  floatEffect: PropTypes.bool,
  rotateEffect: PropTypes.bool,
  shadowInset: PropTypes.bool,
}

ElementImageShadow.defaultProps = {
  floatEffect: false,
  rotateEffect: false,
  shadowInset: false,
}

export default ElementImageShadow
