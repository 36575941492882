import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from '@pbds/grid'
import Box from '@pbds/box'
import { imageShape, imageDefaultProps } from '../../shared/proptypes'
import SideNav from './SideNav'
import NavGroup from './NavGroup'
import BareMinimumHeader from './BareMinimumHeader'
import LanguageSelector from './LanguageSelector'
import Hamburger from '../../svgs/Hamburger'
import PaybrightLogo from '../../svgs/PaybrightLogo'
import { MAX_SCREEN_WIDTH } from '../../shared/constants'
import triggerGtmEvent from './helpers'
import { ElementExpandableButton } from '../../elements/ElementExpandableButton'

const { Row, Col } = Grid

const StyledHeader = styled(Box)(
  ({ theme, isSticky }) => `
    box-shadow: ${theme.elevation['--elevation-regular-2']};
    position: relative;
    z-index: 1000;
    ${isSticky &&
      `background: white;
       position: fixed;
       top: 0;
       left: 0;
       width: 100%;
       z-index: 1001; // the stupid library Rangle introduced has z-index of 1000 on arrows
       animation: moveDown 0.5s ease-in-out;
       @keyframes moveDown {
         from {
            transform: translateY(-5rem);
          }
          to {
            transform: translateY(0rem);
          }
       }
       @keyframes rotate {
         0% {
          transform: rotateY(360deg);
         }
         100% {
          transform: rotateY(0rem);
         }
       }
    `}
  `
)

const HamburgerWrapper = styled(Box)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    display: none;
  }
  cursor: pointer;
`

const LanguageSelectorWrapper = styled(Box)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    display: none;
  }
`

// shared wrapper is used for the elements which
// are disappearing in xs and sm viewport
// like button and link
const SharedWrapper = styled(Box)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`

const getMenuItems = (items, from, to) => items.slice(from, to)

const Header = ({
  menuDropdown,
  menuDropdownTriggerText,
  menuItems,
  rightLinkText,
  rightLinkHref,
  buttonText,
  buttonHref,
  isBareMinimumHeader,
  isSticky,
  location,
  isLogoLeftAligned,
  isAdsLandingPage,
  image,
}) => {
  const { pathname } = location
  const lang = pathname.slice(1, 3)
  const isFrench = lang === 'fr'

  const filteredMenuItems = getMenuItems(menuItems, 0, 5)
  const [open, setVisibility] = useState(false)
  function handleOpen() {
    setVisibility(true)
  }
  function handleClose() {
    setVisibility(false)
  }

  if (isBareMinimumHeader) {
    return (
      <StyledHeader
        as="header"
        padding={['--spacing-03', '--spacing-03', '--spacing-05']}
      >
        <BareMinimumHeader
          isLogoLeftAligned={isLogoLeftAligned}
          image={image}
          location={location}
        />
      </StyledHeader>
    )
  }

  return (
    <>
      <SideNav
        rightLinkText={rightLinkText}
        rightLinkHref={rightLinkHref}
        buttonText={buttonText}
        buttonHref={buttonHref}
        handleClose={handleClose}
        menuItems={menuItems}
        open={open}
        location={location}
      />
      <StyledHeader
        as="header"
        paddingY={['--spacing-03', '--spacing-03', 0, 0, 0]}
        isSticky={isSticky}
      >
        <Box
          maxWidth={MAX_SCREEN_WIDTH}
          marginX="auto"
          paddingX={[
            '--spacing-03',
            '--spacing-03',
            '--spacing-04',
            '--spacing-04',
            '--spacing-07',
          ]}
        >
          <Grid margin={false} gutter={false} limitWidth={false}>
            <Row verticalAlign="middle">
              <Col xs={6} xl={2}>
                <Box marginTop={['--spacing-01']}>
                  <PaybrightLogo location={location} />
                </Box>
              </Col>
              <Col xs={0} xl={6} horizontalAlign="left">
                <Box marginLeft="--spacing-06">
                </Box>
              </Col>
              <Col xs={6} md={6} lg={4} lgOffset={2} xl={4} xlOffset={0}>
                <Box flexAlign="middleRight" flexDirection="row" paddingY={['--spacing-03']}>
                  <SharedWrapper flexAlign="middleRight" flexDirection="row">
                    <Box minWidth="100px" marginLeft="--spacing-03">
                      <ElementExpandableButton
                        buttonLinks={menuDropdown}
                        buttonText={menuDropdownTriggerText}
                      />
                    </Box>
                  </SharedWrapper>
                  <HamburgerWrapper
                    marginTop={['--spacing-01', '--spacing-01', 0]}
                    marginLeft="--spacing-05"
                  >
                    <Hamburger onClick={handleOpen} />
                  </HamburgerWrapper>
                  <LanguageSelectorWrapper marginLeft="--spacing-05">
                    <LanguageSelector />
                  </LanguageSelectorWrapper>
                </Box>
              </Col>
            </Row>
          </Grid>
        </Box>
      </StyledHeader>
    </>
  )
}

Header.defaultProps = {
  menuDropdown: NavGroup.defaultProps,
  menuDropdownTriggerText: '',
  menuItems: NavGroup.defaultProps,
  rightLinkText: '',
  rightLinkHref: '',
  buttonText: '',
  buttonHref: '',
  isBareMinimumHeader: false,
  isLogoLeftAligned: false,
  image: imageDefaultProps,
}

Header.propTypes = {
  menuDropdown: NavGroup.propTypes,
  menuDropdownTriggerText: PropTypes.string,
  menuItems: NavGroup.propTypes,
  rightLinkText: PropTypes.string,
  rightLinkHref: PropTypes.string,
  buttonText: PropTypes.string,
  buttonHref: PropTypes.string,
  isBareMinimumHeader: PropTypes.bool,
  isLogoLeftAligned: PropTypes.bool,
  image: imageShape,
}

export default Header
