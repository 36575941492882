import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Box from '@pbds/box'
import styled from 'styled-components'
import { MOVING_CAROUSEL_CONFIG } from '../../shared/carousel_config'
import { MAX_SCREEN_WIDTH } from '../../shared/constants'
import theme from '@pbds/theme'

const SectionBox = styled(Box)`
  position: relative;
`
const Edge = styled('div')`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100px;
  z-index: 1;
  background: linear-gradient(
    to ${({ side }) => side},
    rgba(255, 255, 255, 0),
    white
  );
  ${({ side }) => side}: -1px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    display: block;
  }
`

const lg = parseInt(theme.breakpoints.lg)
const md = parseInt(theme.breakpoints.md)
const sm = parseInt(theme.breakpoints.sm)

const responsive = {
  desktopxl: {
    breakpoint: {
      max: 3000,
      min: lg,
    },
    items: 6,
  },
  desktop: {
    breakpoint: {
      max: lg,
      min: md,
    },
    items: 5,
  },
  tablet: {
    breakpoint: {
      max: md,
      min: sm,
    },
    items: 4,
  },
  mobile: {
    breakpoint: {
      max: sm,
      min: 0,
    },
    items: 2,
  },
}

const BlockMerchantLogosCarousel = ({ merchantLogos }) => {
  return (
    <SectionBox maxWidth={MAX_SCREEN_WIDTH} margin="0 auto" as="section">
      <Edge side="left" />
      <Carousel responsive={responsive} {...MOVING_CAROUSEL_CONFIG}>
        {merchantLogos.map(logo => (
          <Box height="90px" flexAlign="middleCenter" key={logo.fixed.src}>
            <Img fixed={logo.fixed} alt={logo.alt} />
          </Box>
        ))}
      </Carousel>
      <Edge side="right" />
    </SectionBox>
  )
}

export default BlockMerchantLogosCarousel
