import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import { imageShape, imageDefaultProps } from '../../shared/proptypes'
import Grid from '@pbds/grid'

const BlockSimpleImage = ({ image }) => (
  <Grid>
    <Grid.Row>
      <Grid.Col>
        <Img alt={image.alt} fluid={image.fluid} />
      </Grid.Col>
    </Grid.Row>
  </Grid>
)

export default BlockSimpleImage

BlockSimpleImage.propTypes = {
  image: imageShape,
}

BlockSimpleImage.defaultProps = {
  image: imageDefaultProps,
}
