import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Box from '@pbds/box'
import Typography from '@pbds/typography'
import Grid from '@pbds/grid'
import { PrimaryButton, SecondaryButton } from '@pbds/buttons'
import LeftShapes from './assets/left-cropped.png'
import RightShapes from './assets/right-cropped.png'
import MobileLeft from './assets/left-mobile-cropped.png'
import MobileRight from './assets/right-mobile-cropped.png'

const { Row, Col } = Grid
const { Heading } = Typography

const WrapperBox = styled(Box)`
  position: relative;
`

const Background = styled(Box)(
  ({ theme, isLimitedWidth }) => `
  position: relative;
  overflow: hidden;
  background-color: ${theme.colors['--ui-primary-default']};
  background-size: auto 100%;
  background-position: left, right;
  background-repeat: no-repeat;
  background-image: url(${MobileLeft}), url(${MobileRight});
  @media screen and (min-width: ${theme.breakpoints.sm}) {
    background-position: -100px, calc(100% + 100px);
    background-image: url(${LeftShapes}), url(${RightShapes});
  }
  @media screen and (min-width: ${theme.breakpoints.md}) {
    background-position: -50px, calc(100% + 50px);
  }
  @media screen and (min-width: ${theme.breakpoints.lg}) {
    background-position: -20px, calc(100% + 20px);
    border-radius: ${isLimitedWidth ? theme.radii['--corner-radius-8'] : 0};
  }
`
)

const ContentBox = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const BackgroundConfig = {
  height: ['416px', '346px'],
  flexAlign: 'middleCenter',
  width: '100%',
}

const BlockCenteredCardBanner = ({
  headerText,
  firstButtonHref,
  firstButtonText,
  secondButtonText,
  secondButtonHref,
  isLimitedWidth,
}) => {
  const isFirstButtonInternalLink = firstButtonHref.startsWith('/')
  const isSecondButtonInternalLink =
    secondButtonHref && secondButtonHref.startsWith('/')
  return (
    <WrapperBox
      isLimitedWidth={isLimitedWidth}
      as="section"
      backgroundColor="white"
      flexAlign="middleCenter"
      height={['416px', '346px', isLimitedWidth ? '490px' : '346px']}
    >
      {isLimitedWidth ? (
        <Grid gutter={false} margin={false}>
          <Row horizontalAlign="center">
            <Col xs={12} lg={10}>
              <Background
                {...BackgroundConfig}
                isLimitedWidth={isLimitedWidth}
              ></Background>
            </Col>
          </Row>
        </Grid>
      ) : (
        <Background
          {...BackgroundConfig}
          isLimitedWidth={isLimitedWidth}
        ></Background>
      )}
      <ContentBox flexAlign="middleCenter">
        <Grid>
          <Row horizontalAlign="center">
            <Col xs={10} lg={4}>
              <Heading color="white" variant="--heading-2">
                {headerText}
              </Heading>
            </Col>
          </Row>
          <Box
            flexDirection={['column', 'row']}
            flexAlign={['', 'middleCenter']}
            width="100%"
          >
            <Box marginTop="--spacing-04" marginX="--spacing-04">
              <PrimaryButton
                to={isFirstButtonInternalLink ? firstButtonHref : null}
                href={isFirstButtonInternalLink ? null : firstButtonHref}
                buttonType={isFirstButtonInternalLink ? Link : 'a'}
                buttonSize={'--large-button'}
                useInverseColors={true}
              >
                {firstButtonText}
              </PrimaryButton>
            </Box>
            {secondButtonText && (
              <Box marginTop="--spacing-04" marginX="--spacing-04">
                <SecondaryButton
                  to={isSecondButtonInternalLink ? secondButtonHref : null}
                  href={isSecondButtonInternalLink ? null : secondButtonHref}
                  buttonType={isSecondButtonInternalLink ? Link : 'a'}
                  buttonSize={'--large-button'}
                  useInverseColors={true}
                >
                  {secondButtonText}
                </SecondaryButton>
              </Box>
            )}
          </Box>
        </Grid>
      </ContentBox>
    </WrapperBox>
  )
}

export default BlockCenteredCardBanner

BlockCenteredCardBanner.propTypes = {
  headerText: PropTypes.string,
  firstButtonHref: PropTypes.string,
  firstButtonText: PropTypes.string,
  secondButtonText: PropTypes.string,
  secondButtonHref: PropTypes.string,
  isLimitedWidth: PropTypes.bool,
}

BlockCenteredCardBanner.defaultProps = {
  headerText: '',
  firstButtonHref: '',
  firstButtonText: '',
  secondButtonText: '',
  secondButtonHref: '',
  isLimitedWidth: false,
}
