import React from 'react'
import Grid from '@pbds/grid'
import Typography from '@pbds/typography'
import Box from '@pbds/box'

import { Caution } from '@pbds/icons'
import { CautionCircle, CautionWrapper, StyledCaption } from '../styles'

const { Col } = Grid
const { Deck } = Typography

const NoResults = ({
  searchErrorMessage,
  searchTerm,
  searchErrorWithFilter,
  tipText,
  filterByPromotion,
}) => {
  return (
    <Col horizontalAlign="center">
      <CautionWrapper>
        <CautionCircle>
          <Caution />
        </CautionCircle>
        <Deck
          marginTop="--spacing-03"
          color="#808080"
          variant="--deck-emphasized"
        >
          {searchErrorMessage} "{searchTerm}"
        </Deck>
        {filterByPromotion && (
          <Box marginTop={12}>
            <StyledCaption variant="--caption-semi-emphasized">
              {tipText}
            </StyledCaption>{' '}
            <StyledCaption variant="--caption-regular">
              {searchErrorWithFilter}
            </StyledCaption>
          </Box>
        )}
      </CautionWrapper>
    </Col>
  )
}

export default NoResults
