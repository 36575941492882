import React from 'react'
import Grid from '@pbds/grid'
import MerchantCard from '@pbds/merchant-card'
import { MerchantCardWrapper } from '../styles'
import { LOADING_CARDS_NUMBER } from '../utils/constants'

const { Col } = Grid

const LoadingMerchants = () => {
  // creating an array with each iteam containing 0 value for showing cards
  const merchantsArray = new Array(LOADING_CARDS_NUMBER).fill(0)
  return merchantsArray.map(card => (
    <Col xs={6} md={3}>
      <MerchantCardWrapper>
        <MerchantCard variant="tall" isLoading />
      </MerchantCardWrapper>
    </Col>
  ))
}

export default LoadingMerchants
