import React from 'react'
import PropTypes from 'prop-types'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Box from '@pbds/box'
import Grid from '@pbds/grid'
import { MOVING_CAROUSEL_CONFIG } from '../../shared/carousel_config'
import { MAX_SCREEN_WIDTH } from '../../shared/constants'
import ElementCarouselRegular from '../ElementCarouselRegular'
import { beigeBread25 } from '@pbds/core-colors'

import { Edge, MarqueeBox } from './ComponentsStyled'

const { Row, Col } = Grid

const marquee_responsive = {
  desktopxl: {
    breakpoint: {
      max: 3000,
      min: 0,
    },
    items: 3,
  },
}

const DesktopMarquee = ({ children, backgroundColor }) => (
  <MarqueeBox maxWidth={MAX_SCREEN_WIDTH} marginX="auto">
    <Edge side="left" backgroundColor={backgroundColor} />
    <Carousel
      responsive={marquee_responsive}
      {...MOVING_CAROUSEL_CONFIG}
      transitionDuration={9000}
      customTransition="all 9s linear"
    >
      {children}
    </Carousel>
    <Edge side="right" backgroundColor={backgroundColor} />
  </MarqueeBox>
)

const ElementMarqueeAndCarousel = ({
  backgroundColor,
  marqueeBreakpoint,
  children,
}) => {
  const breakPointCarouselProps = { [marqueeBreakpoint]: 0 }
  const breakPointMarqueeProps = { [marqueeBreakpoint]: 12 }

  return (
    <Box
      margin="0 auto"
      paddingBottom={[
        '--spacing-06',
        '--spacing-06',
        '--spacing-06',
        '--spacing-04',
      ]}
    >
      {/* Carousel on mobile/tablet */}
      <Grid margin={false}>
        <Row>
          <Col mdOffset={2} md={8} {...breakPointCarouselProps}>
            {/* Negative margin to offset the padding we have to give to the carousel items to accomodate the shadow  */}
            <ElementCarouselRegular
              marginX={[0, 0, '-0.8rem']}
              removeArrowOnDeviceType={['desktop', 'mobile', 'tablet']}
            >
              {children}
            </ElementCarouselRegular>
          </Col>
        </Row>
      </Grid>

      {/* Marquee on desktop */}
      <Grid limitWidth={false} margin={false} gutter={false}>
        <Row>
          <Col xs={0} {...breakPointMarqueeProps}>
            <DesktopMarquee backgroundColor={backgroundColor}>
              {children}
            </DesktopMarquee>
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}

ElementMarqueeAndCarousel.propTypes = {
  backgroundColor: PropTypes.string,
  marqueeBreakpoint: PropTypes.string,
  children: PropTypes.node,
}

ElementMarqueeAndCarousel.defaultProps = {
  marqueeBreakpoint: 'lg', // The breakpoint where the carousel turns into a marquee
  backgroundColor: beigeBread25,
  children: null,
}

export default ElementMarqueeAndCarousel
