import Box from '@pbds/box'
import styled, { keyframes, css } from 'styled-components'

const fadeDown = keyframes`
  0% {
    transform: translateY(-30px);
    opacity: 0;
  }

  70%{
      transform: none;
      opacity: .7;
  }

  100% {
    transform: none;
    opacity:1;
  }
`

const fadeUp = keyframes`
  0% {
    transform: translateY(30px);
    opacity: 0;
  }

  80%{
      transform: none;
      opacity: .8;
  }

  100% {
    transform: none;
    opacity:1;
  }
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity:1;
  }
`

const getAnimation = (keyframe, ms, delay) => css`
  ${keyframe} ${ms}ms  ${delay}ms forwards ease-out
`

export const StyledBox = styled(Box)(
  () => css`
    h1 {
      opacity: 0;
      animation: ${getAnimation(fadeUp, 1500, 500)};
    }
    p + button,
    p + a,
    h1 + button h1 + a {
      opacity: 0;
      animation: ${getAnimation(fadeDown, 2000, 900)};
    }
    p {
      opacity: 0;
      animation: ${getAnimation(fadeIn, 1000, 1900)};
    }
    a,
    button {
      opacity: 0;
      animation: ${getAnimation(fadeIn, 1500, 300)};
    }
  `
)
