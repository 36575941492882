import styled from 'styled-components'
import { beigeBread50 } from '@pbds/core-colors'
import Box from '@pbds/box'

export const ContainerSectionStyled = styled.section`
  position: relative;
  overflow: hidden;
`

export const HotDogGridStyled = styled.div(
  ({ theme }) => `
    padding-top: ${theme.space['--spacing-05']}
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;

    [class^='GutterDivStyled'],
    [class^='Col__'],
    [class^='BoxComponentStyled'],
    .container-fluid,
    .row,
    & {
        height: 100%;
    }
    `
)

export const HotDogCircleHolderStyled = styled.div(
  ({ reverse }) => `
  position: absolute;
  ${reverse ? `left: 0` : `right: 0`};
  ${reverse ? `bottom: 0` : `top: 0`};
  transform: ${reverse ? `translateX(3rem)` : `translateX(-3rem)`};

  &:before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 3px;
    right: 0;
    ${reverse ? `left: 0` : `right: 0`};

    width: 3000px; // Appropriately long for the widest screens

    background-color: ${beigeBread50};
  }
`
)

export const HotDogCircleStyled = styled.img(
  ({ reverse }) => `
  height: 25vw;
  min-height: 6rem;
  max-height: 23rem;
  transform: ${reverse ? `translateX(-50%)` : `translateX(50%)`};
  // Target IE only
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    transform: ${reverse ? `translateX(-100%)` : `translateX(100%)`};
 }
`
)

export const HotDogHolderStyled = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

export const GatsbyImageHolder = styled(Box)`
  > div {
    width: 100%;
  }
`
