import React, { useState } from 'react'
import styled from 'styled-components'

const StyledParagraph = styled.p`
  margin: 10px;
  position: absolute;
`

const BlockIdentifier = ({ children, contentType }) => {
  const [isHovered, setHover] = useState(false)

  return (
    // eslint-disable-next-line
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {isHovered && (
        <StyledParagraph>
          {contentType && contentType.substring(7)}
        </StyledParagraph>
      )}
      {children}
    </div>
  )
}

export default BlockIdentifier
