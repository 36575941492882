import React from 'react'
import PropTypes from 'prop-types'

const BASEURL = 'https://tags.w55c.net/rs'
const queryParams = ['t=marketting']
const style = { position: 'absolute', visibility: 'hidden' }

const BlockPageAnalytic = ({ dataxuPixelId }) => {
  if (!dataxuPixelId) return null
  queryParams.unshift(`id=${dataxuPixelId}`)
  const src = `${BASEURL}?${queryParams.join('&')}`
  return <img id="dataxuPixelId" style={style} alt="" src={src} />
}

BlockPageAnalytic.defaultProps = {
  dataxuPixelId: '',
}

BlockPageAnalytic.propTypes = {
  dataxuPixelId: PropTypes.string,
}

export default BlockPageAnalytic
