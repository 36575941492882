import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { motion, useCycle, AnimatePresence } from 'framer-motion'
import Grid from '@pbds/grid'
import Box from '@pbds/box'
import Typography from '@pbds/typography'
import { beigeBread75 } from '@pbds/core-colors'
import { PrimaryButton, SecondaryButton } from '@pbds/buttons'
import {
  ImagesHolderStyled,
  ImageHolderStyled,
  SectionStyled,
  HiddenDivStyled,
  AnimationHolderDivStyled,
  HeadingAnimationHolderStyled,
} from './HomeSliderComponentsStyled'
import ElementImageShadow from '../../elements/ElementImageShadow'
import {
  FLOAT_ELEMS,
  ROTATE_ELEMS,
  TIMING_RULES,
  IMAGE_DELAY_MAP,
} from './imageAnimation'

const { Heading, Deck } = Typography
const { Row, Col } = Grid

const TRANSITION_DURATION_S = 1.25
const HEADER_TRANSITION_DELAY_S = 0.25
const SLIDE_CHANGE_TIME_MS = 4000

const framerMotionImageTransitionVariants = {
  visible: i => ({
    opacity: 1,
    transition: {
      delay: IMAGE_DELAY_MAP[i] * 0.15,
      duration: TRANSITION_DURATION_S,
    },
    hidden: {
      opacity: 0,
    },
  }),
}

const renderButton = (buttonText, buttonTo, isPrimary, hasArrow) => {
  const Button = isPrimary ? PrimaryButton : SecondaryButton
  return (
    <Box
      marginY={['--spacing-02', '--spacing-03']}
      marginRight="--spacing-03"
      width={['100%', '50%', '50%', 'auto']}
    >
      <Button
        to={buttonTo}
        buttonType={Link}
        buttonSize="--large-button"
        rightIcon={hasArrow ? 'rightArrow' : null}
        width="100%"
      >
        {buttonText}
      </Button>
    </Box>
  )
}

function createProductImage(image, index) {
  const floatEffect = FLOAT_ELEMS.includes(index)
  const rotateEffect = ROTATE_ELEMS.includes(index)
  return (
    <ImageHolderStyled index={index}>
      <ElementImageShadow
        floatEffect={floatEffect}
        rotateEffect={rotateEffect}
        shadowInset={true}
        duration={TIMING_RULES[index] && TIMING_RULES[index].DURATION_S}
        delay={TIMING_RULES[index] && TIMING_RULES[index].DELAY_S}
      >
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            key={image.url}
            transition={{ duration: TRANSITION_DURATION_S }}
            custom={index}
            animate="visible"
            variants={framerMotionImageTransitionVariants}
            style={{ position: 'absolute' }}
          >
            <img src={image.url} role="presentation" alt="" />
          </motion.div>
        </AnimatePresence>

        {/* Required to show correct spacing for animation elements */}
        <img
          src={image.url}
          role="presentation"
          alt=""
          style={{ opacity: 0 }}
        />
      </ElementImageShadow>
    </ImageHolderStyled>
  )
}

const HeadingAnimation = ({ currentSlide, vertical = false }) => {
  const initial = vertical
    ? { opacity: 0, y: -30, textAlign: 'center' }
    : { opacity: 0, x: 50 }
  const animate = vertical
    ? { opacity: [0, 1, 1], y: [-30, 0, 0] }
    : { opacity: [0, 0, 1], x: [50, 50, 0] }
  const exit = vertical ? { opacity: 0, y: -70 } : { opacity: 0, x: 100 }

  return (
    <>
      {/* Putting a bunch of one-off divs here to position the animation properly. */}
      <AnimationHolderDivStyled>
        <Box
          flexAlign={[
            'middleCenter',
            'middleCenter',
            'middleCenter',
            'middleCenter',
            'middleLeft',
          ]}
        >
          <AnimatePresence>
            <motion.div
              initial={{ ...initial, width: '100%' }}
              animate={animate}
              exit={exit}
              transition={{
                duration: TRANSITION_DURATION_S,
                delay: HEADER_TRANSITION_DELAY_S,
              }}
              key={currentSlide.categoryText}
              role="presentation"
            >
              <HeadingAnimationHolderStyled vertical={vertical}>
                <Heading variant="--heading-1">
                  {currentSlide.categoryText}
                </Heading>
              </HeadingAnimationHolderStyled>
            </motion.div>
          </AnimatePresence>
        </Box>
      </AnimationHolderDivStyled>
      <HiddenDivStyled>
        {/* Text for screenreaders, gives correct spacing for absolute positioned element above.  */}
        <Heading variant="--heading-1">{currentSlide.categoryText}</Heading>
      </HiddenDivStyled>
    </>
  )
}

const BlockMainHomeSlider = ({
  heading,
  subHeading,
  firstButtonText,
  firstButtonHref,
  firstButtonIsPrimary,
  firstButtonHasArrow,
  secondButtonText,
  secondButtonHref,
  secondButtonIsPrimary,
  secondButtonHasArrow,
  slides,
}) => {
  const [currentSlide, setCurrentSlide] = useCycle(...slides)

  useEffect(() => {
    const timeOut = setTimeout(setCurrentSlide, SLIDE_CHANGE_TIME_MS)
    return () => clearTimeout(timeOut)
  }, [currentSlide, setCurrentSlide])

  return (
    <SectionStyled
      as="section"
      paddingY={[
        '--spacing-08',
        '--spacing-08',
        '--spacing-08',
        '--spacing-08',
        '--spacing-10',
      ]}
      backgroundColor={beigeBread75}
    >
      <Grid>
        <Row>
          <Col sm={8} smOffset={2} xl={5} xlOffset={1}>
            <Box
              flexAlign={[
                'middleCenter',
                'middleCenter',
                'middleCenter',
                'middleCenter',
                'middleLeft',
              ]}
            >
              {currentSlide && (
                <Box
                  flexAlign={[
                    'middleCenter',
                    'middleCenter',
                    'middleCenter',
                    'middleCenter',
                    'middleLeft',
                  ]}
                  width="100%"
                >
                  <Grid margin={false}>
                    <Row>
                      <Col xs={0} xl={12}>
                        <HeadingAnimation
                          currentSlide={currentSlide}
                        ></HeadingAnimation>
                      </Col>
                      <Col xs={12} xl={0}>
                        <HeadingAnimation
                          currentSlide={currentSlide}
                          vertical={true}
                        ></HeadingAnimation>
                      </Col>
                    </Row>
                  </Grid>
                </Box>
              )}
              <Heading
                variant="--heading-1"
                marginBottom="--spacing-02"
                textAlign={['center', 'center', 'center', 'center', 'left']}
              >
                {heading}
              </Heading>
              <Deck
                variant="--deck-regular"
                textAlign={['center', 'center', 'center', 'center', 'left']}
                marginY="--spacing-02"
                as="span"
              >
                {subHeading}
              </Deck>
            </Box>
          </Col>
        </Row>
      </Grid>

      <Grid>
        <Row>
          <Col
            xs={8}
            xsOffset={2}
            sm={10}
            smOffset={1}
            md={8}
            mdOffset={2}
            xl={5}
            xlOffset={1}
          >
            <Box
              flexAlign={[
                'middleCenter',
                'middleCenter',
                'middleCenter',
                'middleCenter',
                'middleLeft',
              ]}
              flexDirection={['column', 'row']}
            >
              {firstButtonText &&
                renderButton(
                  firstButtonText,
                  firstButtonHref,
                  firstButtonIsPrimary,
                  firstButtonHasArrow
                )}
              {secondButtonText &&
                renderButton(
                  secondButtonText,
                  secondButtonHref,
                  secondButtonIsPrimary,
                  secondButtonHasArrow
                )}
            </Box>
          </Col>
        </Row>
      </Grid>

      <ImagesHolderStyled role="presentation">
        {currentSlide && currentSlide.images.map(createProductImage)}
      </ImagesHolderStyled>
    </SectionStyled>
  )
}

BlockMainHomeSlider.defaultProps = {
  heading: '',
  subHeading: '',
  firstButtonText: '',
  firstButtonHref: '',
  firstButtonIsPrimary: false,
  firstButtonHasArrow: false,
  secondButtonText: '',
  secondButtonHref: '',
  secondButtonIsPrimary: false,
  secondButtonHasArrow: false,
  slides: [
    {
      categoryText: '',
      images: [
        {
          url: '',
        },
      ],
    },
  ],
}

BlockMainHomeSlider.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  firstButtonText: PropTypes.string,
  firstButtonHref: PropTypes.string,
  firstButtonIsPrimary: PropTypes.bool,
  firstButtonHasArrow: PropTypes.bool,
  secondButtonText: PropTypes.string,
  secondButtonHref: PropTypes.string,
  secondButtonIsPrimary: PropTypes.bool,
  secondButtonHasArrow: PropTypes.bool,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      categoryText: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
        })
      ),
    })
  ),
}

export default BlockMainHomeSlider
