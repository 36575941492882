import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useLocation } from '@reach/router'
import { navigate } from '@reach/router'
import Grid from '@pbds/grid'
import 'whatwg-fetch'

import NavSection from './components/NavSection'
import useDebounce from '../../shared/hooks/useDebounce'

import { StyledSection } from './styles'

import { API_ROOT, FILTERING_KEYS } from './utils/constants'
import FilterSection from './components/FilterSection'
import MerchantSection from './components/MerchantsSection'
import ShowMoreButton from './components/ShowMoreButton'
import GoToTop from './components/GoToTop'
import TopSection from './components/TopSection'

const constructCategory = category => (category === 'All' ? '' : category)

const constructFilterByPromotion = filterByPromotion =>
  filterByPromotion && 'filterByPromotion=yes'

const BlockMerchantDirectory = ({
  headerText,
  paragraphText,
  searchPlaceHolder,
  sortLabel,
  categoriesLabel,
  sortOptions,
  categoriesOptions,
  filterText,
  cardCtaText,
  showMoreButtonText,
  searchErrorMessage,
  tipText,
  searchErrorWithFilter,
  lang,
  bannerBackgroundImageDesktop,
  bannerBackgroundImageTablet,
  bannerBackgroundImageMobile,
}) => {
  const location = useLocation()
  const parsedParams = Object.fromEntries(new URLSearchParams(location.search).entries())
  const [isLoading, setLoading] = useState(false)
  const [category, setCategory] = useState('All')
  const [search, setSearch] = useState('')
  const [merchants, setMerchants] = useState([])
  const [filterByPromotion, setFilterByPromotion] = useState(false)
  const [sortBy, setSortBy] = useState('entrytitle_ASC')
  const [page, setPage] = useState(1)
  const [isSticky, setSticky] = useState(false)
  const [hasNoResults, setHasNoResults] = useState(false)
  const [isWindowReady, setIsWindowReady] = useState(false)
  const [numberOfReturnedMerchants, setNumberofReturnedMerchants] = useState(0)

  const stickyRef = useRef(null)
  const isMounted = useRef(false)

  const handleScroll = () => {
    if (stickyRef.current) {
      window.innerWidth < 1200 &&
      window.pageYOffset > stickyRef.current.getBoundingClientRect().top
        ? setSticky(true)
        : setSticky(false)
    }
  }

  const debouncedSearchTerm = useDebounce(search, 500)
  const selectedCategory = constructCategory(category)
  const filter = constructFilterByPromotion(filterByPromotion)

  // if you don't have local node server running swap the environment variable with 'staging'
  // remember to switch back the GATSBY_APP_ENV after the feature work is done
  const endPoint = `${
    API_ROOT[process.env.GATSBY_APP_ENV || 'staging']
  }/?category=${selectedCategory}&search=${debouncedSearchTerm}&${filter}&sortBy=${sortBy}&page=${page}`

  // this function will set the url based on the changes to filters
  const setUrl = () => {
    navigate(
      `?category=${selectedCategory}&sortBy=${sortBy}&search=${debouncedSearchTerm}&${filter}`
    )
  }

  const handleCategoryChange = category => {
    setPage(1)
    setCategory(category)
  }

  const toggleFilter = () => {
    setPage(1)
    setFilterByPromotion(prev => !prev)
  }

  const handleChangeSearchTerm = event => {
    setHasNoResults(false)
    setPage(1)
    setSearch(event.target.value)
  }

  const handleChangeSortBy = event => {
    setPage(1)
    setSortBy(event.target.value)
  }

  const showMore = () => {
    setPage(page + 1)
  }

  const fetchFromApi = useCallback(async () => {
    setLoading(true)
    return fetch(endPoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(res => {
        setLoading(false)
        if (Array.isArray(res.items)) {
          return res.items
        }
        return []
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })
  }, [endPoint])

  typeof window === 'object' && window.addEventListener('scroll', handleScroll)

  useEffect(() => {
    // this useEffect is used to change the URL based on filtering changes

    // this check is to make sure we don't run setUrl on the first load
    // if setUrl is run on mount all the query params will be overridden by default state
    if (isMounted.current) {
      setUrl()
    } else {
      isMounted.current = true
    }
    // eslint-disable-next-line
  }, [selectedCategory, filter, debouncedSearchTerm, sortBy])

  useEffect(() => {
    // this useEffect will run on mount and will change the state based on url query params

    FILTERING_KEYS.forEach(key => {
      if (Object.keys(parsedParams).includes(key)) {
        if (parsedParams[key]) {
          // disabling because of eval warning
          // eval won't be a security risk here
          // eslint-disable-next-line
          eval(
            'set' +
              key.charAt(0).toUpperCase() +
              key.slice(1) +
              '(parsedParams[key])'
          )
        }
      }
    })
    setIsWindowReady(true)
    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
    // disabling because complains about parsedParams not being a dependancy
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // this useEffect is used for fetching from API if endpoint is changed

    fetchFromApi().then(items => {
      setNumberofReturnedMerchants(items.length)
      // if in the first page the result is 0 items we are sure that
      // it is due to the search terms
      if (items.length === 0 && page === 1) {
        setHasNoResults(true)
      } else {
        setHasNoResults(false)
      }
      let NewMerchants = items
      if (page !== 1) {
        NewMerchants = [...merchants, ...items]
      }
      setMerchants(NewMerchants)
    })
    // disabling due to complaining about merchants being a dependacy
    // eslint-disable-next-line
  }, [endPoint, fetchFromApi, page])

  return (
    <StyledSection id="#top">
      <TopSection
        headerText={headerText}
        paragraphText={paragraphText}
        searchPlaceHolder={searchPlaceHolder}
        sortLabel={sortLabel}
        searchTerm={search}
        categoriesLabel={categoriesLabel}
        sortOptions={sortOptions}
        categoriesOptions={categoriesOptions}
        handleChangeSearchTerm={handleChangeSearchTerm}
        handleChangeSortBy={handleChangeSortBy}
        handleCategoryChange={handleCategoryChange}
        selectedCategory={category}
        sortBy={sortBy}
        isWindowReady={isWindowReady}
        backgroundImage={{
          backgroundDesktop: bannerBackgroundImageDesktop?.url,
          backgroundTablet: bannerBackgroundImageTablet?.url,
          backgroundMobile: bannerBackgroundImageMobile?.url,
        }}
      />

      <NavSection
        categoriesOptions={categoriesOptions}
        handleCategoryChange={handleCategoryChange}
        selectedCategory={category}
      />
      <Grid>
        <FilterSection
          filterText={filterText}
          stickyRef={stickyRef}
          isSticky={isSticky}
          toggleFilter={toggleFilter}
          filterByPromotion={filterByPromotion}
        />
        <MerchantSection
          filterByPromotion={filterByPromotion}
          searchErrorMessage={searchErrorMessage}
          tipText={tipText}
          searchErrorWithFilter={searchErrorWithFilter}
          categoriesOptions={categoriesOptions}
          lang={lang}
          selectedCategory={category}
          cardCtaText={cardCtaText}
          merchants={merchants}
          hasNoResults={hasNoResults}
          searchTerm={search}
          page={page}
          isLoading={isLoading}
        />
        <GoToTop />

        <ShowMoreButton
          numberOfReturnedMerchants={numberOfReturnedMerchants}
          showMoreButtonText={showMoreButtonText}
          merchants={merchants}
          isLoading={isLoading}
          showMore={showMore}
        />
      </Grid>
    </StyledSection>
  )
}

export default BlockMerchantDirectory
