import React, { useContext } from 'react'
import { Location, useLocation } from '@reach/router'
import styled, { ThemeContext } from 'styled-components'
import { PrimaryButton } from '@pbds/buttons'
import Grid from '@pbds/grid'
import Link from '@pbds/link'
import Typography from '@pbds/typography'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { isWindows } from 'react-device-detect'

const { Body, Heading } = Typography
const { Row } = Grid

const SubHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 1px;
  padding-top: 6px;
  position: relative;
  top: ${isWindows ? '-3px' : '-1px'};
`

const CookieConsentBanner = () => {
  const { colors } = useContext(ThemeContext)
  const location = useLocation()

  const renderContent = lang => {
    const isEnglish = lang === 'en'
    return (
      <Grid margin={false} gutter={false}>
        <Row>
          <Heading
            variant="--heading-4"
            paddingRight="--spacing-02"
            textAlign="left"
          >
            {isEnglish
              ? `This website uses cookies to enhance user experience.`
              : `Ce site web utilise des cookies pour améliorer l'expérience utilisateur.`}
          </Heading>
          <SubHeadingContainer>
            <Body variant="--body-02-regular">
              {isEnglish ? (
                <>
                  {`Please see our `}
                  <Link to={`/${lang}/privacy`}>
                    <Body variant="--body-02-semi-emphasized">
                      {`Privacy Statement`}
                    </Body>
                  </Link>
                  {` for more information.`}
                </>
              ) : (
                <>
                  {`Veuillez consulter notre `}
                  <Link to={`/${lang}/privacy`}>
                    <Body variant="--body-02-semi-emphasized">
                      {`déclaration de protection`}
                    </Body>
                  </Link>
                  {`  de la vie privée pour plus d'informations.`}
                </>
              )}
            </Body>
          </SubHeadingContainer>
        </Row>
      </Grid>
    )
  }

  const ButtonText = lang => (
    <PrimaryButton>{lang === 'en' ? 'Close' : 'Fermer'}</PrimaryButton>
  )

  return (
    <Location>
      {({ location }) => {
        const { pathname } = location
        const lang = pathname.slice(1, 3)
        return (
          <CookieConsent
            location="bottom"
            buttonText={ButtonText(lang)}
            cookieName="cookie-notification"
            flipButtons
            disableButtonStyles
            buttonStyle={{
              border: 'none',
              backgroundColor: 'transparent',
              outline: 'none',
              margin: '0 10px',
            }}
            style={{
              backgroundColor: colors['--ui-light'],
              borderTop: `1px solid ${colors['--ui-inactive-01']}`,
              padding: '15px',
              display: 'flex',
              alignItems: 'center',
              boxShadow: '0px -2px 11px rgba(0,0,0, .15)',
            }}
            contentStyle={{ color: colors['--title-dark'] }}
          >
            {renderContent(lang)}
          </CookieConsent>
        )
      }}
    </Location>
  )
}

export default CookieConsentBanner
