import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { imageShape, imageDefaultProps } from '../../shared/proptypes'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'
import Box from '@pbds/box'
import Grid from '@pbds/grid'
import { PrimaryButton } from '@pbds/buttons'
import Typography from '@pbds/typography'
import GrayHotdog from '../../svgs/GrayHotdog'
import GrayCircle from '../../svgs/GrayCircle'
import { beigeBread50 } from '@pbds/core-colors'

const { Row, Col } = Grid
const { Heading } = Typography

const StyledImage = styled(Img)`
  border-radius: ${({ theme }) => theme.radii['--corner-radius-8']};
  width: 100%;
  height: auto;
  position: relative;
  z-index: 1;
`

const AnchorContainer = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  a {
    margin: 0 auto;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      margin: 0;
    }
  }
`

const RightHotdog = styled(GrayHotdog)`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: block;
    position: absolute;
    width: 230px;
    height: 80px;
    left: -130px;
    transform: rotate(17deg);
    bottom: 50px;
  }
`
const LeftHotdog = styled(GrayHotdog)`
  display: block;
  position: absolute;
  top: -20px;
  right: -12px;
  transform: rotate(-23.75deg);
`

const Circle = styled(GrayCircle)`
  display: block;
  position: absolute;
  bottom: -34px;
  right: 40px;
`

const BlockTextLeftImageRightBanner = ({
  headerText,
  buttonText,
  buttonHref,
  image,
}) => (
  <Box
    as="section"
    flexAlign="middleCenter"
    backgroundColor={beigeBread50}
    paddingTop={['--spacing-03']}
    paddingBottom={['--spacing-06', '--spacing-03']}
  >
    <Grid gutter={false}>
      <Row xsReverse={true} distribute="between">
        <Col xs={0} lg={1}></Col>
        <Col xs={12} sm={6} lg={5}>
          <AnchorContainer flexAlign="middleCenter" marginBottom="--spacing-03">
            <RightHotdog />
            <Box height="100%" width="100%" flexAlign="middleCenter">
              <StyledImage fluid={image.fluid} alt={image.alt} />
            </Box>
          </AnchorContainer>
        </Col>
        <Col xs={0} lg={1}></Col>
        <Col xs={12} sm={5} lg={4}>
          <AnchorContainer flexAlign={'middleLeft'} marginBottom="--spacing-03">
            <Heading
              variant="--heading-2"
              marginY="--spacing-04"
              as="h2"
              textAlign={['center', 'left']}
              paddingLeft={['--spacing-06', 0]}
              paddingRight={['--spacing-06', '--spacing-03']}
              marginTop={['--spacing-04', 0]}
            >
              {headerText}
            </Heading>
            <PrimaryButton
              rightIcon="rightArrow"
              buttonSize="--large-button"
              buttonType={Link}
              to={buttonHref}
              isFullWidthOnMobile={false}
            >
              {buttonText}
            </PrimaryButton>
          </AnchorContainer>
        </Col>
        <Col xs={0} lg={1}>
          <AnchorContainer>
            <LeftHotdog />
            <Circle />
          </AnchorContainer>
        </Col>
      </Row>
    </Grid>
  </Box>
)

export default BlockTextLeftImageRightBanner

BlockTextLeftImageRightBanner.defaultProps = {
  headerText: '',
  buttonText: '',
  buttonHref: '',
  image: imageDefaultProps,
}

BlockTextLeftImageRightBanner.propTypes = {
  headerText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonHref: PropTypes.string,
  image: imageShape,
}
