import React from 'react'
import Grid from '@pbds/grid'
import MerchantCard from '@pbds/merchant-card'
import LoadingMerchants from './LoadingMerchants'
import NoResults from './NoResults'
import { MerchantsContainer, MerchantCardWrapper } from '../styles'
import gtmEvent from '../../../shared/gtmEvent'
import detectInAppBrowser from '../../../shared/detectInAppBrowser'

const { Row, Col } = Grid

const isInAppBrowser =
  typeof window !== 'undefined'
    ? detectInAppBrowser(
        window.navigator.userAgent || window.navigator.vendor || window.opera
      )
    : false

const handleClickGtmEvent = (merchantName, merchantLink, merchantCategory) => {
  gtmEvent({
    event: 'shop-directory-clicks',
    label: merchantCategory,
    action: merchantName,
    category: 'merchant directory',
    value: merchantLink,
  })
}

const generateUrl = ({
  trackingUrl,
  lang,
  websiteEn,
  websiteFr,
  dataxuPixelId,
}) => {
  let dataxuPixelUrl = ''
  if (dataxuPixelId) {
    // this is the pixel tracking code for
    dataxuPixelUrl = `https://i.w55c.net/rs?id=${dataxuPixelId}&type=marketing&rurl=`
  }
  if (trackingUrl) {
    return dataxuPixelUrl + trackingUrl
  }
  if (lang === 'en') {
    return dataxuPixelUrl + websiteEn
  }
  if (lang === 'fr' && websiteFr) {
    return dataxuPixelUrl + websiteFr
  }
  return dataxuPixelUrl + websiteEn
}

const assignCategory = ({ category, selectedCategory, categoriesOptions }) => {
  if (category.includes('Home & Furniture Beauty & Wellness')) {
    return categoriesOptions.find(cat => cat.value === selectedCategory).text
  }
  let categoryValue = category
  if (category.includes(' & ')) {
    categoryValue = category.replace(' & ', '-and-')
  }
  return categoriesOptions.find(cat => cat.value === categoryValue).text
}
const MerchantSection = ({
  categoriesOptions,
  selectedCategory,
  cardCtaText,
  merchants,
  hasNoResults,
  searchTerm,
  searchErrorMessage,
  tipText,
  searchErrorWithFilter,
  filterByPromotion,
  page,
  isLoading,
  lang,
}) => {
  return (
    <MerchantsContainer>
      <Row>
        {hasNoResults ? (
          <NoResults
            filterByPromotion={filterByPromotion}
            searchErrorWithFilter={searchErrorWithFilter}
            tipText={tipText}
            searchErrorMessage={searchErrorMessage}
            searchTerm={searchTerm}
          />
        ) : page === 1 && isLoading ? (
          <LoadingMerchants />
        ) : (
          merchants.map(
            ({
              card,
              logo,
              name,
              category,
              trackingUrl,
              websiteEn,
              websiteFr,
              dataxuPixelId,
            }) => {
              // watch for Ebay which is exceptional case
              const merchantCategory = assignCategory({
                category,
                selectedCategory,
                categoriesOptions,
              })

              const merchantLink = generateUrl({
                trackingUrl,
                lang,
                websiteEn,
                websiteFr,
                dataxuPixelId,
              })
              return (
                <Col key={name} xs={6} md={3}>
                  <MerchantCardWrapper
                    tabindex="0"
                    role="button"
                    onKeyDown={() =>
                      handleClickGtmEvent(name, merchantLink, merchantCategory)
                    }
                    onClick={() =>
                      handleClickGtmEvent(name, merchantLink, merchantCategory)
                    }
                  >
                    <MerchantCard
                      backgroundImageUrl={card.url}
                      merchantLogoImageUrl={logo && logo.url}
                      ctaText={cardCtaText}
                      mainText={name}
                      category={merchantCategory}
                      to={merchantLink}
                      variant="tall"
                      target={isInAppBrowser ? '_self' : '_blank'}
                    />
                  </MerchantCardWrapper>
                </Col>
              )
            }
          )
        )}
      </Row>
    </MerchantsContainer>
  )
}

export default MerchantSection
