import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'
import Grid from '@pbds/grid'
import Typography from '@pbds/typography'
import Box from '@pbds/box'
import Link from '@pbds/link'

import { imageShape, imageDefaultProps } from '../../shared/proptypes'

const { Row, Col } = Grid
const { Heading, Deck } = Typography

const BlockTextIsoImage = ({
  image,
  titleText,
  headerText,
  paragraphText,
  linkText,
  linkHref,
  isImageOnLeft,
  compactView,
  mobileImage,
  shrinkContent
}) => {
  const [alt, setAlt] = useState('')
  const breakpoint = mobileImage ? 992 : 0
  const sources = [
    {
      ...image.fluid,
      media: `(min-width: ${breakpoint.toString()}px)`,
    },
  ]
  mobileImage && sources.push(mobileImage.fluid)

  const resolveAltText = () =>
    typeof window === 'undefined' ||
    setAlt(window.innerWidth < breakpoint ? mobileImage.alt : image.alt)

  const StyledImg = styled(Img)(({ theme }) =>
    compactView
      ? `
    @media screen and (min-width: ${theme.breakpoints.lg}) {
      ${
        isImageOnLeft ? 'margin-left' : 'margin-right'
      }: clamp(0px, 6.5vw, 51px);
     }
  `
      : null
  )

  const paddingBottom = compactView
    ? ['--spacing-04', '--spacing-05', '--spacing-06', 0]
    : ['--spacing-09', '--spacing-09', '--spacing-09', 0]

  const deckPaddingRight = shrinkContent ? '--spacing-07' : '--spacing-09'


  return (
    <Grid>
      <Row lgReverse={!isImageOnLeft}>
        <Col xs={12} mdOffset={2} md={8} lgOffset={0} lg={shrinkContent ? 5 : 6}>
          <Box
            backgroundColor="white"
            height="100%"
            paddingX={['--spacing-04', 0]}
            paddingBottom={['--spacing-03', '--spacing-03', '--spacing-03', 0]}
          >
            <StyledImg fluid={sources} alt={alt} onLoad={resolveAltText} />
          </Box>
        </Col>
        <Col xs={12} mdOffset={2} md={8} lgOffset={0} lg={6}>
          <Box
            backgroundColor="white"
            height="100%"
            flexAlign={[
              'middleCenter',
              'middleCenter',
              'middleCenter',
              'middleLeft',
            ]}
            paddingX={[0, 0, 0, compactView ? '--spacing-07' : '--spacing-09']}
            paddingBottom={paddingBottom}
          >
            {titleText && (
              <Heading
                variant="--heading-3"
                marginBottom="--spacing-03"
                textAlign={['center', 'center', 'center', 'left']}
              >
                {titleText}
              </Heading>
            )}
            <Heading
              variant="--heading-2"
              marginBottom="--spacing-03"
              textAlign={['center', 'center', 'center', 'left']}
              paddingRight={[0, 0, 0, shrinkContent ? '--spacing-09' : 0]}
            >
              {headerText}
            </Heading>
            <Deck
              marginBottom="--spacing-03"
              paddingRight={[0, 0, 0, compactView ? deckPaddingRight : 0]}
              textAlign={['center', 'center', 'center', 'left']}
            >
              {paragraphText}
            </Deck>
            {linkText && (
              <Link
                variant="primary-right"
                to={linkHref}
                textAlign={['center', 'center', 'center', 'left']}
              >
                {linkText}
              </Link>
            )}
          </Box>
        </Col>
      </Row>
    </Grid>
  )
}

BlockTextIsoImage.propTypes = {
  image: imageShape,
  mobileImage: imageShape,
  titleText: PropTypes.string,
  headerText: PropTypes.string,
  paragraphText: PropTypes.string,
  linkText: PropTypes.string,
  linkHref: PropTypes.string,
  isImageOnLeft: PropTypes.bool,
  compactView: PropTypes.bool,
  shrinkContent: PropTypes.bool,
}

BlockTextIsoImage.defaultProps = {
  image: imageDefaultProps,
  mobileImage: imageDefaultProps,
  titleText: '',
  headerText: '',
  paragraphText: '',
  linkText: '',
  linkHref: '',
  isImageOnLeft: false,
  compactView: false,
  shrinkContent: false,
}

export default BlockTextIsoImage
