import styled from 'styled-components'
import Box from '@pbds/box'
import WHITE_BACKGROUND_MD from './backgrounds/white-hero-blobs-md.svg'
import GREEN_BACKGROUND_MD from './backgrounds/green-hero-blobs-md.svg'
import PURPLE_BACKGROUND_MD from './backgrounds/purple-hero-blobs-md.svg'
import { MAX_SCREEN_WIDTH } from '../../shared/constants'

const getBackground = color =>
  ({
    white: WHITE_BACKGROUND_MD,
    green: GREEN_BACKGROUND_MD,
    purple: PURPLE_BACKGROUND_MD,
    none: 'none',
  }[color] || WHITE_BACKGROUND_MD)

export const SectionBoxStyled = styled(Box)(
  ({ theme, blobColor, backgroundColors }) => {
    const isGradient = backgroundColors[1]
    const backgroundXs = `linear-gradient(${backgroundColors[0]}, ${
      backgroundColors[isGradient ? 1 : 0]
    })`
    const background = `linear-gradient(to left, ${backgroundColors[0]}, ${
      backgroundColors[isGradient ? 1 : 0]
    })`
    return `
    position: relative;
    overflow: hidden;
    background-size: cover;
    &:before {
        content:"";
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom: 0;
        width:100%;
        height:100%;
        transform: rotateY(180deg);
        background-image: ${backgroundXs}, url(${getBackground(blobColor)});
        background-size: 100%, cover;
        background-position: center;
    }
    @media screen and (min-width: ${theme.breakpoints.md}) {
      background-image:${background}, url(${getBackground(blobColor)});
      background-position: center;
      &:before {
          content:none;
      }
    }
    > div:first-of-type {
      position: relative;
      z-index: 1; //Show content above plants
    }
  `
  }
)

// Holder box for the background images.
export const ImagesHolderStyled = styled(Box)(
  ({
    theme,
    xsLeftImg,
    xsRightImg,
    mdLeftImg,
    mdRightImg,
    xlTopImg,
    xlBottomImg,
  }) => {
    const isSingleImageXs = xsLeftImg && !xsRightImg
    const isSingleImageMd = mdLeftImg && !mdRightImg
    const isSingleImageXl = xlTopImg && !xlBottomImg
    const getBGPosition = isSingleImage =>
      isSingleImage ? 'center' : 'left, right'
    return `
    position: absolute;
    width: 100%;
    margin: 0 auto;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-size: auto 100%;
    background-position: ${getBGPosition(isSingleImageXs)};
    background-repeat: no-repeat;
    background-image: url(${xsLeftImg}), url(${xsRightImg});
    @media screen and (min-width: ${theme.breakpoints.md}) {
      background-image: url(${mdLeftImg}), url(${mdRightImg});
      background-position: ${getBGPosition(isSingleImageMd)};
    }
    @media screen and (min-width: ${theme.breakpoints.xl}) {
      width: ${MAX_SCREEN_WIDTH};
      background-size: 100% auto;
      background-position: ${isSingleImageXl ? 'center' : 'top, bottom'};
      background-image: url(${xlTopImg}), url(${xlBottomImg});
    }
  `
  }
)
