import React from 'react'

const CanadianFlag = () => {
  return (
    <svg
      width="29"
      height="17"
      viewBox="0 0 29 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="web--svg_canada_flag_title"
    >
      <title id="web--svg_canada_flag_title">Canada</title>
      <path
        d="M6.41908 0.631409V16.6314H1.44333C1.05653 16.6314 0.742188 16.3254 0.742188 15.9488V1.31405C0.742188 0.93746 1.05653 0.631409 1.44333 0.631409H6.41908ZM28.041 0.631409H23.0653V16.6314H28.041C28.4278 16.6314 28.7422 16.3254 28.7422 15.9488V1.31405C28.7422 0.93746 28.4278 0.631409 28.041 0.631409ZM18.0825 11.8404L20.8812 9.6275C21.0145 9.52169 20.9888 9.31576 20.8333 9.24522L20.5307 9.10641C20.2491 9.00857 20.3285 8.85497 20.429 8.48407L20.7983 7.17681C20.845 7.01298 20.6966 6.85824 20.5248 6.89351L19.1062 7.18364C18.9309 7.20981 18.8141 7.10172 18.7872 6.99819L18.6738 6.61022C18.6236 6.43842 18.3969 6.38722 18.2753 6.52147L17.1395 7.75933C16.9373 8.00622 16.5295 8.00622 16.6569 7.43508L17.1734 4.76253C17.2096 4.57594 17.0098 4.43145 16.8357 4.51792L16.4489 4.71247C16.221 4.83649 15.992 4.85696 15.8646 4.63511L14.9473 2.9706C14.8596 2.81018 14.6236 2.81018 14.5348 2.9706L13.6186 4.63511C13.4912 4.85696 13.2634 4.83649 13.0343 4.71247L12.6475 4.51792C12.4746 4.43031 12.2748 4.57594 12.3098 4.76253L12.8263 7.43508C12.9537 8.00736 12.5447 8.00736 12.3437 7.75933L11.2079 6.52147C11.0852 6.38836 10.8596 6.43842 10.8094 6.61022L10.696 6.99819C10.6692 7.10172 10.5523 7.20981 10.377 7.18364L8.95837 6.89351C8.78659 6.85824 8.63819 7.01184 8.68493 7.17681L9.05419 8.48407C9.15469 8.85497 9.23415 9.00857 8.95253 9.10641L8.64987 9.24522C8.49445 9.31689 8.46874 9.52169 8.60196 9.6275L11.4007 11.8404C11.5234 11.9326 11.5841 12.0987 11.5409 12.2488L11.3761 12.7745C11.3271 12.9326 11.4614 13.0885 11.6309 13.0669L14.2181 12.7335C14.3618 12.7153 14.488 12.828 14.4822 12.969L14.4167 14.4276C14.4109 14.5573 14.5172 14.6654 14.6505 14.6654H14.8339C14.9648 14.6654 15.0711 14.5596 15.0676 14.4322L15.0302 12.9656C15.0267 12.8257 15.1518 12.7153 15.2943 12.7347L17.85 13.0669C18.0183 13.0885 18.1538 12.9338 18.1047 12.7745L17.94 12.25C17.8979 12.0998 17.9598 11.9337 18.0825 11.8404Z"
        fill="#4A4AF4"
      />
    </svg>
  )
}

export default CanadianFlag
