import React from 'react'
import PropTypes from 'prop-types'
import NavPrimary from '@pbds/nav-primary'
import { Link } from 'gatsby'

const NavGroup = ({ menuItems, handleClick, location }) => {
  const params = location && location.search
  return (
    <NavPrimary>
      <ul>
        {menuItems.map(item =>
          item.linkHref.startsWith('/') ? (
            <Link
              onClick={handleClick}
              key={item.linkText}
              to={item.linkHref + params}
            >
              {item.linkText}
            </Link>
          ) : (
            <a onClick={handleClick} target="blank" href={item.linkHref}>
              {item.linkText}
            </a>
          )
        )}
      </ul>
    </NavPrimary>
  )
}

NavGroup.defaultProps = {
  menuItems: [{ linkText: '', linkHref: '', isButton: false }],
}

NavGroup.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      linkText: PropTypes.string,
      linkHref: PropTypes.string,
      isButton: PropTypes.bool,
    })
  ),
}

export default NavGroup
