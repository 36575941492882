import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import { Close } from '@pbds/icons'
import { PrimaryButton } from '@pbds/buttons'
import styled, { keyframes, css } from 'styled-components'
import { ActionsContext } from '../../context'
import * as postMessage from '../../../../utils/messager'
import Modal from 'react-modal'
import { beginStyles, endStyles, baseStyles } from './modalStyles'
import PropTypes from 'prop-types'
import LeadFormCard from '../BlockLeadForm/LeadFormCard'
import HiddenIframe from '../BlockLeadForm/HiddenIframe'

const ModalContents = styled(`div`)(
  () => css`
    .close {
      border: none;
      background-color: transparent;
      position: absolute;
      right: 5px;
      top: 5px;
      padding: 0;
      cursor: pointer;
      &:focus {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        outline: none;
      }
    }
    .visually-hidden {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
  `
)

const fadeIn = keyframes`
  0% {
    opacity: 0;
    padding-bottom:50px;
    transform: scale(.95);
  }
  100%{
      opacity: 1;
      padding-bottom: 30px;
      transform: scale(1);
  }
`

const getAnimation = (keyframe, ms, delay) => css`
  ${keyframe} ${ms}ms  ${delay}ms forwards ease-out
`

const ModalSuccess = styled('div')(
  () => css`
    opacity: 0;
    padding: 30px 10px;
    animation: ${getAnimation(fadeIn, 1000, 0)};
  `
)

const BlockModalForm = ({
  heading: formFirstHeader,
  firstNamePlaceholderText: firstPlaceHolder,
  lastNamePlaceholderText: secondPlaceHolder,
  emailPlaceholderText: thirdPlaceHolder,
  companyPlaceholderText: fifthPlaceHolder,
  genericMissingErrorMessage,
  submitButtonText: buttonText,
  caslComplianceText,
  formName,
  location,
  downloadAssetLink,
  downloadButtonText,
}) => {
  const [formSuccess, setFormSuccess] = useState(false)
  const [{ content, overlay }, setDynamicStyles] = useState(beginStyles)
  const customStyles = { ...baseStyles }
  customStyles.content = { ...customStyles.content, ...content }
  customStyles.overlay = { ...customStyles.overlay, ...overlay }
  const { modalActive, setModalActive } = useContext(ActionsContext)
  const afterOpenModal = () => setDynamicStyles(endStyles)
  const requestClose = () => {
    setDynamicStyles(beginStyles)
    setModalActive(false)
    setFormSuccess(false)
  }

  const leadForm = {
    caslComplianceText,
    formName,
    formFirstHeader,
    firstPlaceHolder,
    secondPlaceHolder,
    thirdPlaceHolder,
    fifthPlaceHolder,
    genericMissingErrorMessage,
    buttonText,
  }

  const timer = useRef(null)

  const postLocationToParentFrame = useCallback(() => {
    timer.current = setTimeout(() => {
      postMessage.post({ location })
    }, 600)
  }, [location])

  useEffect(() => {
    postLocationToParentFrame()
    return function() {
      clearTimeout(timer.current)
    }
  }, [postLocationToParentFrame, timer])

  return (
    <Modal
      isOpen={modalActive}
      onAfterOpen={afterOpenModal}
      onRequestClose={requestClose}
      style={customStyles}
      contentLabel="Form Modal"
    >
      <HiddenIframe id="hiddenFrame" />
      {formSuccess ? (
        <ModalSuccess>
          {' '}
          <PrimaryButton
            buttonSize="--large-button"
            width="100%"
            href={downloadAssetLink}
            buttonType="a"
            fullWidthOnMobile={false}
            useInverseColors={true}
            download
            target="_blank"
            onClick={requestClose}
          >
            {downloadButtonText}
          </PrimaryButton>
        </ModalSuccess>
      ) : (
        <ModalContents>
          <div className="lead-form-card" style={{ position: 'relative' }}>
            <button className="close" onClick={requestClose}>
              <Close />
              <span className="visually-hidden">close modal</span>
            </button>
            <LeadFormCard
              className="lead-form-card"
              leadForm={leadForm}
              location={location}
              formType="short"
              setFormSuccess={setFormSuccess}
            />
          </div>
        </ModalContents>
      )}
    </Modal>
  )
}

BlockModalForm.defaultProps = {
  formName: 'salesforce',
  heading: '',
  firstNamePlaceholderText: '',
  lastNamePlaceholderText: '',
  emailPlaceholderText: '',
  companyPlaceholderText: '',
  genericMissingErrorMessage: '',
  submitButtonText: '',
  downloadAssetLink: '',
  downloadButtonText: '',
}

BlockModalForm.propTypes = {
  heading: PropTypes.string,
  firstNamePlaceholderText: PropTypes.string,
  lastNamePlaceholderText: PropTypes.string,
  emailPlaceholderText: PropTypes.string,
  companyPlaceholderText: PropTypes.string,
  genericMissingErrorMessage: PropTypes.string,
  submitButtonText: PropTypes.string,
  location: PropTypes.object,
  downloadAssetLink: PropTypes.string,
  downloadButtonText: PropTypes.string,
}

export default BlockModalForm
