export const FLOAT_ELEMS = [2, 5, 7] // Elements that have floating effect
export const ROTATE_ELEMS = [2, 7] // ELement that have rotate effect.
export const TIMING_RULES = {
  2: {
    DURATION_S: 4.5,
    DELAY_S: 2,
  },
  5: {
    DURATION_S: 3.5,
    DELAY_S: 0,
  },
  7: {
    DURATION_S: 4.5,
    DELAY_S: 1,
  },
}
export const IMAGE_DELAY_MAP = [7, 8, 5, 3, 1, 0, 2, 4, 6]
