import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image/withIEPolyfill'
import Box from '@pbds/box'
import Typography from '@pbds/typography'
import { imageShape, imageDefaultProps } from '../../shared/proptypes'

const textStyles = {
  textAlign: 'center',
  marginBottom: '--spacing-02',
}
const ElementTextImage = ({
  image,
  headerText,
  boldHeading,
  paragraphText,
  imageWidth,
}) => {
  return (
    <Box>
      <Box margin="0 auto">
        <Box
          paddingBottom="--spacing-03"
          maxWidth={imageWidth}
          flexAlign="middle"
          margin="0 auto"
        >
          <Img fluid={image.fluid} alt={image.alt} />
        </Box>
        {boldHeading ? (
          <Typography.Body variant="--body-01-emphasized" {...textStyles}>
            {headerText}
          </Typography.Body>
        ) : (
          <Typography.Heading
            variant="--heading-4"
            my="--spacing-03"
            as="h4"
            {...textStyles}
          >
            {headerText}
          </Typography.Heading>
        )}

        <Typography.Body as="div" variant="--body-01-regular" {...textStyles}>
          {paragraphText}
        </Typography.Body>
      </Box>
    </Box>
  )
}

export const elementTextImagePropTypes = {
  image: imageShape,
  headerText: PropTypes.string,
  paragraphText: PropTypes.string,
  boldHeading: PropTypes.bool,
  imageWidth: PropTypes.string,
}

ElementTextImage.propTypes = elementTextImagePropTypes

export const elementTextImageDefaultProps = {
  image: imageDefaultProps,
  headerText: '',
  paragraphText: '',
  boldHeading: false,
  imageWidth: '',
}

ElementTextImage.defaultProps = elementTextImageDefaultProps
export default ElementTextImage
