import { useState } from "react";

export const useInput = (initialValue, validator) => {
  const [value, setValue] = useState(initialValue);
  const [error, setHasError] = useState(false);
  
  const onChange = event => {
    setValue(event.target.value)
    setHasError(validator(event.target.value))
  }

  return {
    value,
    setValue,
    error,
    reset: () => setValue(""),
    bindInput: {
      value,
      onChange,
      error
    }
  }
}