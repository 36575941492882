import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Box from '@pbds/box'
import Grid from '@pbds/grid'
import Typography from '@pbds/typography'
import { PrimaryButton, SecondaryButton } from '@pbds/buttons'
import Background from './assets/background.svg'
import BackgroundMobile from './assets/background-mobile.svg'

const { Heading, Deck } = Typography
const { Row, Col } = Grid

const WrapperBox = styled(Box)`
  position: relative;
`

const BackgroundElement = styled(Box)(
  ({ theme }) => `
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${theme.colors['--background-03']};

  z-index: -1;

  div {
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
)

const BlockNotFound = ({
  topText,
  headerText,
  subHeading,
  firstButtonHref,
  firstButtonText,
  secondButtonText,
  secondButtonHref,
}) => {
  const isFirstButtonInternalLink = firstButtonHref.startsWith('/')
  const isSecondButtonInternalLink = secondButtonHref.startsWith('/')

  return (
    <WrapperBox
      as="section"
      flexAlign="topCenter"
      height={['auto', 'auto', '60vh', '90vh']}
      paddingY={['--spacing-08', '--spacing-08', '--spacing-09']}
      paddingX="--spacing-04"
    >
      <BackgroundElement>
        <Grid margin={false} gutter={false} limitWidth={false}>
          <Row>
            <Col xs={12} sm={0}>
              <img src={BackgroundMobile} alt="" />
            </Col>
            <Col xs={0} sm={12}>
              <img src={Background} alt="" />
            </Col>
          </Row>
        </Grid>
      </BackgroundElement>

      <Heading variant="--heading-3" color="white" marginBottom="--spacing-03">
        {topText}
      </Heading>
      <Heading
        variant="--heading-1"
        color="white"
        textAlign="center"
        marginBottom="--spacing-04"
      >
        {headerText}
      </Heading>
      <Deck color="white" textAlign="center" marginBottom="--spacing-04">
        {subHeading}
      </Deck>

      <Box
        flexDirection={['column', 'row']}
        flexAlign="middleCenter"
        width="100%"
      >
        <Box marginTop="--spacing-03" marginX="--spacing-03">
          <PrimaryButton
            to={isFirstButtonInternalLink ? firstButtonHref : null}
            href={isFirstButtonInternalLink ? null : firstButtonHref}
            buttonType={isFirstButtonInternalLink ? Link : 'a'}
            target={isFirstButtonInternalLink ? '_self' : '_blank'}
            buttonSize={'--large-button'}
            useInverseColors={true}
          >
            {firstButtonText}
          </PrimaryButton>
        </Box>
        {secondButtonText && (
          <Box
            marginTop={['--spacing-04', '--spacing-03']}
            marginX="--spacing-03"
          >
            <SecondaryButton
              to={isSecondButtonInternalLink ? secondButtonHref : null}
              href={isSecondButtonInternalLink ? null : secondButtonHref}
              buttonType={isSecondButtonInternalLink ? Link : 'a'}
              target={isSecondButtonInternalLink ? '_self' : '_blank'}
              buttonSize={'--large-button'}
              useInverseColors={true}
            >
              {secondButtonText}
            </SecondaryButton>
          </Box>
        )}
      </Box>
    </WrapperBox>
  )
}

export default BlockNotFound

BlockNotFound.propTypes = {
  topText: PropTypes.string,
  headerText: PropTypes.string,
  subHeading: PropTypes.string,
  firstButtonHref: PropTypes.string,
  firstButtonText: PropTypes.string,
  secondButtonText: PropTypes.string,
  secondButtonHref: PropTypes.string,
}

BlockNotFound.defaultProps = {
  topText: '',
  headerText: '',
  subHeading: '',
  firstButtonHref: '',
  firstButtonText: '',
  secondButtonText: '',
  secondButtonHref: '',
}
