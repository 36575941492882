import React from 'react'
import PropTypes from 'prop-types'
import Carousel from 'react-multi-carousel'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Grid from '@pbds/grid'
import Box from '@pbds/box'
import Typography from '@pbds/typography'
import MerchantCard from '@pbds/merchant-card'
import { PrimaryButton } from '@pbds/buttons'
import theme from '@pbds/theme'

import { SWIPING_CAROUSEL_CONFIG } from '../../shared/carousel_config'
import { CarouselStyleBox } from '../../elements/ElementCarouselRegular/ComponentsStyled'
import detectInAppBrowser from '../../shared/detectInAppBrowser'

const isInAppBrowser = typeof window !== 'undefined' ?
  detectInAppBrowser(window.navigator.userAgent || window.navigator.vendor || window.opera) :
  false;

const { Row, Col } = Grid
const { Heading, Deck } = Typography

const MIN_CARDS_FOR_CAROUSEL = 5

const lg = parseInt(theme.breakpoints.lg)
const md = parseInt(theme.breakpoints.md)
const sm = parseInt(theme.breakpoints.sm)

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: lg,
    },
    items: 3,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: {
      max: md,
      min: sm,
    },
    items: 2,
    arrows: false,
    partialVisibilityGutter: 70,
  },
  mobile: {
    breakpoint: {
      max: sm,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 80,
    arrows: false,
  },
}

// CAROUSEL

const renderMerchantCardCarouselBox = ({
  image,
  title,
  category,
  link,
  logoImage,
  imageCtaText,
}) => (
  <Box
    marginBottom={['--spacing-02']}
    paddingBottom={['--spacing-03', '--spacing-03', 0]}
    paddingRight={[
      '--spacing-03',
      '--spacing-03',
      '--spacing-04',
      '--spacing-05',
    ]}
    key={title}
  >
    <MerchantCard
      backgroundImageUrl={image.url}
      merchantLogoImageUrl={logoImage.url}
      mainText={title}
      ctaText={imageCtaText}
      category={category}
      to={link}
      target={isInAppBrowser ? '_self' : '_blank'}
    />
  </Box>
)

const ModifiedCarouselStyleBox = styled(CarouselStyleBox)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    .react-multi-carousel-track {
      padding-left: 1.75rem;
    }

    .react-multi-carousel-list {
      &::before,
      &::after {
        content: '';
        height: 100%;
        width: 1.5rem;
        background-color: white;
        display: block;
        position: absolute;
        top: 0;
        z-index: 1;
      }
      &::before {
        left: -1px;
      }
      &::after {
        right: -1px;
      }
    }
  }
`

const renderCarousel = merchants => (
  <ModifiedCarouselStyleBox
    padding={['--spacing-03', '--spacing-03', '--spacing-03', 0]}
  >
    <Carousel
      {...SWIPING_CAROUSEL_CONFIG}
      partialVisible
      responsive={responsive}
      slidesToSlide={1}
    >
      {merchants.map(renderMerchantCardCarouselBox)}
      {/* Extra div required for arrow spacing hack. */}
      <div />{' '}
    </Carousel>
  </ModifiedCarouselStyleBox>
)

// 4 CARDS

const renderMerchantCardCol = ({
  image,
  title,
  category,
  link,
  logoImage,
  imageCtaText,
}) => (
  <Col xs={6} md={3} key={title}>
    <Box
      height="100%"
      paddingBottom={['--spacing-03', '--spacing-03', 0]}
      paddingRight={[
        '--spacing-03',
        '--spacing-03',
        '--spacing-04',
        '--spacing-05',
      ]}
    >
      <MerchantCard
        backgroundImageUrl={image.url}
        merchantLogoImageUrl={logoImage.url}
        variant="tall"
        mainText={title}
        ctaText={imageCtaText}
        category={category}
        to={link}
        target={isInAppBrowser ? '_self' : '_blank'}
      />
    </Box>
  </Col>
)

const renderResponsiveGrid = merchants => (
  <Row>{merchants.map(renderMerchantCardCol)}</Row>
)

const BlockMerchantsLinksCarousel = ({
  headerText,
  paragraphText,
  imageCtaText,
  merchants,
  buttonText,
  buttonHref,
}) => {
  const merchantsWithCTA = merchants.map(merchant => ({
    imageCtaText,
    ...merchant,
  }))
  return (
    <Box as="section">
      <Grid gutter={false} margin={false}>
        <Row horizontalAlign="center">
          <Col md={12} xl={10}>
            <Box
              paddingLeft={[
                '--spacing-03',
                '--spacing-03',
                '--spacing-04',
                '--spacing-04',
                '--spacing-05',
              ]}
            >
              <Heading
                variant="--heading-2"
                paddingRight={[
                  '--spacing-03',
                  '--spacing-03',
                  '--spacing-04',
                  '--spacing-04',
                  '--spacing-05',
                ]}
              >
                {headerText}
              </Heading>
              <Deck
                variant="--deck-regular"
                marginTop="--spacing-02"
                paddingRight={[
                  '--spacing-03',
                  '--spacing-03',
                  '--spacing-04',
                  '--spacing-04',
                  '--spacing-05',
                ]}
              >
                {paragraphText}
              </Deck>
              <Box
                paddingTop={[
                  '--spacing-05',
                  '--spacing-05',
                  '--spacing-05',
                  '--spacing-05',
                  '--spacing-07',
                ]}
                paddingBottom={[
                  '--spacing-02',
                  '--spacing-02',
                  '--spacing-04',
                  '--spacing-04',
                  '--spacing-06',
                ]}
                marginBottom={
                  merchants.length < MIN_CARDS_FOR_CAROUSEL
                    ? ['--spacing-02']
                    : 0
                }
              >
                {merchants.length < MIN_CARDS_FOR_CAROUSEL &&
                  renderResponsiveGrid(merchantsWithCTA)}
                {merchants.length >= MIN_CARDS_FOR_CAROUSEL &&
                  renderCarousel(merchantsWithCTA)}
              </Box>
            </Box>
          </Col>
        </Row>
        <Grid>
          <Row horizontalAlign="center">
            {buttonText && buttonHref && (
              <Col xs={6}>
                <Box flexAlign="middleCenter">
                  <PrimaryButton
                    isFullWidthOnMobile={false}
                    buttonSize="--large-button"
                    to={buttonHref}
                    buttonType={Link}
                  >
                    {buttonText}
                  </PrimaryButton>
                </Box>
              </Col>
            )}
          </Row>
        </Grid>
      </Grid>
    </Box>
  )
}

BlockMerchantsLinksCarousel.propTypes = {
  headerText: PropTypes.string,
  paragraphText: PropTypes.string,
  imageCtaText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonHref: PropTypes.string,
  merchants: PropTypes.arrayOf(MerchantCard.propTypes),
}

BlockMerchantsLinksCarousel.defaultProps = {
  headerText: '',
  paragraphText: '',
  imageCtaText: '',
  buttonText: '',
  buttonHref: '',
  merchants: [MerchantCard.defaultProps],
}

export default BlockMerchantsLinksCarousel
