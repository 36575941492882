import React from 'react'

export const tapJoyUrl = () => (
  <script
    defer
    src={'https://www.tp88trk.com/scripts/sdk/everflow.js'}
  ></script>
)

export const tapJoyEvent = () => (
  <script>{`window.addEventListener('DOMContentLoaded', function(){
                            EF.click({
                                offer_id: EF.urlParameter('oid'),
                                offer_id: EF.urlParameter('oid'),
                                affiliate_id: EF.urlParameter('affid'),
                                sub1: EF.urlParameter('sub1'),
                                sub2: EF.urlParameter('sub2'),
                                sub3: EF.urlParameter('sub3'),
                                sub4: EF.urlParameter('sub4'),
                                sub5: EF.urlParameter('sub5'),
                                uid: EF.urlParameter('uid'),
                                transaction_id: EF.urlParameter('utm_term')
                            })
                        });
                       `}</script>
)

const renderTapJoy = () => [tapJoyUrl, tapJoyEvent].map(fn => fn())

export default renderTapJoy
