import styled from 'styled-components'
import Box from '@pbds/box'
import { getImageRules } from './imagePositionRules'

export const SectionStyled = styled(Box)`
  overflow: hidden;
  position: relative;

  // Keep buttons above images div
  > div:nth-of-type(2) {
    position: relative;
    z-index: 1;
  }
`

// Holder box for all the background images.
export const ImagesHolderStyled = styled(Box)(
  ({ theme }) => `
  position: absolute;
  width: 100%;
  max-width: ${theme.breakpoints.xl};
  margin: 0 auto;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`
)

function imagePositionAndScale(rules) {
  return `
    display: block;
    top: ${rules.top || 'auto'};
    bottom: ${rules.bottom || 'auto'};
    right: ${rules.right || 'auto'};
    left: ${rules.left || 'auto'};
    ${rules.scale ? `transform: scale(${rules.scale});` : ``};
  `
}

export const ImageHolderStyled = styled.div(({ theme, index }) => {
  const { xs, md, xl, width, height } = getImageRules(index)

  return `
    position: absolute;
    width: ${width};
    height: ${height};

    ${xs ? imagePositionAndScale(xs) : `display: none;`}

    @media screen and (min-width: ${theme.breakpoints.md}) {
      ${md ? imagePositionAndScale(md) : `display: none;`}
    }

    @media screen and (min-width: ${theme.breakpoints.xl}) {
      ${xl ? imagePositionAndScale(xl) : `display: none;`}
    }
  `
})

export const HiddenDivStyled = styled.div`
  opacity: 0;
`
export const AnimationHolderDivStyled = styled.div`
  position: relative;
  width: 100%;
`
export const HeadingAnimationHolderStyled = styled.div(
  ({ vertical }) => `
      width: 100%;
      position: absolute;
      top: 0;
      ${
        vertical
          ? `
            left: 50%;
            transform: translateX(-50%);`
          : ``
      }
`
)
