import React from 'react'
import styled from 'styled-components'
import Box from '@pbds/box'
import PBDSLink from '@pbds/link'
import { Link } from 'gatsby'
import Typography from '@pbds/typography'
import { Location } from '@reach/router'

import CanadianFlag from '../../../svgs/CanadianFlag'
const { Body } = Typography

const LANGS = ['en', 'fr']

const StyledBody = styled(Body)(
  ({ languageIsSeleted, theme }) => `
  color: ${languageIsSeleted && theme.colors['--ui-primary-hover']}
  text-decoration: ${languageIsSeleted && 'underline'};
`
)

const LanguageSelector = () => (
  <Location>
    {({ location }) => {
      const params = location && location.search
      const { pathname } = location
      const path = pathname.substring(4)
      const selectedLang = pathname.slice(1, 3)
      return (
        <Box flexAlign="middleRight" flexDirection="row">
          <Box flexAlign="middleCenter" flexDirection="row">
            {LANGS.map((lang, i) => {
              const isFirstItem = Boolean(!i)
              const MARGIN = '--spacing-01'
              return (
                <>
                  <Box
                    key={lang}
                    marginRight={isFirstItem && MARGIN}
                    marginLeft={!isFirstItem && MARGIN}
                  >
                    <PBDSLink
                      reactRouterLinkComponent={Link}
                      to={`/${lang}/${path}` + params}
                    >
                      <StyledBody
                        languageIsSeleted={lang === selectedLang}
                        variant={
                          selectedLang === lang && '--body-01-emphasized'
                        }
                      >
                        {lang.toUpperCase()}
                      </StyledBody>
                    </PBDSLink>
                  </Box>
                  {isFirstItem && (
                    <Body variant="--body-01-semi-emphasized">|</Body>
                  )}
                </>
              )
            })}
          </Box>
          <Box marginTop="2px" marginLeft="12px">
            <CanadianFlag />
          </Box>
        </Box>
      )
    }}
  </Location>
)

export default LanguageSelector
