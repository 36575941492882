import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@pbds/typography'
import Grid from '@pbds/grid'
import Box from '@pbds/box'

const { Row, Col } = Grid
const { Body } = Typography

const BlockSimpleParagraph = ({ text, hasExtendedBottomMargin }) => (
  <Box marginBottom={[hasExtendedBottomMargin ? 32 : 24]}>
    <Grid>
      <Row>
        <Col>
          <Body variant="--body-01-regular">{text}</Body>
        </Col>
      </Row>
    </Grid>
  </Box>
)

BlockSimpleParagraph.defaultProps = {
  text: '',
  hasExtendedBottomMargin: false,
}

BlockSimpleParagraph.propTypes = {
  text: PropTypes.string,
  hasExtendedBottomMargin: PropTypes.bool,
}

export default BlockSimpleParagraph
