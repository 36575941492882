import React from 'react'
import { ChevronUp } from '@pbds/icons'
import { GoToTopIcon, ChevronWrapper, GoToTopWrapper } from '../styles'

const GoToTop = () => (
  <GoToTopWrapper>
    <GoToTopIcon href="#top">
      <ChevronWrapper>
        <ChevronUp />
      </ChevronWrapper>
    </GoToTopIcon>
  </GoToTopWrapper>
)

export default GoToTop
