import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Box from '@pbds/box'
import Grid from '@pbds/grid'
import Typography from '@pbds/typography'
import { PrimaryButton } from '@pbds/buttons'
import DarkCtaMerchant from '../../svgs/DarkCtaMerchant'
import LightCtaMerchant from '../../svgs/LightCtaMerchant'
import { StyledImageContainer, StyledBox, StyledDiv } from './ComponentStyled'

const { Row, Col } = Grid

function renderButton(isColorSchemeInverted, buttonText, buttonURL) {
  return (
    <PrimaryButton
      rightIcon="rightArrow"
      buttonSize="--large-button"
      buttonType={Link}
      width="auto"
      isFullWidthOnMobile={true}
      useInverseColors={isColorSchemeInverted}
      to={buttonURL}
    >
      {buttonText}
    </PrimaryButton>
  )
}

function renderMobileButton(buttonText, buttonURL) {
  return (
    <PrimaryButton
      buttonSize="--large-button"
      buttonType={Link}
      width="auto"
      isFullWidthOnMobile={true}
      to={buttonURL}
    >
      {buttonText}
    </PrimaryButton>
  )
}

function setFontColor(isColorSchemeInverted, theme) {
  return isColorSchemeInverted
    ? theme.colors['--background-light']
    : theme.colors['--background-dark']
}

const BannerTextHeader = ({ bannerText, isColorSchemeInverted, theme }) => (
  <Typography.Heading
    variant="--heading-3"
    color={setFontColor(isColorSchemeInverted, theme)}
    textAlign="center"
  >
    {bannerText}
  </Typography.Heading>
)

const ElementMerchantCTABanner = ({
  isColorSchemeInverted,
  elementTitle,
  buttonTitle,
  buttonUrl,
}) => {
  const theme = useContext(ThemeContext)

  const mainButton = renderButton(isColorSchemeInverted, buttonTitle, buttonUrl)
  const mobileButton = renderMobileButton(buttonTitle, buttonUrl)
  const ctaMerchants = isColorSchemeInverted ? (
    <LightCtaMerchant />
  ) : (
    <DarkCtaMerchant />
  )

  return (
    <StyledDiv>
      <StyledBox
        isColorSchemeInverted={isColorSchemeInverted}
        flexAlign="middleCenter"
      >
        <Grid gutter={false} margin={false}>
          <Row>
            {/* Desktop view */}
            <Col xs={0} xl>
              <StyledImageContainer
                paddingBottom="--spacing-08"
                flexAlign="middleCenter"
              >
                {ctaMerchants}
              </StyledImageContainer>
              <Box paddingBottom="--spacing-05" flexAlign="middleCenter">
                <BannerTextHeader
                  bannerText={elementTitle}
                  isColorSchemeInverted={isColorSchemeInverted}
                  theme={theme}
                />
              </Box>
              <Box flexAlign="middleCenter">{mainButton}</Box>
            </Col>
            {/* Tablet view */}
            <Col xs={0} md xl={0}>
              <Box flexAlign="middleLeft" flexDirection="row">
                <Col xs={3}>
                  <BannerTextHeader
                    bannerText={elementTitle}
                    isColorSchemeInverted={isColorSchemeInverted}
                    theme={theme}
                  />
                </Col>
                <Col horizontalAlign="center">
                  <StyledImageContainer>{ctaMerchants}</StyledImageContainer>
                </Col>

                <Box flexAlign="middleCenter">{mainButton}</Box>
              </Box>
            </Col>
            {/* Mobile view */}
            <Col xs md={0}>
              {mobileButton}
            </Col>
          </Row>
        </Grid>
      </StyledBox>
    </StyledDiv>
  )
}

ElementMerchantCTABanner.propTypes = {
  isColorSchemeInverted: PropTypes.bool,
  elementTitle: PropTypes.string,
  buttonTitle: PropTypes.string,
  buttonUrl: PropTypes.string,
}

ElementMerchantCTABanner.defaultProps = {
  isColorSchemeInverted: false,
  elementTitle: '',
  buttonTitle: '',
  buttonUrl: '',
}

export default ElementMerchantCTABanner
