import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import Grid from '@pbds/grid'
import Card from '@pbds/card'
import Box from '@pbds/box'

import { purpleJelly10 } from '@pbds/core-colors'
import Typography from '@pbds/typography'
import { imageShape, imageDefaultProps } from '../../shared/proptypes'
import ElementMarqueeAndCarousel from '../../elements/ElementMarqueeAndCarousel'

const { Row, Col } = Grid
const { Heading, Body, Deck } = Typography

const ImgStyled = styled(Img)`
  min-height: 6.5rem;
  min-width: 6.5rem;
`

const ValueCard = ({ headerText, paragraphText, image }) => (
  <Card
    paddingX={['--spacing-03', '--spacing-03', '--spacing-05']}
    paddingY="--spacing-06"
    backgroundColor="white"
    marginX="--spacing-03"
    marginTop="--spacing-03"
    marginBottom={[
      '--spacing-06',
      '--spacing-06',
      '--spacing-06',
      '--spacing-06',
      '--spacing-03',
    ]}
  >
    <Grid margin={false} gutter={false}>
      <Row>
        <Col xs={12} sm={3}>
          <Box
            flexAlign={'middleCenter'}
            height="100%"
            paddingBottom={['--spacing-04', 0]}
          >
            <ImgStyled fixed={image.fluid} alt={image.alt} />
          </Box>
        </Col>
        <Col xs={12} sm={9}>
          <Box
            flexAlign={['middleCenter', 'middleLeft']}
            height="100%"
            paddingLeft="--spacing-05"
            paddingRight={['--spacing-04', 0]}
          >
            <Heading
              variant="--heading-4"
              marginBottom="--spacing-02"
              textAlign={['center', 'left']}
            >
              {headerText}
            </Heading>
            <Body textAlign={['center', 'left']}>{paragraphText}</Body>
          </Box>
        </Col>
      </Row>
    </Grid>
  </Card>
)

const BlockValuesCarousel = ({ slides, headerText, paragraphText }) => (
  <Box
    margin="0 auto"
    backgroundColor={purpleJelly10}
    paddingTop="--spacing-07"
    paddingBottom={[
      '--spacing-06',
      '--spacing-06',
      '--spacing-06',
      '--spacing-07',
    ]}
    as="section"
  >
    {/* Heading  */}
    <Grid>
      <Row>
        <Col smOffset={2} sm={8} lgOffset={4} lg={4} horizontalAlign="center">
          <Heading
            variant="--heading-2"
            textAlign="center"
            marginBottom="--spacing-03"
            marginTop="--spacing-04"
          >
            {headerText}
          </Heading>

          <Deck variant="--deck-regular" marginBottom="--spacing-04">
            {paragraphText}
          </Deck>
        </Col>
      </Row>
    </Grid>

    <ElementMarqueeAndCarousel
      backgroundColor={purpleJelly10}
      marqueeBreakpoint="xl"
    >
      {slides.map(slide => (
        <ValueCard key={`${slide}`} {...slide}></ValueCard>
      ))}
    </ElementMarqueeAndCarousel>
  </Box>
)

BlockValuesCarousel.propTypes = {
  headerText: PropTypes.string,
  paragraphText: PropTypes.string,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      image: imageShape,
      headerText: PropTypes.string,
      paragraphText: PropTypes.string,
    })
  ),
}

BlockValuesCarousel.defaultProps = {
  headerText: '',
  paragraphText: '',
  slides: [
    {
      image: imageDefaultProps,
      headerText: '',
      paragraphText: '',
    },
  ],
}

export default BlockValuesCarousel
