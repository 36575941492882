import styled from 'styled-components'
import Typography from '@pbds/typography'
import Box from '@pbds/box'

const { Caption } = Typography

const NavWrapper = styled('div')(
  ({ theme }) => `
  box-shadow: ${theme.elevation['--elevation-regular-3']};
  @media screen and (max-width: ${theme.breakpoints.xl}) {
    display: none;
  }
  `
)

const FilterWrapper = styled(Box)(
  ({ theme, isSticky }) => `
  @media screen and (min-width: ${theme.breakpoints.md}) and (max-width: ${
    theme.breakpoints.xl
  }) {
    margin: ${theme.space['--spacing-03']} auto 0px; 
  }
  margin: ${theme.space['--spacing-03']} 0px 0px;
    p {
      display: inline-block;
    }

    ${isSticky &&
      `position: fixed;
       
       @media screen and (min-width: ${theme.breakpoints.md}) {
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
       }
       width: 94%;
       top: 60px;
       left: 15px;
       z-index: 1;
    `}
  `
)

const SearchWrapper = styled.div`
  margin-bottom: 26px;
`

const GoToTopIcon = styled('a')(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-content: center;
  width: 56px;
  height: 56px;
  background-color: white;
  box-shadow: ${theme.elevation['--elevation-regular-4']};
  border-radius: 50%;
  &:active {
    color: ${theme.colors['--border-confirmation']};
  }
  `
)

const CautionCircle = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
  align-content: center;
  background-color: #f2f2f2;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  align-items: center;
  padding-bottom: 5px;
`

const MerchantsContainer = styled('div')(
  ({ theme }) => `
    margin-top: ${theme.space['--spacing-03']};
  `
)

const StyledTopSection = styled(Box)(
  ({ theme }) => `
    position: relative;
    background-color: ${theme.colors['--background-02']};
    min-height: 400px;
    z-index: 0;
  `
)

const StyledSection = styled.section`
  margin-bottom: 90px;
`

const ChevronWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
`

const GoToTopWrapper = styled.div`
  position: fixed;
  right: 3%;
  bottom: 2%;
  z-index: 1;
`

const StyledNavItem = styled('a')(
  ({ theme, isSelected }) => `
    border-bottom-color: ${
      isSelected
        ? `${theme.colors['--border-hover']} !important`
        : 'transparent'
    };  
  `
)

const CautionWrapper = styled('div')(
  ({ theme }) => `
    margin-top: ${theme.space['--spacing-03']};
  `
)

const StyledCaption = styled(Caption)(
  ({ theme }) => `
    display: inline-block;
    color: ${theme.colors['--text-disabled']};
  `
)

const BackgroundElement = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: -1;

  div {
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const MerchantCardWrapper = styled('div')(
  ({ theme }) => `
  margin-bottom: ${theme.space['--spacing-05']};
`
)

const ShowMoreButtonWrapper = styled('div')(
  ({ theme }) => `
  margin-top: ${theme.space['--spacing-03']};
`
)

export {
  NavWrapper,
  FilterWrapper,
  SearchWrapper,
  GoToTopIcon,
  CautionCircle,
  MerchantsContainer,
  StyledTopSection,
  StyledSection,
  ChevronWrapper,
  GoToTopWrapper,
  StyledNavItem,
  CautionWrapper,
  StyledCaption,
  BackgroundElement,
  MerchantCardWrapper,
  ShowMoreButtonWrapper,
}
