const API_ROOT = {
  development: 'http://localhost:3000',
  staging:
    'https://jizk2jsv1k.execute-api.us-east-2.amazonaws.com/staging/merchants',
  production:
    'https://pjfxhi04tb.execute-api.us-east-2.amazonaws.com/production/merchants',
}

// this number is defined based on design and pagination
// right now the requirements is to have 12 cards
// our API also returns 12 items in each page, therefore they need to match
const LOADING_CARDS_NUMBER = 12

const FILTERING_KEYS = ['category', 'sortBy', 'filterByPromotion', 'search']

export { API_ROOT, LOADING_CARDS_NUMBER, FILTERING_KEYS }
