import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@pbds/typography'
import Grid from '@pbds/grid'
import Box from '@pbds/box'
import { beigeBread75 } from '@pbds/core-colors'
import { SectionBoxStyled, ImagesHolderStyled } from './ComponentsStyled'
import { ButtonWithAction } from '../../shared/ButtonWithAction'

const { Heading, Deck } = Typography
const { Row, Col } = Grid

const BlockHeroBanner = ({
  headerText,
  paragraphText,
  secondParagraphText,
  thirdParagraphText,
  hasWhiteText,
  hideDecoration,
  bannerBackground,
  bannerBackgroundSecondary,
  blobColor,
  firstButtonText,
  firstButtonHref,
  firstButtonAction,
  firstButtonIsPrimary,
  firstButtonHasArrow,
  firstButtonIsWhite,
  headingTextDecoration,
  hideHeadingText,
  centeredBannerImageDesktop,
  centeredBannerImageTablet,
  centeredBannerImageMobile,
  secondButtonIsWhite,
  secondButtonText,
  secondButtonHref,
  secondButtonIsPrimary,
  secondButtonHasArrow,
  xsLeftImg,
  xsRightImg,
  mdLeftImg,
  mdRightImg,
  topPinnedDecoration,
  wideBannerText,
  xlTopImg,
  xlBottomImg,
  location,
}) => {
  const backgroundColor = bannerBackground ? bannerBackground.hex : beigeBread75
  const hasTopPinnedDecoration = Boolean(topPinnedDecoration?.url)
  const textColor = hasWhiteText ? 'white' : null
  const hasHeadingTextDecoration = headingTextDecoration?.url
  const hasCenteredBannerImage = centeredBannerImageDesktop?.url

  const minHeight = hasHeadingTextDecoration
    ? ['354px', '354px', '354px', '412px']
    : ['460px', '460px', '460px', '536px']

  const CenteredBannerImage = () => 
    hasCenteredBannerImage ? (
      <Box
        flexAlign="topCenter"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >    <Col xs={12} md={0}>
              <img src={centeredBannerImageMobile.url} alt={centeredBannerImageMobile.alt} style={{
                maxWidth: 'calc(100vw - 64px)',
                height: 'auto',
              }}/>
          </Col>
          <Col xs={0} md={12} xl={0}>
            <img src={centeredBannerImageTablet.url} alt={centeredBannerImageTablet.alt} style={{
                maxWidth: 'calc(100vw - 128px)',
                height: 'auto',
              }}/>
          </Col>
          <Col xl={12} xs={0} md={0} lg={0}>
            <img src={centeredBannerImageDesktop.url} alt={centeredBannerImageDesktop.alt} style={{
                maxWidth: 'calc(100vw - 128px)',
                height: 'auto',
              }}/>
          </Col>
      </Box>
    ) : (
      <>{null}</>
    )

  const VisuallyHiddenTextWrapper = ({ children }) =>
    hideHeadingText ? (
      <Box
        style={{
          position: 'absolute',
          width: '1px',
          height: '1px',
          padding: '0',
          margin: '-1px',
          overflow: 'hidden',
          clip: 'rect(0,0,0,0)',
          whiteSpace: 'nowrap',
          border: '0',
        }}
      >
        {children}
      </Box>
    ) : (
      <>{children}</>
    )

  const HeadingWrapper = ({ children }) =>
    hasHeadingTextDecoration ? (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginBottom: '-64px',
        }}
      >
        <img
          alt={headingTextDecoration.alt}
          style={{
            width: '15vw',
            minWidth: '90px',
            maxWidth: '160px',
            height: 'auto',
            margin: '8px',
          }}
          src={headingTextDecoration.url}
        />
        {children}
      </Box>
    ) : (
      <>{children}</>
    )

  return (
    <SectionBoxStyled
      as="section"
      blobColor={blobColor}
      backgroundColors={[backgroundColor, bannerBackgroundSecondary?.hex]}
    >
      <Grid>
        <Row verticalAlign="middle">
          <Col
            xs={12}
            mdOffset={2}
            md={8}
            xlOffset={wideBannerText ? 2 : 3}
            xl={wideBannerText ? 8 : 6}
          >
            {hasTopPinnedDecoration && (
              <Box flexAlign="topCenter">
                <Box
                  width={
                    hideDecoration
                      ? ['80px', '90px', '0px', '0px']
                      : ['80px', '90px', '100px', '127px']
                  }
                  style={{
                    position: 'absolute',
                    borderColor: 'red',
                    borderWidth: '2px',
                  }}
                >
                  <img
                    alt={topPinnedDecoration.alt}
                    style={{ width: '100%', height: 'auto' }}
                    src={topPinnedDecoration.url}
                  />
                </Box>
              </Box>
            )}
            <Box flexAlign="middleCenter" minHeight={minHeight}>
              <Box flexAlign="middleCenter" paddingTop="--spacing-03">
                <Box
                  paddingTop={
                    hasTopPinnedDecoration
                      ? hideDecoration
                        ? [
                            '--spacing-08',
                            '--spacing-08',
                            '--spacing-05',
                            '--spacing-05',
                          ]
                        : [
                            '--spacing-08',
                            '--spacing-08',
                            '--spacing-08',
                            '--spacing-09',
                          ]
                      : '--spacing-05'
                  }
                >
                  <HeadingWrapper>
                    <CenteredBannerImage/>
                    <Heading
                      variant="--heading-1"
                      marginBottom="--spacing-02"
                      textAlign="center"
                      color={textColor}
                    >
                      <VisuallyHiddenTextWrapper>
                        {headerText}
                      </VisuallyHiddenTextWrapper>
                    </Heading>
                  </HeadingWrapper>
                </Box>
                {Object.entries({
                  paragraphText,
                  secondParagraphText,
                  thirdParagraphText,
                }).map(([key, text]) => (
                  (text && <Deck
                    key={key}
                    variant="--deck-regular"
                    textAlign="center"
                    marginTop="--spacing-04"
                    as="span"
                    color={textColor}
                  >
                    {text}
                  </Deck>)
                ))}
              </Box>
              <Box
                flexAlign="middleCenter"
                flexDirection={['column', 'row']}
                paddingX={['--spacing-08', '--spacing-07', '0']}
                paddingTop="--spacing-03"
                marginBottom="--spacing-05"
              >
                {firstButtonText && (
                  <ButtonWithAction
                    buttonTo={firstButtonHref}
                    isPrimary={firstButtonIsPrimary}
                    hasArrow={firstButtonHasArrow}
                    buttonAction={firstButtonAction}
                    useInverseColors={
                      textColor === 'white' || firstButtonIsWhite
                    }
                  >
                    {firstButtonText}
                  </ButtonWithAction>
                )}
                {secondButtonText && (
                  <ButtonWithAction
                    buttonTo={secondButtonHref}
                    isPrimary={secondButtonIsPrimary}
                    hasArrow={secondButtonHasArrow}
                    useInverseColors={
                      textColor === 'white' || secondButtonIsWhite
                    }
                    location={location}
                  >
                    {secondButtonText}
                  </ButtonWithAction>
                )}
              </Box>
            </Box>
          </Col>
        </Row>
      </Grid>

      <ImagesHolderStyled
        xsLeftImg={xsLeftImg?.url}
        xsRightImg={xsRightImg?.url}
        mdLeftImg={mdLeftImg?.url}
        mdRightImg={mdRightImg?.url}
        xlTopImg={xlTopImg?.url}
        xlBottomImg={xlBottomImg?.url}
      ></ImagesHolderStyled>
    </SectionBoxStyled>
  )
}

BlockHeroBanner.defaultProps = {
  bannerBackground: { hex: '' },
  bannerBackgroundSecondary: { hex: '' },
  headerText: '',
  hasWhiteText: false,
  hideDecoration: false,
  hideHeadingText: false,
  paragraphText: '',
  secondParagraphText: '',
  thirdParagraphText: '',
  firstButtonText: '',
  firstButtonAction: '',
  firstButtonHref: '',
  firstButtonIsPrimary: true,
  firstButtonHasArrow: true,
  firstButtonIsWhite: false,
  headingTextDecoration: {
    url: '',
    alt: '',
  },
  centeredBannerImageDesktop: {
    url: '',
    alt: '',
  },
  centeredBannerImageTablet: {
    url: '',
    alt: '',
  },
  centeredBannerImageMobile: {
    url: '',
    alt: '',
  },
  secondButtonIsWhite: false,
  secondButtonText: '',
  secondButtonHref: '',
  secondButtonIsPrimary: false,
  secondButtonHasArrow: false,
  topPinnedDecoration: {
    url: '',
    alt: '',
  },
  wideBannerText: false,
  xsLeftImg: {
    url: '',
  },
  xsRightImg: {
    url: '',
  },
  mdLeftImg: {
    url: '',
  },
  mdRightImg: {
    url: '',
  },
  xlTopImg: {
    url: '',
  },
  xlBottomImg: {
    url: '',
  },
}

BlockHeroBanner.propTypes = {
  bannerBackground: {
    hex: PropTypes.string,
  },
  bannerBackgroundSecondary: {
    hex: PropTypes.string,
  },
  headerText: PropTypes.string,
  hasWhiteText: PropTypes.bool,
  hideDecoration: PropTypes.bool,
  hideHeadingText: PropTypes.bool,
  paragraphText: PropTypes.string,
  secondParagraphText: PropTypes.string,
  thirdParagraphText: PropTypes.string,
  firstButtonText: PropTypes.string,
  firstButtonHref: PropTypes.string,
  firstButtonAction: PropTypes.string,
  firstButtonIsPrimary: PropTypes.bool,
  firstButtonHasArrow: PropTypes.bool,
  firstButtonIsWhite: PropTypes.bool,
  headingTextDecoration: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  centeredBannerImageDesktop: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  centeredBannerImageTablet: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  centeredBannerImageMobile: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  secondButtonIsWhite: PropTypes.bool,
  secondButtonText: PropTypes.string,
  secondButtonHref: PropTypes.string,
  secondButtonIsPrimary: PropTypes.bool,
  secondButtonHasArrow: PropTypes.bool,
  topPinnedDecoration: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  wideBannerText: PropTypes.bool,
  xsLeftImg: PropTypes.shape({
    url: PropTypes.string,
  }),
  xsRightImg: PropTypes.shape({
    url: PropTypes.string,
  }),
  mdLeftImg: PropTypes.shape({
    url: PropTypes.string,
  }),
  mdRigthImg: PropTypes.shape({
    url: PropTypes.string,
  }),
  xlTopImg: PropTypes.shape({
    url: PropTypes.string,
  }),
  xlBottomtImg: PropTypes.shape({
    url: PropTypes.string,
  }),
}

export default BlockHeroBanner
