import React, { useRef } from 'react'
import Grid from '@pbds/grid'
import Typography from '@pbds/typography'
import SearchInput from '@pbds/search-input'
import Select from '@pbds/select'
import Box from '@pbds/box'
import { StyledTopSection, SearchWrapper, BackgroundElement } from '../styles'
import DefaultBackgroundMobile from '../assets/background-xs.svg'
import DefaultBackgroundTablet from '../assets/background-m.png'
import DefaultBackground from '../assets/background-xl.png'

const { Row, Col } = Grid

const { Heading, Deck } = Typography

const TopSection = ({
  headerText,
  paragraphText,
  searchPlaceHolder,
  searchTerm,
  sortLabel,
  categoriesLabel,
  sortOptions,
  categoriesOptions,
  handleChangeSearchTerm,
  handleChangeSortBy,
  handleCategoryChange,
  selectedCategory,
  sortBy,
  isWindowReady,
  backgroundImage,
}) => {
  const searchRef = useRef(null)

  const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)
  const handleSearchFocus = () => {
    if (window && window.innerWidth < 600) {
      scrollToRef(searchRef)
    }
  }

  const {
    backgroundMobile = DefaultBackgroundMobile,
    backgroundTablet = DefaultBackgroundTablet,
    backgroundDesktop = DefaultBackground,
  } = backgroundImage

  return (
    <StyledTopSection flexAlign="middleCenter">
      <BackgroundElement>
        <Grid margin={false} gutter={false} limitWidth={false}>
          <Row>
            <Col xs={12} md={0}>
              <img src={backgroundMobile} alt="" />
            </Col>
            <Col md={12} xl={0}>
              <img src={backgroundTablet} alt="" />
            </Col>
            <Col xl={12}>
              <img src={backgroundDesktop} alt="" />
            </Col>
          </Row>
        </Grid>
      </BackgroundElement>
      <Grid>
        <Row verticalAlign="middle" horizontalAlign="center">
          <Col>
            <Heading
              paddingTop={[0, 0, 0, 0, 10]}
              paddingX={[0, 0, '120px', '240px', '260px']}
              marginBottom="--spacing-03"
              variant="--heading-1"
            >
              {headerText}
            </Heading>
            <Deck
              paddingX={[0, 0, '120px', '300px']}
              ref={searchRef}
              marginBottom="--spacing-05"
              variant="--deck-regular"
            >
              {paragraphText}
            </Deck>
            <Box
              maxWidth={['100%', '100%', '472px', '472px', '640px']}
              margin="auto"
            >
              {isWindowReady && (
                <Grid gutter={true} margin={false}>
                  <Row xlReverse>
                    <Col xs={12} xl={6}>
                      <SearchWrapper>
                        <SearchInput
                          onFocus={handleSearchFocus}
                          onChange={handleChangeSearchTerm}
                          label={searchPlaceHolder}
                          defaultValue={searchTerm}
                          name="search"
                          type="search"
                        />
                      </SearchWrapper>
                    </Col>
                    <Col xs={0} md>
                      <Select
                        label={sortLabel}
                        name="sort-by"
                        options={sortOptions}
                        value={sortBy}
                        onChange={handleChangeSortBy}
                      />
                    </Col>
                    <Col xs={6} md={0}>
                      <Select
                        native
                        label={sortLabel}
                        name="sort-by"
                        options={sortOptions}
                        value={sortBy}
                        onChange={handleChangeSortBy}
                      />
                    </Col>
                    <Col xs={0} md={6} xl={0}>
                      <Select
                        label={categoriesLabel}
                        name="categories"
                        options={categoriesOptions}
                        value={selectedCategory}
                        onChange={e => handleCategoryChange(e.target.value)}
                      />
                    </Col>
                    <Col xs={6} md={0}>
                      <Select
                        native
                        label={categoriesLabel}
                        name="categories"
                        options={categoriesOptions}
                        value={selectedCategory}
                        onChange={e => handleCategoryChange(e.target.value)}
                      />
                    </Col>
                  </Row>
                </Grid>
              )}
            </Box>
          </Col>
        </Row>
      </Grid>
    </StyledTopSection>
  )
}

export default TopSection
