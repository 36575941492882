import React from 'react'
import PropTypes from 'prop-types'
import Box from '@pbds/box'
import Grid from '@pbds/grid'
import { PrimaryButton } from '@pbds/buttons'
import Typography from '@pbds/typography'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { beigeBread, beigeBread25 } from '@pbds/core-colors'
import TabletV5 from './Images/tabletv5-shapes.svg'
import DesktopV5 from './Images/desktopv5-shapes.svg'
import TabletV5Left from './Images/v5-tablet-left.png'
import TabletV5Right from './Images/v5-tablet-right.png'
import DesktopV5Left from './Images/v5-desktop-left.png'
import DesktopV5Right from './Images/v5-desktop-right.png'
import TabletV6 from './Images/tablet-v6-shapes.svg'
import DesktopV6 from './Images/desktop-v6-shapes.svg'
import TabletV6Left from './Images/tablet-v6-left.png'
import TabletV6Right from './Images/tablet-v6-right.png'
import DesktopV6Left from './Images/desktop-v6-left.png'
import DesktopV6Right from './Images/desktop-v6-right.png'

const { Row, Col } = Grid
const { Heading } = Typography

const StyledSectionBox = styled(Box)(
  ({ theme, isFullHeight }) => `
      position: relative;
      overflow: hidden;
      background-color: ${beigeBread25};
      @media screen and (min-width: ${theme.breakpoints.md}) {
        background-image: ${
          isFullHeight
            ? `url(${TabletV6});`
            : `url(${TabletV5}), linear-gradient(${beigeBread}, ${beigeBread});`
        }
        background-position: ${isFullHeight ? 'bottom' : 'center 400px'};
        background-size: ${isFullHeight ? 'cover' : 'contain'};
      }
      @media screen and (min-width: ${theme.breakpoints.xl}) {
        background-image: ${
          isFullHeight
            ? `url(${DesktopV6});`
            : `url(${DesktopV5}), linear-gradient(${beigeBread}, ${beigeBread});`
        }
        background-size: cover; //Need to set v5 to cover in order to get the 'space' of the shapes
      }
      > div:first-of-type {
        position: relative;
        z-index: 1; //Show content above background objects
      }
    `
)

export const ImagesHolderStyled = styled(Box)(
  ({ theme, isFullHeight }) => `
      position: absolute;
      width: 100%;
      margin: 0 auto;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
  
      @media screen and (min-width: ${theme.breakpoints.md}) {
        background-image: url(${isFullHeight ? TabletV6Left : TabletV5Left}), 
          url(${isFullHeight ? TabletV6Right : TabletV5Right});
          
        background-position: left bottom, right bottom;
      }
    
      @media screen and (min-width: ${theme.breakpoints.xl}) {
        background-image: url(${isFullHeight ? DesktopV6Left : DesktopV5Left}), 
          url(${isFullHeight ? DesktopV6Right : DesktopV5Right});
        
      }
    `
)

const StyledChildrenContainer = styled(Box)(
  ({ theme }) => `
    display:flex;
    width:100%;
    justify-content: center;
    flex-direction: column;
    >*:nth-of-type(2){
        margin: 28px 0 0 0;
    }
    @media screen and (min-width: ${theme.breakpoints.xl}) {
        margin-bottom:0;
        flex-direction: row;
        >*:nth-of-type(2){
            margin:0 0 0 32px;
        }
    }
    `
)

const ElementBackgroundObjectsBanner = ({
  isFullHeight,
  withElementVideo,
  buttonText,
  buttonHref,
  bannerText,
  children,
}) => (
  <StyledSectionBox
    flexAlign="middleCenter"
    paddingTop={[0, '--spacing-07']}
    isFullHeight={isFullHeight}
  >
    <Grid>
      <Row
        horizontalAlign="center"
        verticalAlign="middle"
        margin={false}
        gutter={false}
      >
        <Col md={12} lg={10}>
          <Box flexAlign="middleCenter">
            {children && (
              <StyledChildrenContainer
                className={
                  children.filter(child => child).length > 1
                    ? 'has-siblings'
                    : null
                }
              >
                {children}
              </StyledChildrenContainer>
            )}
          </Box>
        </Col>
      </Row>
      <Row
        horizontalAlign="center"
        verticalAlign="middle"
        margin={false}
        gutter={false}
      >
        <Col xs={0} sm={12} md={12} lg={10}>
          <Box flexAlign="middleCenter">
            <Box
              flexAlign="middleCenter"
              paddingTop="--spacing-08"
              width="300px"
            >
              <Heading
                variant="--heading-2"
                marginTop="--spacing-04"
                marginBottom="--spacing-03"
                textAlign="center"
              >
                {bannerText}
              </Heading>
            </Box>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Box
            marginTop={['--spacing-05', 0]}
            marginBottom={['--spacing-05', '--spacing-09']}
            flexAlign="middleCenter"
          >
            <PrimaryButton
              buttonSize="--large-button"
              buttonType={Link}
              to={buttonHref}
            >
              {buttonText}
            </PrimaryButton>
          </Box>
        </Col>
      </Row>
    </Grid>
    <ImagesHolderStyled isFullHeight={isFullHeight} />
  </StyledSectionBox>
)

ElementBackgroundObjectsBanner.propTypes = {
  isFullHeight: PropTypes.bool,
  withElementVideo: PropTypes.bool,
  bannerText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonHref: PropTypes.string,
}

ElementBackgroundObjectsBanner.defaultProps = {
  isFullHeight: false,
  withElementVideo: false,
  bannerText: 'Shop hundreds of online stores',
  buttonText: 'View Shop Directory',
  buttonHref: '',
}

export default ElementBackgroundObjectsBanner
