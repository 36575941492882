import MODAL_SVG from './modalSVG.svg'

const beginStyles = {
  content: {
    marginTop: '-30px',
    opacity: '0',
  },
  overlay: {
    backgroundColor: 'rgba(9,9,24,.1)',
  },
}

const endStyles = {
  content: {
    marginTop: '0',
    opacity: '1',
  },
  overlay: {
    backgroundImage: `url(${MODAL_SVG}), linear-gradient(rgba(33,33,137,.89), rgba(33,33,137,1))`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}

const baseStyles = {
  position: 'relative',
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 500ms ease 370ms, transform 300ms ease 370ms',
    border: 'none',
    padding: '0',
    backgroundColor: 'transparent',
    maxHeight: '100vh',
  },
  overlay: {
    transition: 'background-color 300ms ease',
    zIndex: '100000',
  },
}

export { beginStyles, endStyles, baseStyles }
