import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'
import Box from '@pbds/box'

const setSpaceToken = (space, theme) => {
  // there is an inheritance rule from xs to xl
  // this is for cases where we explicitly pass 0
  if (parseInt(space) === 0) return 0
  // if null is passed it will follow inheritance rules
  if (space === null) return null
  return theme.space[`--spacing-0${space}`]
}

const BlockDivider = ({
  color,
  xsSpace,
  smSpace,
  mdSpace,
  lgSpace,
  xlSpace,
}) => {
  const theme = useContext(ThemeContext)
  const height = [
    setSpaceToken(xsSpace, theme),
    setSpaceToken(smSpace, theme),
    setSpaceToken(mdSpace, theme),
    setSpaceToken(lgSpace, theme),
    setSpaceToken(xlSpace, theme),
  ]
  return <Box backgroundColor={color.hex} height={height} />
}

const spacePropType = PropTypes.oneOf(null, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9)

BlockDivider.propTypes = {
  xsSpace: spacePropType,
  smSpace: spacePropType,
  mdSpace: spacePropType,
  lgSpace: spacePropType,
  xlSpace: spacePropType,
  color: PropTypes.shape({
    hex: PropTypes.string,
  }),
}

BlockDivider.defaultProps = {
  xsSpace: null,
  smSpace: null,
  mdSpace: null,
  lgSpace: null,
  xlSpace: null,
  color: {
    hex: '#FFFFFF',
  },
}

export default BlockDivider
