import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import Typography from '@pbds/typography'
import Box from '@pbds/box'
import Grid from '@pbds/grid'
import theme from '@pbds/theme'
import { PrimaryButton, SecondaryButton } from '@pbds/buttons'
import ElementTextImage, {
  elementTextImagePropTypes,
  elementTextImageDefaultProps,
} from '../../elements/ElementTextImage'

const { Row, Col } = Grid

const BlockPaymentsBanner = ({
  headerText,
  buttonText,
  buttonHref,
  isButtonSecondary,
  leftElementTextImage,
  rightElementTextImage,
}) => {
  const Button = isButtonSecondary ? SecondaryButton : PrimaryButton

  return (
    <Box
      as="section"
      backgroundColor={theme.colors['--background-02']}
      paddingY="--spacing-08"
    >
      <Grid gutter={false}>
        <Row verticalAlign="middle" horizontalAlign="center">
          <Col xs={12} sm={12} md={12} lg={0}>
            <Box
              flexAlign="middleCenter"
              paddingBottom="--spacing-06"
              paddingX={['--spacing-03', '--spacing-08', '--spacing-10']}
            >
              <Typography.Heading variant="--heading-2">
                {headerText}
              </Typography.Heading>
            </Box>
          </Col>
          <Col xs={8} smOffset={0} sm={5} md={4} lg={2}>
            <Box
              flexAlign="middleCenter"
              paddingBottom={[
                '--spacing-06',
                '--spacing-06',
                '--spacing-06',
                0,
              ]}
              paddingRight={[0, '--spacing-02', '--spacing-02', 0]}
            >
              <ElementTextImage {...leftElementTextImage} />
            </Box>
          </Col>
          <Col xs={0} sm={0} md={0} lg={5}>
            <Box
              flexAlign="middleCenter"
              paddingBottom="--spacing-04"
              paddingX="--spacing-07"
            >
              <Typography.Heading variant="--heading-2">
                {headerText}
              </Typography.Heading>
            </Box>
            <Box flexAlign="middleCenter">
              <Button
                to={buttonHref}
                buttonType={Link}
                buttonSize="--large-button"
                isFullWidthOnMobile={false}
              >
                {buttonText}
              </Button>
            </Box>
          </Col>
          <Col xs={8} smOffset={0} sm={5} md={4} lg={2}>
            <Box
              flexAlign="middleCenter"
              paddingBottom={[
                '--spacing-06',
                '--spacing-06',
                '--spacing-06',
                0,
              ]}
              paddingLeft={[0, '--spacing-02', '--spacing-02', 0]}
            >
              <ElementTextImage {...rightElementTextImage} />
            </Box>
          </Col>
          <Col xs={12} sm={12} md={12} lg={0}>
            <Box flexAlign="middleCenter">
              <Button
                to={buttonHref}
                buttonType={Link}
                buttonSize="--large-button"
                isFullWidthOnMobile={false}
              >
                {buttonText}
              </Button>
            </Box>
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}

BlockPaymentsBanner.propTypes = {
  headerText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonHref: PropTypes.string,
  isButtonSecondary: PropTypes.bool,
  leftElementTextImage: elementTextImagePropTypes,
  rightElementTextImage: elementTextImagePropTypes,
}

BlockPaymentsBanner.defaultProps = {
  headerText: '',
  buttonText: '',
  buttonHref: '/',
  isButtonSecondary: false,
  leftElementTextImage: elementTextImageDefaultProps,
  rightElementTextImage: elementTextImageDefaultProps,
}

export default BlockPaymentsBanner
