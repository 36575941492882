import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Location } from '@reach/router'


const PaybrightLogo = ({ width, height }) => (
  <Location>
    {({ location }) => {
      const params = location && location.search
      const { pathname } = location
      const lang = pathname.slice(1, 3)
      const isFrench = lang === 'fr';
      return (
        <Link to={`/${lang}/` + params}>
          {isFrench ?
            <svg             
              width={width}
              height={height}
              viewBox="0 0 325 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <title id="web--svg_paybright_logo_title">PayBright par Affirm</title>
              <path d="M211.374 20.8787H213.238V22.8481H213.469C213.794 22.18 214.313 21.6213 214.959 21.242C215.606 20.8627 216.352 20.6798 217.104 20.7161C220.278 20.7161 222.468 23.2025 222.468 27.1064C222.468 31.0103 220.278 33.4967 217.157 33.4967C216.398 33.5286 215.647 33.3409 214.995 32.9568C214.344 32.5727 213.821 32.0091 213.492 31.3356H213.327V37.8943H211.397L211.374 20.8787ZM216.873 31.8061C219.293 31.8061 220.538 29.709 220.538 27.0657C220.538 24.4224 219.328 22.4182 216.873 22.4182C214.513 22.4182 213.268 24.2888 213.268 27.0657C213.268 29.8426 214.548 31.8061 216.873 31.8061Z" fill="black"/>
              <path d="M223.724 29.7439C223.724 26.9031 226.274 26.3977 228.605 26.1014C230.935 25.8051 231.844 25.8923 231.844 24.9744V24.9163C231.844 23.3245 230.971 22.4008 229.195 22.4008C228.522 22.3569 227.851 22.5114 227.267 22.8446C226.684 23.1778 226.215 23.6745 225.92 24.2714L224.084 23.6266C225.07 21.3668 227.23 20.7219 229.13 20.7219C230.729 20.7219 233.774 21.1751 233.774 25.108V33.2411H231.844V31.5796H231.75C231.354 32.3871 230.21 33.5432 228.015 33.5432C225.625 33.5432 223.724 32.1606 223.724 29.7439ZM231.844 28.7737V27.0309C231.52 27.4143 229.325 27.6118 228.504 27.7396C227 27.9313 225.66 28.3845 225.66 29.831C225.66 31.1555 226.769 31.8294 228.31 31.8294C230.599 31.8352 231.844 30.3248 231.844 28.7737Z" fill="black"/>
              <path d="M236.164 20.8787H238.029V22.7493H238.164C238.431 22.1191 238.888 21.584 239.472 21.2155C240.056 20.847 240.74 20.6627 241.433 20.687C241.723 20.687 242.23 20.687 242.448 20.687V22.6506C242.06 22.5706 241.665 22.5278 241.268 22.5228C239.433 22.5228 238.093 23.7485 238.093 25.4274V33.2527H236.158L236.164 20.8787Z" fill="black"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M254.079 30.987C253.147 30.987 252.681 30.5397 252.681 29.8252C252.681 28.4425 254.25 27.972 257.112 27.6757C257.112 29.5173 255.844 31.0161 254.079 31.0161V30.987ZM255.324 20.6057C253.277 20.6774 251.3 21.3579 249.653 22.5577L250.834 24.9743C251.984 23.9469 253.46 23.3434 255.012 23.2664C256.428 23.2664 257.213 23.7311 257.213 24.6723C257.213 25.3055 256.693 25.625 255.708 25.747C252.043 26.2176 249.17 27.2168 249.17 29.9994C249.17 32.207 250.763 33.5432 253.253 33.5432C254.081 33.5472 254.895 33.3414 255.619 32.9457C256.342 32.5499 256.95 31.9774 257.384 31.2833V33.183H260.635V25.2241C260.635 21.9419 258.316 20.5767 255.289 20.5767" fill="#060809"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M288.606 20.9659V33.1656H292.146V27.2981C292.146 24.498 293.864 23.6789 295.097 23.6789C295.636 23.6749 296.163 23.8286 296.613 24.1204L297.221 20.8904C296.717 20.6908 296.177 20.594 295.634 20.6057C294.833 20.5631 294.039 20.7743 293.369 21.2081C292.699 21.642 292.188 22.2758 291.91 23.0166V20.9659H288.606Z" fill="#060809"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M313.691 20.6057C312.896 20.6179 312.116 20.8199 311.418 21.1943C310.721 21.5687 310.125 22.1043 309.684 22.7552C309.297 22.0962 308.739 21.55 308.067 21.1722C307.396 20.7944 306.634 20.5985 305.861 20.6044C305.087 20.6103 304.329 20.8178 303.664 21.2058C302.998 21.5938 302.449 22.1484 302.072 22.8133V20.9659H298.655V33.1655H302.196V26.8914C302.196 24.6374 303.376 23.5569 304.521 23.5569C305.536 23.5569 306.468 24.2017 306.468 25.8806V33.183H310.009V26.8914C310.009 24.6083 311.189 23.5569 312.369 23.5569C313.307 23.5569 314.299 24.2249 314.299 25.8399V33.183H317.839V24.7362C317.839 21.9941 315.963 20.6057 313.709 20.6057" fill="#060809"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M279.967 20.9659H276.757V19.7227C276.757 18.1077 277.689 17.6488 278.527 17.6488C279.08 17.6547 279.623 17.788 280.114 18.038L281.206 15.5748C280.247 15.0647 279.167 14.8179 278.078 14.8603C275.812 14.8603 273.234 16.1151 273.234 20.0887V20.9659H267.852V19.7227C267.852 18.1077 268.784 17.6488 269.593 17.6488C270.148 17.6482 270.695 17.7818 271.186 18.038L272.278 15.5748C271.316 15.072 270.238 14.8257 269.15 14.8603C266.884 14.8603 264.306 16.1151 264.306 20.0887V20.9659H262.246V23.6614H264.306V33.183H267.846V23.6614H273.228V33.183H276.768V23.6614H279.979L279.967 20.9659Z" fill="#060809"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M282.085 33.183H285.632V20.9659H282.085V33.183Z" fill="#060809"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M302.756 3.927C293.179 3.927 284.64 10.4742 282.215 18.8919H285.691C287.715 12.6236 294.584 7.12215 302.756 7.12215C312.688 7.12215 321.274 14.5698 321.274 26.1594C321.301 28.5362 320.967 30.9036 320.282 33.1829H323.652L323.687 33.0668C324.253 30.8069 324.532 28.4868 324.519 26.1594C324.519 13.2336 314.948 3.927 302.756 3.927Z" fill="#4A4AF4"/>
              <path d="M37.1764 9.97461C40.3925 9.97461 43.0774 11.4095 44.1986 13.4312V11.607C44.1937 11.4776 44.2159 11.3485 44.264 11.2278C44.312 11.1072 44.3849 10.9977 44.4779 10.906C44.571 10.8144 44.6823 10.7427 44.8048 10.6954C44.9273 10.6481 45.0585 10.6262 45.19 10.6311H49.3797C49.511 10.6279 49.6417 10.651 49.7637 10.6988C49.8858 10.7467 49.9967 10.8183 50.0899 10.9095C50.1831 11.0007 50.2565 11.1095 50.3059 11.2294C50.3552 11.3493 50.3794 11.4777 50.377 11.607V32.3639C50.3794 32.4935 50.3552 32.6222 50.3059 32.7424C50.2567 32.8626 50.1833 32.9717 50.0902 33.0634C49.9971 33.155 49.8862 33.2273 49.7642 33.2758C49.6421 33.3243 49.5113 33.348 49.3797 33.3457H45.19C45.0586 33.3481 44.9281 33.3243 44.8064 33.2757C44.6846 33.2271 44.5741 33.1548 44.4815 33.0631C44.3888 32.9713 44.316 32.8621 44.2674 32.7419C44.2188 32.6218 44.1954 32.4932 44.1986 32.3639V30.5746C42.9712 32.6253 40.3925 34.0254 37.1174 34.0254C31.1279 34.0254 25.5987 29.3372 25.5987 22C25.5987 14.6628 31.122 9.97461 37.1764 9.97461ZM38.038 28.3554C41.614 28.3554 44.1986 25.5205 44.1986 21.9651C44.1986 18.4098 41.614 15.6097 38.038 15.6097C37.2063 15.6187 36.3848 15.79 35.6208 16.1137C34.8569 16.4373 34.1657 16.9069 33.5873 17.4952C33.0088 18.0836 32.5547 18.779 32.2511 19.5413C31.9474 20.3035 31.8003 21.1175 31.8183 21.9361C31.8183 25.4972 34.5328 28.3554 38.038 28.3554Z" fill="#4A4AF4"/>
              <path d="M13.3836 9.97461C10.1086 9.97461 7.48262 11.4095 6.1372 13.4312V11.607C6.1415 11.4757 6.118 11.345 6.0682 11.2231C6.0184 11.1012 5.94338 10.9909 5.84792 10.8991C5.75247 10.8073 5.63865 10.7361 5.51371 10.6899C5.38878 10.6438 5.25546 10.6237 5.12222 10.6311H0.991531C0.860682 10.6287 0.730691 10.6523 0.609327 10.7005C0.487964 10.7488 0.377719 10.8206 0.285179 10.9117C0.19264 11.0028 0.119706 11.1113 0.0707355 11.2308C0.0217649 11.3503 -0.00223755 11.4782 0.000163953 11.607V41.9784C-0.00223755 42.1072 0.0217649 42.2352 0.0707355 42.3546C0.119706 42.4741 0.19264 42.5827 0.285179 42.6738C0.377719 42.7649 0.487964 42.8367 0.609327 42.8849C0.730691 42.9331 0.860682 42.9567 0.991531 42.9544H5.19894C5.32979 42.9567 5.45978 42.9331 5.58114 42.8849C5.7025 42.8367 5.81275 42.7649 5.90529 42.6738C5.99783 42.5827 6.07076 42.4741 6.11973 42.3546C6.1687 42.2352 6.19271 42.1072 6.1903 41.9784V41.6879L6.25521 30.8012C7.64785 32.8519 10.5275 33.9615 13.3364 33.9615C19.4262 33.9615 24.7843 29.2675 24.7843 21.9361C24.7843 14.6047 19.4675 9.97461 13.3836 9.97461ZM12.3863 28.3554C8.84575 28.3554 6.1608 25.5205 6.1608 21.9651C6.12911 21.1398 6.26653 20.3167 6.56488 19.5448C6.86323 18.7729 7.31639 18.0681 7.89735 17.4723C8.47831 16.8765 9.17518 16.4019 9.94641 16.077C10.7176 15.752 11.5474 15.5832 12.3863 15.5806C15.9623 15.5806 18.5411 18.4156 18.5411 21.9361C18.5411 25.4566 15.9623 28.3554 12.3863 28.3554Z" fill="#4A4AF4"/>
              <path d="M74.0576 10.6601H69.5552C69.2661 10.6562 68.9849 10.7524 68.7606 10.9318C68.5362 11.1112 68.383 11.3626 68.3277 11.6419L66.3745 18.4504L63.4948 27.24L58.6619 11.6303C58.6359 11.3758 58.5191 11.1386 58.3321 10.9609C58.1452 10.7833 57.9003 10.6767 57.641 10.6601H52.7432C51.6515 10.6601 51.5158 11.3456 51.7518 12.1299L58.7327 32.3348C58.7991 32.6081 58.9555 32.852 59.1776 33.0286C59.3996 33.2052 59.6748 33.3044 59.9601 33.3108H61.6124L58.4023 41.4904C58.0718 42.4024 58.3374 42.9543 59.2638 42.9543H63.7663C64.0548 42.9596 64.3359 42.8647 64.5604 42.6862C64.7848 42.5077 64.9382 42.2571 64.9937 41.9784L65.2887 40.9036L68.1035 33.3514L75.0195 12.2054C75.2496 11.2178 74.9487 10.6601 74.0576 10.6601Z" fill="#4A4AF4"/>
              <path d="M115.931 10.2708C116.462 10.2708 117.023 10.4974 117.023 11.4792V15.5457C117.036 15.7038 117.016 15.8629 116.962 16.0125C116.908 16.162 116.822 16.2985 116.71 16.4129C116.599 16.5273 116.464 16.6169 116.314 16.6757C116.164 16.7345 116.004 16.7612 115.843 16.7541H114.662C111.617 16.7541 109.829 18.2819 109.829 22.1916V32.3638C109.826 32.4879 109.797 32.6101 109.745 32.7232C109.693 32.8364 109.619 32.9383 109.527 33.023C109.434 33.1078 109.326 33.1738 109.208 33.2172C109.09 33.2606 108.964 33.2805 108.838 33.2759H104.637C104.508 33.2751 104.38 33.2492 104.262 33.1996C104.143 33.15 104.036 33.0777 103.946 32.9869C103.856 32.8962 103.785 32.7887 103.737 32.6707C103.69 32.5528 103.666 32.4268 103.669 32.2999V11.5082C103.665 11.3784 103.688 11.2492 103.736 11.1286C103.785 11.008 103.859 10.8985 103.952 10.8069C104.046 10.7154 104.157 10.6438 104.28 10.5965C104.403 10.5493 104.534 10.5274 104.666 10.5322H108.637C108.892 10.5244 109.139 10.6159 109.324 10.7869C109.51 10.9578 109.62 11.1942 109.629 11.4443V13.5938C110.18 12.5866 110.996 11.7436 111.992 11.153C112.987 10.5623 114.126 10.2456 115.288 10.236C115.501 10.2656 115.716 10.2773 115.931 10.2708Z" fill="#2ABC7A"/>
              <path d="M117.654 3.75274C117.66 3.00725 117.89 2.2801 118.314 1.66264C118.738 1.04518 119.338 0.564964 120.039 0.282329C120.74 -0.000305787 121.51 -0.0727517 122.252 0.0740982C122.995 0.220948 123.677 0.580543 124.212 1.10769C124.747 1.63485 125.113 2.30604 125.262 3.03694C125.411 3.76783 125.337 4.52583 125.05 5.21568C124.763 5.90553 124.275 6.49647 123.648 6.91422C123.021 7.33198 122.282 7.55793 121.525 7.56368C120.499 7.56368 119.514 7.16217 118.788 6.44748C118.062 5.73279 117.654 4.76346 117.654 3.75274ZM119.424 33.2469C119.293 33.2476 119.163 33.2228 119.041 33.1738C118.919 33.1248 118.809 33.0526 118.715 32.9614C118.622 32.8701 118.548 32.7616 118.498 32.6421C118.447 32.5226 118.421 32.3945 118.421 32.2651V11.5082C118.416 11.3788 118.439 11.2497 118.487 11.129C118.535 11.0084 118.608 10.8989 118.701 10.8072C118.794 10.7156 118.905 10.6439 119.027 10.5966C119.15 10.5493 119.281 10.5274 119.413 10.5323H123.543C123.675 10.5274 123.806 10.5493 123.929 10.5966C124.051 10.6439 124.162 10.7156 124.255 10.8072C124.348 10.8989 124.421 11.0084 124.469 11.129C124.517 11.2497 124.54 11.3788 124.535 11.5082V32.2012C124.529 32.4587 124.423 32.7042 124.238 32.8869C124.054 33.0696 123.805 33.1756 123.543 33.183H119.413L119.424 33.2469Z" fill="#2ABC7A"/>
              <path d="M198.392 29.889L196.934 27.5653C196.533 26.9437 195.754 26.9437 195.081 27.4026C194.432 27.8303 193.673 28.0662 192.892 28.0823C191.8 28.0823 190.248 27.4026 190.248 25.1777V15.5806H196.934C197.195 15.5747 197.444 15.47 197.628 15.2882C197.813 15.1064 197.919 14.8616 197.925 14.6046V11.4792C197.925 11.2198 197.821 10.971 197.635 10.787C197.45 10.603 197.197 10.4989 196.934 10.4974H190.248V5.80343C190.252 5.67213 190.229 5.54139 190.179 5.41952C190.129 5.29764 190.054 5.1873 189.959 5.09551C189.863 5.00372 189.75 4.93248 189.625 4.88634C189.5 4.84019 189.366 4.82014 189.233 4.82746H185.032C184.9 4.82169 184.768 4.84298 184.645 4.88999C184.523 4.93699 184.411 5.00868 184.318 5.10048C184.224 5.19229 184.152 5.3022 184.104 5.42322C184.056 5.54425 184.034 5.67373 184.04 5.80343V10.5613H180.86C180.729 10.5612 180.599 10.5871 180.479 10.6375C180.358 10.6878 180.249 10.7615 180.158 10.8543C180.067 10.947 179.997 11.0568 179.95 11.1772C179.903 11.2975 179.881 11.4259 179.886 11.5547V14.6801C179.883 14.8094 179.906 14.938 179.955 15.0582C180.003 15.1784 180.076 15.2876 180.169 15.3793C180.261 15.471 180.372 15.5434 180.494 15.5919C180.616 15.6405 180.746 15.6643 180.877 15.6619H184.058V26.5951C184.058 31.7132 187.929 33.7348 191.7 33.7348C194.349 33.7348 196.698 32.9506 198.22 31.4518C198.443 31.2597 198.583 30.9926 198.615 30.7032C198.647 30.4138 198.567 30.1233 198.392 29.889Z" fill="#2ABC7A"/>
              <path d="M145.755 33.183V30.1796C144.574 32.0095 142.013 33.4096 138.774 33.4096C132.448 33.4096 127.119 28.977 127.119 21.6747C127.119 14.3723 132.548 9.88165 138.538 9.88165C141.819 9.88165 144.598 11.3456 145.755 13.3324V11.5083C145.752 11.3795 145.776 11.2515 145.825 11.132C145.874 11.0125 145.947 10.904 146.04 10.8129C146.132 10.7218 146.242 10.65 146.364 10.6018C146.485 10.5536 146.615 10.5299 146.746 10.5323H150.877C151.008 10.5274 151.139 10.5493 151.262 10.5966C151.384 10.6439 151.496 10.7157 151.589 10.8073C151.682 10.8989 151.755 11.0085 151.803 11.1291C151.851 11.2497 151.873 11.3788 151.868 11.5083V33.3457C151.868 39.6314 147.147 44 139.476 44C133.321 44 128.819 40.1542 127.727 35.8204C127.685 35.7273 127.664 35.6264 127.665 35.5245C127.666 35.4226 127.689 35.322 127.732 35.2296C127.776 35.1371 127.839 35.055 127.917 34.9885C127.996 34.9221 128.088 34.873 128.187 34.8445H132.725C133.315 34.8445 133.652 34.9084 133.817 35.2337C134.342 36.93 136.13 38.6903 139.576 38.6903C143.796 38.7832 145.755 36.5699 145.755 33.183ZM139.565 28.0301C143.141 28.0301 145.725 25.3578 145.725 21.8083C145.725 18.2588 143.141 15.5806 139.565 15.5806C138.74 15.573 137.922 15.7302 137.16 16.0426C136.399 16.355 135.709 16.8163 135.134 17.3984C134.559 17.9805 134.109 18.6714 133.813 19.4294C133.517 20.1874 133.379 20.9966 133.41 21.8083C133.41 25.3578 136.024 28.0301 139.565 28.0301Z" fill="#2ABC7A"/>
              <path d="M90.5096 9.81192C87.2286 9.81192 84.5142 11.2817 83.3576 13.1697V8.38282L83.2927 1.95766C83.2852 1.70015 83.1775 1.45531 82.9919 1.27369C82.8064 1.09207 82.557 0.98754 82.2954 0.981689H78.1647C78.0332 0.976798 77.9021 0.998697 77.7795 1.04601C77.657 1.09333 77.5457 1.16504 77.4527 1.25666C77.3596 1.34829 77.2867 1.45784 77.2387 1.57846C77.1906 1.69909 77.1684 1.82818 77.1733 1.95766L77.2383 8.38282V32.2012C77.2351 32.3305 77.2585 32.4591 77.3071 32.5793C77.3557 32.6994 77.4285 32.8087 77.5211 32.9004C77.6137 32.9921 77.7243 33.0644 77.846 33.113C77.9678 33.1616 78.0983 33.1854 78.2296 33.183H82.3603C82.4915 33.1846 82.6216 33.1602 82.743 33.1113C82.8644 33.0625 82.9745 32.9901 83.067 32.8986C83.1595 32.807 83.2323 32.6981 83.2812 32.5783C83.3301 32.4585 83.3541 32.3303 83.3517 32.2012V30.3771C84.6735 32.4336 87.1578 33.8336 90.4329 33.8336C96.4932 33.8336 101.958 29.1397 101.958 21.8083C101.958 14.4768 96.4637 9.81192 90.5096 9.81192ZM89.5477 28.2566C86.0366 28.2566 83.393 25.4217 83.393 21.8663C83.393 18.311 86.0366 15.5109 89.5477 15.5109C90.3796 15.5199 91.2015 15.6912 91.9658 16.0148C92.7301 16.3384 93.4216 16.8078 94.0005 17.3961C94.5794 17.9844 95.0341 18.6797 95.3384 19.442C95.6427 20.2043 95.7905 21.0184 95.7733 21.8373C95.7025 25.3926 93.047 28.2566 89.5477 28.2566Z" fill="#2ABC7A"/>
              <path d="M169.294 9.81189C165.617 9.81189 162.968 11.3456 161.622 13.1697V1.92859C161.613 1.67843 161.504 1.44205 161.318 1.27113C161.132 1.10022 160.885 1.00867 160.631 1.01652H156.424C156.293 1.01415 156.163 1.03778 156.041 1.08599C155.92 1.1342 155.81 1.206 155.717 1.29711C155.625 1.38821 155.552 1.49674 155.503 1.61622C155.454 1.7357 155.43 1.86367 155.432 1.99249V32.2012C155.429 32.3305 155.452 32.4591 155.501 32.5792C155.55 32.6994 155.623 32.8086 155.715 32.9003C155.808 32.9921 155.918 33.0644 156.04 33.113C156.162 33.1615 156.292 33.1853 156.424 33.183H160.631C160.886 33.1892 161.133 33.0964 161.318 32.9245C161.504 32.7527 161.613 32.5157 161.622 32.2651V21.3202C161.622 17.7998 163.936 15.4179 167.382 15.4179C170.958 15.4179 172.976 17.7417 172.976 21.3202V32.2651C172.98 32.3894 173.008 32.5119 173.06 32.6253C173.112 32.7388 173.186 32.841 173.278 32.9263C173.37 33.0115 173.479 33.0781 173.597 33.1221C173.715 33.1661 173.841 33.1868 173.967 33.183H178.098C178.224 33.1868 178.35 33.1661 178.468 33.1221C178.587 33.0781 178.695 33.0115 178.787 32.9263C178.879 32.841 178.953 32.7388 179.005 32.6253C179.057 32.5119 179.086 32.3894 179.089 32.2651V19.6878C179.201 14.0527 175.384 9.81189 169.294 9.81189Z" fill="#2ABC7A"/>
            </svg>            
            :
            <svg 
              width={width}
              height={height}
              viewBox="0 0 317 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <title id="web--svg_paybright_logo_title">PayBright by Affirm</title>
              <path d="M37.604 10.4272C40.7172 10.4272 43.3163 11.8381 44.4016 13.826V12.0323C44.3968 11.905 44.4184 11.7781 44.4649 11.6595C44.5114 11.5409 44.5819 11.4331 44.672 11.3431C44.7621 11.253 44.8698 11.1824 44.9884 11.1359C45.107 11.0894 45.234 11.0679 45.3613 11.0727H49.417C49.5441 11.0696 49.6706 11.0922 49.7887 11.1393C49.9069 11.1863 50.0143 11.2568 50.1045 11.3465C50.1947 11.4361 50.2658 11.5431 50.3136 11.661C50.3613 11.7789 50.3847 11.9052 50.3824 12.0323V32.4423C50.3847 32.5697 50.3613 32.6963 50.3136 32.8144C50.2659 32.9326 50.1949 33.04 50.1048 33.1301C50.0147 33.2202 49.9073 33.2912 49.7892 33.3389C49.671 33.3866 49.5444 33.41 49.417 33.4077H45.3613C45.2341 33.41 45.1078 33.3866 44.9899 33.3388C44.8721 33.2911 44.7651 33.22 44.6754 33.1298C44.5858 33.0396 44.5153 32.9322 44.4682 32.814C44.4212 32.6959 44.3985 32.5694 44.4016 32.4423V30.6829C43.2135 32.6993 40.7172 34.076 37.5469 34.076C31.7489 34.076 26.3965 29.4662 26.3965 22.2516C26.3965 15.037 31.7432 10.4272 37.604 10.4272ZM38.438 28.5008C41.8997 28.5008 44.4016 25.7132 44.4016 22.2173C44.4016 18.7214 41.8997 15.9681 38.438 15.9681C37.633 15.977 36.8377 16.1454 36.0981 16.4636C35.3586 16.7819 34.6895 17.2436 34.1296 17.8221C33.5697 18.4006 33.1301 19.0844 32.8362 19.8339C32.5422 20.5835 32.3999 21.3838 32.4173 22.1888C32.4173 25.6904 35.0449 28.5008 38.438 28.5008V28.5008Z" fill="#4A4AF4"/>
              <path d="M14.5723 10.4272C11.402 10.4272 8.86002 11.8381 7.55762 13.826V12.0323C7.56178 11.9032 7.53904 11.7747 7.49083 11.6548C7.44262 11.535 7.37001 11.4265 7.2776 11.3362C7.18519 11.246 7.07502 11.1759 6.95408 11.1306C6.83314 11.0852 6.70408 11.0655 6.57511 11.0727H2.57652C2.44986 11.0703 2.32402 11.0936 2.20654 11.141C2.08906 11.1884 1.98234 11.259 1.89276 11.3486C1.80318 11.4382 1.73258 11.5449 1.68517 11.6624C1.63777 11.7798 1.61453 11.9057 1.61686 12.0323V41.8961C1.61453 42.0228 1.63777 42.1486 1.68517 42.2661C1.73258 42.3835 1.80318 42.4903 1.89276 42.5798C1.98234 42.6694 2.08906 42.74 2.20654 42.7874C2.32402 42.8348 2.44986 42.8581 2.57652 42.8557H6.64937C6.77603 42.8581 6.90187 42.8348 7.01935 42.7874C7.13683 42.74 7.24355 42.6694 7.33313 42.5798C7.42271 42.4903 7.49331 42.3835 7.54072 42.2661C7.58812 42.1486 7.61136 42.0228 7.60903 41.8961V41.6105L7.67186 30.9057C9.01996 32.9221 11.8075 34.0132 14.5266 34.0132C20.4217 34.0132 25.6084 29.3976 25.6084 22.1888C25.6084 14.9799 20.4616 10.4272 14.5723 10.4272ZM13.6069 28.5008C10.1796 28.5008 7.58047 25.7132 7.58047 22.2173C7.54979 21.4058 7.68282 20.5964 7.97163 19.8374C8.26043 19.0785 8.6991 18.3854 9.26148 17.7995C9.82386 17.2137 10.4984 16.7471 11.245 16.4275C11.9916 16.108 12.7948 15.942 13.6069 15.9395C17.0686 15.9395 19.5648 18.7271 19.5648 22.1888C19.5648 25.6504 17.0686 28.5008 13.6069 28.5008Z" fill="#4A4AF4"/>
              <path d="M73.3057 11.1012H68.9472C68.6674 11.0974 68.3952 11.192 68.178 11.3684C67.9609 11.5449 67.8126 11.792 67.7591 12.0666L65.8683 18.7614L63.0807 27.4041L58.4024 12.0552C58.3772 11.805 58.2641 11.5717 58.0831 11.3971C57.9022 11.2224 57.6651 11.1176 57.4141 11.1012H52.673C51.6162 11.1012 51.4848 11.7753 51.7133 12.5465L58.4709 32.4137C58.5352 32.6824 58.6866 32.9223 58.9015 33.0959C59.1165 33.2695 59.3828 33.3671 59.6591 33.3734H61.2585L58.151 41.4163C57.8311 42.3131 58.0882 42.8558 58.985 42.8558H63.3435C63.6227 42.861 63.8949 42.7676 64.1122 42.5921C64.3295 42.4166 64.478 42.1702 64.5316 41.8961L64.8173 40.8393L67.542 33.4134L74.2368 12.6207C74.4596 11.6496 74.1682 11.1012 73.3057 11.1012Z" fill="#4A4AF4"/>
              <path d="M113.84 10.7184C114.354 10.7184 114.897 10.9412 114.897 11.9065V15.9051C114.91 16.0606 114.89 16.2171 114.838 16.3641C114.786 16.5111 114.703 16.6454 114.595 16.7578C114.486 16.8703 114.356 16.9584 114.211 17.0162C114.066 17.0741 113.91 17.1003 113.754 17.0933H112.612C109.664 17.0933 107.934 18.5956 107.934 22.44V32.4422C107.93 32.5642 107.902 32.6843 107.852 32.7955C107.801 32.9068 107.73 33.007 107.64 33.0903C107.551 33.1737 107.446 33.2386 107.332 33.2813C107.218 33.3239 107.096 33.3436 106.974 33.339H102.907C102.782 33.3382 102.659 33.3128 102.544 33.264C102.429 33.2152 102.325 33.1441 102.238 33.0549C102.151 32.9656 102.082 32.8599 102.036 32.7439C101.99 32.628 101.968 32.504 101.97 32.3793V11.9351C101.966 11.8075 101.988 11.6805 102.035 11.5618C102.083 11.4432 102.154 11.3356 102.244 11.2456C102.335 11.1556 102.443 11.0851 102.562 11.0387C102.681 10.9922 102.808 10.9707 102.935 10.9754H106.78C107.026 10.9677 107.265 11.0577 107.445 11.2258C107.624 11.3939 107.73 11.6263 107.739 11.8723V13.9858C108.273 12.9954 109.063 12.1666 110.027 11.5858C110.99 11.005 112.092 10.6936 113.217 10.6841C113.424 10.7132 113.632 10.7247 113.84 10.7184V10.7184Z" fill="#2ABC7A"/>
              <path d="M115.508 4.30928C115.514 3.57626 115.736 2.86125 116.147 2.25412C116.558 1.64698 117.139 1.17478 117.817 0.896873C118.496 0.618962 119.241 0.547727 119.96 0.692122C120.678 0.836518 121.338 1.1901 121.857 1.70844C122.375 2.22679 122.728 2.88676 122.873 3.60544C123.017 4.32413 122.946 5.06946 122.668 5.74778C122.39 6.4261 121.918 7.00715 121.311 7.41793C120.704 7.82871 119.989 8.05088 119.256 8.05653C118.262 8.05653 117.309 7.66173 116.606 6.95899C115.903 6.25624 115.508 5.30311 115.508 4.30928ZM117.222 33.3105C117.095 33.3112 116.969 33.2868 116.851 33.2386C116.733 33.1905 116.626 33.1195 116.536 33.0298C116.446 32.94 116.374 32.8334 116.325 32.7159C116.276 32.5984 116.251 32.4724 116.251 32.3451V11.9352C116.246 11.8078 116.268 11.6809 116.314 11.5623C116.361 11.4437 116.431 11.336 116.521 11.2459C116.612 11.1558 116.719 11.0853 116.838 11.0387C116.956 10.9922 117.083 10.9707 117.211 10.9755H121.209C121.337 10.9707 121.464 10.9922 121.582 11.0387C121.701 11.0853 121.809 11.1558 121.899 11.2459C121.989 11.336 122.059 11.4437 122.106 11.5623C122.152 11.6809 122.174 11.8078 122.169 11.9352V32.2823C122.163 32.5355 122.06 32.7769 121.882 32.9565C121.703 33.1362 121.463 33.2404 121.209 33.2476H117.211L117.222 33.3105Z" fill="#2ABC7A"/>
              <path d="M193.663 30.0088L192.252 27.7239C191.864 27.1127 191.11 27.1127 190.458 27.564C189.83 27.9845 189.095 28.2165 188.339 28.2323C187.282 28.2323 185.78 27.564 185.78 25.3762V15.9395H192.252C192.505 15.9337 192.746 15.8307 192.924 15.652C193.103 15.4733 193.206 15.2325 193.212 14.9799V11.9067C193.212 11.6516 193.111 11.4069 192.931 11.226C192.751 11.0452 192.507 10.9428 192.252 10.9413H185.78V6.32576C185.784 6.19666 185.762 6.0681 185.713 5.94826C185.665 5.82842 185.592 5.71993 185.5 5.62967C185.408 5.53941 185.297 5.46937 185.177 5.42399C185.056 5.37862 184.927 5.3589 184.798 5.3661H180.73C180.603 5.36043 180.476 5.38136 180.357 5.42758C180.238 5.4738 180.129 5.54429 180.039 5.63456C179.949 5.72483 179.878 5.83291 179.832 5.95191C179.786 6.07091 179.765 6.19823 179.771 6.32576V11.0041H176.692C176.565 11.004 176.44 11.0295 176.323 11.079C176.206 11.1285 176.101 11.201 176.013 11.2922C175.925 11.3834 175.856 11.4914 175.811 11.6097C175.766 11.728 175.745 11.8543 175.749 11.9809V15.0541C175.746 15.1813 175.769 15.3077 175.816 15.4259C175.863 15.544 175.933 15.6514 176.023 15.7416C176.113 15.8318 176.22 15.9029 176.338 15.9507C176.456 15.9984 176.582 16.0218 176.709 16.0195H179.788V26.77C179.788 31.8025 183.535 33.7904 187.185 33.7904C189.75 33.7904 192.024 33.0192 193.497 31.5454C193.712 31.3566 193.849 31.094 193.879 30.8094C193.91 30.5249 193.833 30.2392 193.663 30.0088Z" fill="#2ABC7A"/>
              <path d="M142.71 33.2476V30.2944C141.567 32.0937 139.088 33.4704 135.952 33.4704C129.829 33.4704 124.671 29.1119 124.671 21.9316C124.671 14.7513 129.926 10.3357 135.724 10.3357C138.9 10.3357 141.59 11.7752 142.71 13.7288V11.9351C142.708 11.8085 142.731 11.6826 142.778 11.5652C142.826 11.4477 142.896 11.3409 142.986 11.2514C143.075 11.1618 143.182 11.0912 143.3 11.0438C143.417 10.9964 143.543 10.9731 143.67 10.9755H147.668C147.795 10.9707 147.922 10.9922 148.041 11.0387C148.16 11.0852 148.267 11.1558 148.357 11.2458C148.448 11.3359 148.518 11.4437 148.565 11.5623C148.611 11.6809 148.633 11.8078 148.628 11.9351V33.4076C148.628 39.5882 144.058 43.8839 136.632 43.8839C130.674 43.8839 126.316 40.1023 125.259 35.841C125.218 35.7494 125.198 35.6502 125.198 35.55C125.199 35.4497 125.222 35.3509 125.264 35.26C125.306 35.1691 125.367 35.0883 125.443 35.023C125.519 34.9577 125.608 34.9094 125.704 34.8813H130.097C130.668 34.8813 130.994 34.9442 131.154 35.264C131.662 36.932 133.393 38.6628 136.729 38.6628C140.813 38.7542 142.71 36.5779 142.71 33.2476ZM136.718 28.1808C140.179 28.1808 142.681 25.5532 142.681 22.063C142.681 18.5728 140.179 15.9394 136.718 15.9394C135.919 15.9319 135.127 16.0865 134.39 16.3937C133.653 16.7009 132.986 17.1544 132.429 17.7268C131.872 18.2992 131.437 18.9786 131.15 19.7239C130.863 20.4692 130.73 21.2649 130.76 22.063C130.76 25.5532 133.29 28.1808 136.718 28.1808Z" fill="#2ABC7A"/>
              <path d="M89.2317 10.2672C86.0557 10.2672 83.4281 11.7124 82.3085 13.5689V8.86196L82.2456 2.54418C82.2384 2.29098 82.1342 2.05023 81.9545 1.87164C81.7749 1.69306 81.5335 1.59027 81.2803 1.58452H77.2817C77.1544 1.57971 77.0274 1.60125 76.9088 1.64777C76.7902 1.6943 76.6825 1.76481 76.5924 1.8549C76.5023 1.94499 76.4318 2.05272 76.3853 2.17132C76.3387 2.28993 76.3172 2.41687 76.322 2.54418L76.3848 8.86196V32.2823C76.3817 32.4094 76.4044 32.5359 76.4514 32.654C76.4985 32.7722 76.569 32.8796 76.6586 32.9698C76.7483 33.06 76.8553 33.1311 76.9732 33.1788C77.091 33.2266 77.2173 33.25 77.3445 33.2476H81.3431C81.47 33.2492 81.596 33.2252 81.7135 33.1772C81.831 33.1292 81.9377 33.058 82.0272 32.968C82.1167 32.878 82.1872 32.7709 82.2345 32.6531C82.2818 32.5353 82.3051 32.4092 82.3028 32.2823V30.4886C83.5823 32.5108 85.9872 33.8874 89.1575 33.8874C95.024 33.8874 100.314 29.2719 100.314 22.063C100.314 14.8541 94.9954 10.2672 89.2317 10.2672ZM88.3006 28.4036C84.9018 28.4036 82.3427 25.616 82.3427 22.1201C82.3427 18.6242 84.9018 15.8709 88.3006 15.8709C89.1059 15.8798 89.9015 16.0482 90.6413 16.3664C91.3812 16.6845 92.0506 17.1462 92.611 17.7246C93.1714 18.303 93.6116 18.9868 93.9061 19.7363C94.2007 20.4859 94.3437 21.2864 94.3271 22.0916C94.2585 25.5875 91.688 28.4036 88.3006 28.4036Z" fill="#2ABC7A"/>
              <path d="M165.496 10.2672C161.937 10.2672 159.372 11.7753 158.07 13.5689V2.51566C158.061 2.26969 157.955 2.03726 157.775 1.8692C157.596 1.70114 157.356 1.61112 157.11 1.61884H153.038C152.911 1.61651 152.785 1.63974 152.668 1.68715C152.55 1.73455 152.443 1.80515 152.354 1.89473C152.264 1.98431 152.194 2.09103 152.146 2.20852C152.099 2.326 152.076 2.45183 152.078 2.5785V32.2823C152.075 32.4094 152.097 32.5359 152.145 32.6541C152.192 32.7722 152.262 32.8796 152.352 32.9698C152.441 33.06 152.548 33.1311 152.666 33.1789C152.784 33.2266 152.91 33.25 153.038 33.2477H157.11C157.357 33.2539 157.596 33.1626 157.776 32.9936C157.955 32.8246 158.061 32.5916 158.07 32.3451V21.5832C158.07 18.1216 160.309 15.7796 163.645 15.7796C167.107 15.7796 169.061 18.0645 169.061 21.5832V32.3451C169.064 32.4674 169.092 32.5878 169.142 32.6994C169.192 32.8109 169.264 32.9115 169.353 32.9953C169.442 33.0791 169.547 33.1445 169.662 33.1879C169.776 33.2312 169.898 33.2515 170.02 33.2477H174.019C174.141 33.2515 174.263 33.2312 174.377 33.1879C174.492 33.1445 174.597 33.0791 174.686 32.9953C174.775 32.9115 174.846 32.8109 174.897 32.6994C174.947 32.5878 174.975 32.4674 174.978 32.3451V19.9781C175.087 14.4372 171.391 10.2672 165.496 10.2672Z" fill="#2ABC7A"/>
              <path d="M210.417 17.059H212.285V23.0283H212.44C212.754 22.3744 213.255 21.8279 213.879 21.457C214.503 21.0862 215.222 20.9075 215.947 20.9434C219.009 20.9434 221.122 23.3768 221.122 27.1926C221.122 31.0084 219.009 33.4761 215.981 33.4761C215.249 33.5065 214.524 33.322 213.896 32.9454C213.268 32.5688 212.763 32.0166 212.445 31.3568H212.217V33.219H210.417V17.059ZM215.724 31.8081C218.06 31.8081 219.26 29.7517 219.26 27.164C219.26 24.5764 218.118 22.5942 215.724 22.5942C213.439 22.5942 212.251 24.4279 212.251 27.164C212.251 29.9002 213.485 31.8081 215.724 31.8081V31.8081Z" fill="black"/>
              <path d="M223.373 37.5204L223.847 35.881C225.206 36.2294 226.058 36.0524 226.703 34.2359L227.069 33.2876L222.585 21.1205H224.607L227.954 30.7799H228.08L231.427 21.1205H233.461L228.32 34.9842C227.623 36.8406 226.429 37.7318 224.749 37.7318C224.281 37.7515 223.814 37.6797 223.373 37.5204V37.5204Z" fill="black"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M245.08 31.0712C244.166 31.0712 243.714 30.6257 243.714 29.8888C243.714 28.5179 245.245 28.0495 248.039 27.7524C248.039 29.5861 246.805 31.0712 245.08 31.0712V31.0712ZM246.285 20.7892C244.283 20.8592 242.349 21.5344 240.738 22.7256L241.881 25.1248C243.001 24.0984 244.442 23.4926 245.959 23.4111C247.347 23.4111 248.113 23.8738 248.113 24.8049C248.113 25.4389 247.604 25.7531 246.645 25.8788C243.057 26.3472 240.247 27.3297 240.247 30.0944C240.247 32.288 241.807 33.6132 244.246 33.6132C245.048 33.6115 245.837 33.4043 246.537 33.0113C247.237 32.6184 247.825 32.0527 248.244 31.3683V33.2533H251.483V25.3533C251.483 22.0916 249.198 20.7834 246.262 20.7834" fill="#060809"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M278.822 21.1205V33.2476H282.29V27.404C282.29 24.6221 284.003 23.811 285.146 23.811C285.671 23.8062 286.186 23.9573 286.625 24.2451L287.259 21.0405C286.765 20.8449 286.237 20.7497 285.706 20.7606C284.922 20.718 284.146 20.9274 283.49 21.3581C282.834 21.7888 282.333 22.4182 282.061 23.1541V21.1205H278.822Z" fill="#060809"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M303.339 20.7663C302.563 20.7787 301.801 20.9789 301.118 21.35C300.436 21.721 299.853 22.2518 299.421 22.897C299.041 22.2461 298.496 21.7071 297.84 21.3346C297.185 20.9621 296.443 20.7693 295.69 20.7756C294.936 20.782 294.197 20.9874 293.548 21.3709C292.9 21.7545 292.364 22.3026 291.995 22.9598V21.1205H288.653V33.2476H292.12V27.0098C292.12 24.7649 293.297 23.691 294.405 23.691C295.399 23.691 296.313 24.3365 296.313 25.9759V33.2476H299.775V27.0098C299.775 24.7249 300.917 23.691 302.06 23.691C302.974 23.691 303.939 24.3593 303.939 25.9759V33.2476H307.407V24.8677C307.407 22.143 305.573 20.7663 303.357 20.7663" fill="#060809"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M270.38 21.1206H267.238V19.8924C267.238 18.2873 268.158 17.8246 268.952 17.8246C269.491 17.8305 270.021 17.9635 270.5 18.213L271.574 15.7682C270.637 15.2628 269.581 15.0182 268.518 15.0599C266.301 15.0599 263.776 16.3108 263.776 20.2009V21.1206H258.521V19.8924C258.521 18.2873 259.435 17.8246 260.235 17.8246C260.777 17.8262 261.31 17.9595 261.789 18.213L262.857 15.7682C261.918 15.268 260.864 15.0237 259.801 15.0599C257.584 15.0599 255.065 16.3108 255.065 20.2009V21.1206H253.054V23.7996H255.059V33.2477H258.527V23.7996H263.782V33.2477H267.249V23.7996H270.391L270.38 21.1206Z" fill="#060809"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M272.447 33.2477H275.914V21.1206H272.447V33.2477Z" fill="#060809"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M292.652 4.2065C283.29 4.2065 274.944 10.7071 272.579 19.0584H275.972C277.949 12.832 284.661 7.3711 292.652 7.3711C302.363 7.3711 310.748 14.7628 310.748 26.273C310.776 28.6311 310.451 30.9801 309.783 33.242H313.073L313.108 33.1277C313.653 30.8804 313.918 28.5741 313.896 26.2616C313.896 13.4261 304.545 4.18936 292.629 4.18936" fill="#4A4AF4"/>
            </svg>
          }
        </Link>
      )
    }}
  </Location>
)

PaybrightLogo.defaultProps = {
  width: 201.72,
  height: 28,
}

PaybrightLogo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default PaybrightLogo
