import React, { useEffect, useState } from 'react'
import { StructuredText } from 'react-datocms'
import PropTypes from 'prop-types'
import Box from '@pbds/box'
import { beigeBread25 } from '@pbds/core-colors'
import Typography from '@pbds/typography'
import styled from 'styled-components'
import Grid from '@pbds/grid'
import LeadFormCard from './LeadFormCard'
import { Link } from 'gatsby'
import Card from '@pbds/card'
import { PrimaryButton } from '@pbds/buttons'
import TabletMask from './assets/TabletBG.svg'
import DesktopMask from './assets/DesktopBG.svg'
import gtmEvent from '../../shared/gtmEvent'
import HiddenIframe from '../BlockLeadForm/HiddenIframe'
import replaceAll from '../../../../utils/replaceAll'

// above and below prop take [sm, md, lg, xl] breakpoint arguments
const Hidden = styled.div`
  ${({ theme, above, below }) => `
      @media screen and (min-width: ${theme.breakpoints[above]}) { display: none; }
      @media screen and (max-width: ${theme.breakpoints[below]}) { display: none; }
    `}
`

const BackgroundMask = styled.section`
  background-position: center;
  background-size: cover;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: ${({ theme }) => theme.space['--spacing-08']} 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    background-image: url(${({ tablet }) => tablet});
    padding: ${({ theme }) => theme.space['--spacing-08']} 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    background-image: url(${({ desktop }) => desktop});
    position: relative;
    padding: ${({ theme }) => theme.space['--spacing-07']} 0
      ${({ theme }) => theme.space['--spacing-09']} 0;
  }
`

const RelativeContainer = styled.div`
  position: relative;
  height: auto;
  width: auto;
`

const TopAccentImage = styled.img`
  height: 120.14px;
  width: 99.86px;
  position: absolute;
  top: -72px;
  left: 0;
`

const MiddleAccentImage = styled.img`
  height: 244.95px;
  width: 155.46px;
  position: absolute;
  top: 236px;
  right: 0;
`

const BottomAccentImage = styled.img`
  height: 342.22px;
  width: 500px;
  top: 500px;
  left: -50px;
  position: absolute;
`

const renderFormSuccess = (
  cardHeaderText,
  cardParagraphText,
  cardButtonText,
  cardButtonHref,
  location
) => {
  const parsedQueryString = Object.fromEntries(new URLSearchParams(location.search).entries())
  const isPDF = cardButtonHref.endsWith('.pdf')
  gtmEvent({
    event: 'submitForm',
    label: 'sales lead form',
    action: parsedQueryString.company || 'organic',
    category: parsedQueryString.utm_campaign || 'sales lead form',
    value: parsedQueryString.utm_source || 'organic',
  })

  return (
    <Card
      maxWidth={['90vw', '472px', '481px', '481px']}
      elevation="--elevation-regular-3"
      marginX="auto"
      paddingX={[
        '--spacing-03',
        '--spacing-07',
        '--spacing-07',
        '--spacing-07',
      ]}
      paddingY="--spacing-07"
    >
      <Typography.Heading variant="--heading-3" marginBottom="--spacing-02">
        {cardHeaderText}
      </Typography.Heading>
      <Typography.Deck marginBottom="--spacing-03">
        {cardParagraphText}
      </Typography.Deck>
      <PrimaryButton
        to={isPDF ? null : cardButtonHref}
        href={isPDF ? cardButtonHref : null}
        buttonSize="--large-button"
        width="auto"
        buttonType={Link}
        download={isPDF}
        target={isPDF ? '_blank' : '_self'}
        buttonType={isPDF ? 'a' : null}
      >
        {cardButtonText}
      </PrimaryButton>
    </Card>
  )
}

const BlockLeadForm = ({
  headerText,
  caslComplianceText,
  formName,
  structuredText,
  firstPlant,
  secondPlant,
  thirdPlant,
  formFirstHeader,
  firstPlaceHolder,
  firstFieldErrorMessage,
  secondPlaceHolder,
  secondFieldErrorMessage,
  thirdPlaceHolder,
  thirdFieldErrorMessage,
  fourthPlaceHolder,
  fourthFieldErrorMessage,
  formSecondHeader,
  fifthPlaceHolder,
  fifthFieldErrorMessage,
  sixthPlaceHolder,
  sixthFieldErrorMessage,
  dropDownFields,
  dropDownPlaceHolder,
  secondDropDownFields,
  secondDropDownPlaceHolder,
  dropDownErrorMessage,
  textAreaPlaceHolder,
  genericMissingErrorMessage,
  buttonText,
  cardHeaderText,
  cardParagraphText,
  cardButtonText,
  cardButtonHref,
  location,
}) => {
  // if query param, then form is sent

  const [formSuccess, setFormSuccess] = useState(false)

  const { pathname } = location
  const lang = pathname.slice(1, 3)
  const isEnglish = lang === 'en'

  const { Heading, Deck } = Typography

  //control dropdown data mutation for display
  useEffect(() => {
    if (dropDownFields[0]?.id !== '') {
      dropDownFields.unshift({ id: '', text: '', value: '' })
    }
  }, [dropDownFields])

  const leadForm = {
    caslComplianceText,
    formName,
    formFirstHeader,
    firstPlaceHolder,
    firstFieldErrorMessage,
    secondPlaceHolder,
    secondFieldErrorMessage,
    thirdPlaceHolder,
    thirdFieldErrorMessage,
    fourthPlaceHolder,
    fourthFieldErrorMessage,
    formSecondHeader,
    fifthPlaceHolder,
    fifthFieldErrorMessage,
    sixthPlaceHolder,
    sixthFieldErrorMessage,
    dropDownFields,
    dropDownPlaceHolder,
    secondDropDownFields,
    secondDropDownPlaceHolder,
    dropDownErrorMessage,
    textAreaPlaceHolder,
    genericMissingErrorMessage,
    buttonText,
  }

  const getFormattedHeaderText = headerText => {
    if (!headerText || typeof headerText !== 'string') return ''
    if (isEnglish) return headerText
    let newString = replaceAll(headerText, '«', `«${String.fromCharCode(8239)}`)
    newString = replaceAll(newString, '»', `${String.fromCharCode(8239)}»`)
    return newString
  }

  const formattedHeaderText = getFormattedHeaderText(headerText)

  return (
    <BackgroundMask
      tablet={TabletMask}
      desktop={DesktopMask}
      backgroundColor={beigeBread25}
    >
      <Hidden below="sm" above="lg">
        <Heading
          variant="--heading-1"
          textAlign="center"
          marginBottom="--spacing-03"
        >
          {formattedHeaderText}
        </Heading>
      </Hidden>
      <Hidden above="sm">
        <Heading
          variant="--heading-2"
          paddingX="--spacing-05"
          textAlign="center"
          marginBottom="--spacing-03"
        >
          {formattedHeaderText}
        </Heading>
      </Hidden>
      <Box
        marginBottom={['--spacing-04', '--spacing-07']}
        paddingX={['--spacing-03', 0]}
        maxWidth={['100%', '472px']}
        marginX={['none', 'auto']}
      >
        <Hidden above="lg">
          <Deck
            className="structured-text"
            variant="--deck-regular"
            textAlign="center"
          >
            <StructuredText data={structuredText} />
          </Deck>
        </Hidden>
      </Box>
      <Grid>
        <Grid.Row>
          <Grid.Col xs={0} lg={6}>
            <RelativeContainer>
              <TopAccentImage src={firstPlant.url} alt="" role="presentation" />
              {secondPlant && (
                <MiddleAccentImage
                  src={secondPlant.url}
                  alt=""
                  role="presentation"
                />
              )}
              {formSuccess === false && (
                <BottomAccentImage
                  src={thirdPlant.url}
                  alt=""
                  role="presentation"
                />
              )}
              <Box
                maxWidth={isEnglish ? '378px' : '530px'}
                margin="auto"
                paddingY="--spacing-05"
                paddingBottom={formSuccess && '--spacing-10'}
              >
                <Heading
                  variant="--heading-1"
                  textAlign="left"
                  marginBottom="--spacing-03"
                >
                  {formattedHeaderText}
                </Heading>
                <Deck
                  className="structured-text"
                  variant="--deck-regular"
                  textAlign="left"
                >
                  <StructuredText data={structuredText} />
                </Deck>
              </Box>
            </RelativeContainer>
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <HiddenIframe id="hiddenFrame" />
            {formSuccess ? (
              renderFormSuccess(
                cardHeaderText,
                cardParagraphText,
                cardButtonText,
                cardButtonHref,
                location
              )
            ) : (
              <LeadFormCard
                leadForm={leadForm}
                location={location}
                formType="long"
                setFormSuccess={setFormSuccess}
              />
            )}
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </BackgroundMask>
  )
}

BlockLeadForm.defaultProps = {
  headerText: 'Get in touch with our sales team',
  formName: 'salesforce',
  firstPlant: { url: '' },
  secondPlant: { url: '' },
  thirdPlant: { url: '' },
  formFirstHeader: 'Your contact information',
  firstPlaceHolder: 'First Name',
  firstFieldErrorMessage: 'Input is too long!',
  secondPlaceHolder: 'Last Name',
  secondFieldErrorMessage: 'Input is too long!',
  thirdPlaceHolder: 'Email address',
  thirdFieldErrorMessage: 'Input is too long!',
  fourthPlaceHolder: 'Phone number',
  fourthFieldErrorMessage: 'Input is too long!',
  formSecondHeader: 'Your business information',
  fifthPlaceHolder: 'Company',
  fifthFieldErrorMessage: 'Input is too long!',
  sixthPlaceHolder: 'Website',
  sixthFieldErrorMessage: 'Input is too long!',
  dropDownFields: [
    {
      id: 'DatoCmsDropdownField-4496493-en-CA',
      text: 'Between $500,000 and $1 Million',
      value: 'Between $500,000 and $1 Million',
    },
    {
      id: 'DatoCmsDropdownField-4496494-en-CA',
      text: 'Between $1 Million and $2 Million',
      value: 'Between $1 Million and $2 Million',
    },
    {
      id: 'DatoCmsDropdownField-4496495-en-CA',
      text: 'Between $2 Million and $5 Million',
      value: 'Between $2 Million and $5 Million',
    },
    {
      id: 'DatoCmsDropdownField-4496496-en-CA',
      text: 'Between $5 Million and $10 Million',
      value: 'Between $5 Million and $10 Million',
    },
    {
      id: 'DatoCmsDropdownField-4496497-en-CA',
      text: 'Between $10 Million and $25 Million',
      value: 'Between $10 Million and $25 Million',
    },
    {
      id: 'DatoCmsDropdownField-4496498-en-CA',
      text: 'Between $25 Million and $50 Million',
      value: 'Between $50 Million and $100 Million',
    },
    {
      id: 'DatoCmsDropdownField-4496499-en-CA',
      text: 'Over $100 Million',
      value: 'Over $100 Million',
    },
  ],
  dropDownPlaceHolder: 'Annual revenue',
  textAreaPlaceHolder: 'How can we help? (optional)',
  genericMissingErrorMessage: 'Field is required',
  buttonText: 'Get in touch',
  cardHeaderText: 'Sent',
  cardParagraphText:
    'Thank you for your interest in PayBright! A sales representative will get in touch with you shortly.',
  cardButtonText: 'Go home',
  cardButtonHref: '#',
}

BlockLeadForm.propTypes = {
  headerText: PropTypes.string,
  structuredText: PropTypes.shape({
    value: PropTypes.object,
  }),
  firstPlant: PropTypes.shape({
    url: PropTypes.string,
  }),
  secondPlant: PropTypes.shape({
    url: PropTypes.string,
  }),
  thirdPlant: PropTypes.shape({
    url: PropTypes.string,
  }),
  formFirstHeader: PropTypes.string,
  firstPlaceHolder: PropTypes.string,
  firstFieldErrorMessage: PropTypes.string,
  secondPlaceHolder: PropTypes.string,
  secondFieldErrorMessage: PropTypes.string,
  thirdPlaceHolder: PropTypes.string,
  thirdFieldErrorMessage: PropTypes.string,
  fourthPlaceHolder: PropTypes.string,
  fourthFieldErrorMessage: PropTypes.string,
  formSecondHeader: PropTypes.string,
  fifthPlaceHolder: PropTypes.string,
  fifthFieldErrorMessage: PropTypes.string,
  sixthPlaceHolder: PropTypes.string,
  sixthFieldErrorMessage: PropTypes.string,
  dropDownFields: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    value: PropTypes.string,
  }),
  dropDownPlaceHolder: PropTypes.string,
  dropDownErrorMessage: PropTypes.string,
  textAreaPlaceHolder: PropTypes.string,
  genericMissingErrorMessage: PropTypes.string,
  buttonText: PropTypes.string,
  cardHeaderText: PropTypes.string,
  cardParagraphText: PropTypes.string,
  cardButtonText: PropTypes.string,
  cardButtonHref: PropTypes.string,
  location: PropTypes.object,
}

export default BlockLeadForm
