import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'
import Grid from '@pbds/grid'
import Box from '@pbds/box'
import { imageShape, imageDefaultProps } from '../../shared/proptypes'

const { Row, Col } = Grid

const StyledImg = styled(Img)`
  width: 100%;
  max-width: 154px;
  margin-bottom: ${({ theme }) => theme.space['--spacing-07']};
`

const BlockAwardsBanner = ({ awards }) => (
  <Box as="section" paddingTop="--spacing-07">
    <Grid>
      <Row horizontalAlign="center">
        <Col xs={0} lg={2} />
        {awards &&
          awards.map(award => (
            <Col
              xs={6}
              sm="auto"
              key={award.awardImage.fluid.src}
              horizontalAlign="center"
            >
              <Box flexAlign="middleCenter">
                <StyledImg
                  fluid={award.awardImage.fluid}
                  alt={award.awardImage.alt}
                />
              </Box>
            </Col>
          ))}
        <Col xs={0} lg={2} />
      </Row>
    </Grid>
  </Box>
)

BlockAwardsBanner.defaultProps = {
  awards: [{ awardImage: imageDefaultProps }],
}

BlockAwardsBanner.propTypes = {
  awards: PropTypes.arrayOf({
    awardImage: imageShape,
  }),
}

export default BlockAwardsBanner
