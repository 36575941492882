import React from 'react'

const GrayCircle = ({ className }) => {
  return (
    <svg
      className={className}
      width="143"
      height="143"
      viewBox="0 0 143 143"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <circle cx="71.3859" cy="71.5739" r="71.2746" fill="#F2ECE4" />
    </svg>
  )
}

export default GrayCircle
