import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@pbds/grid'
import Box from '@pbds/box'
import { imageShape, imageDefaultProps } from '../../../shared/proptypes'
import VectorLine from '../../../svgs/VectorLine'
import PaybrightLogo from '../../../svgs/PaybrightLogo'
import { MAX_SCREEN_WIDTH } from '../../../shared/constants'

const { Row, Col } = Grid

const setFlexLocation = isLogoLeftAligned =>
  isLogoLeftAligned ? 'middleLeft' : 'middleCenter'

const LockupVariationLogo = ({ image }) => (
  <>
    <VectorLine />
    <Box marginLeft="--spacing-02">
      <img src={image.url} alt={image.alt} />
    </Box>
  </>
)

const BareMinimumHeader = ({ isLogoLeftAligned, location, image }) => {
  return (
    <Box
      maxWidth={MAX_SCREEN_WIDTH}
      marginX="auto"
      flexAlign={['middleLeft']}
      paddingX={[
        '--spacing-03',
        '--spacing-03',
        '--spacing-04',
        '--spacing-04',
        '--spacing-07',
      ]}
    >
      <Grid margin={false} gutter={false} limitWidth={false}>
        <Row verticalAlign="middle">
          <Col>
            <Box
              flexAlign={[
                'middleCenter',
                'middleCenter',
                'middleCenter',
                setFlexLocation(isLogoLeftAligned),
              ]}
              marginTop={['--spacing-01']}
            >
              <Box flexAlign="middleCenter" flexDirection="row">
                <Box marginRight="--spacing-02">
                  <PaybrightLogo location={location} />
                </Box>

                {image && <LockupVariationLogo image={image} />}
              </Box>
            </Box>
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}

BareMinimumHeader.defaultProps = {
  isLogoLeftAligned: false,
  image: imageDefaultProps,
}

BareMinimumHeader.propTypes = {
  isLogoLeftAligned: PropTypes.bool,
  image: imageShape,
}

export default BareMinimumHeader
