import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledIframe = styled.iframe`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  z-index: -1;
`

const HiddenIframe = ({ id }) => (
  <StyledIframe src="about:blank" tabindex="-1" name={id} id={id} />
)

HiddenIframe.propTypes = {
  id: PropTypes.string,
}

export default HiddenIframe
