import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import Carousel from 'react-multi-carousel'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Box from '@pbds/box'
import Grid from '@pbds/grid'
import Card from '@pbds/card'
import Typography from '@pbds/typography'
import ChecklistItem from '@pbds/checklist-item'
import theme from '@pbds/theme'
import { mintSprig10 } from '@pbds/core-colors'
import { imageShape, imageDefaultProps } from '../../shared/proptypes'
import { MOVING_CAROUSEL_CONFIG_SLOW } from '../../shared/carousel_config'
import { MAX_SCREEN_WIDTH } from '../../shared/constants'

const { Row, Col } = Grid
const { Heading, Deck } = Typography

const sm = parseInt(theme.breakpoints.sm)

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: sm,
    },
    items: 3,
  },
  mobile: {
    breakpoint: {
      max: sm,
      min: 0,
    },
    items: 2,
  },
}

const CarouselBox = styled(Box)`
  position: relative;
`

const StyledImg = styled(Img)`
  height: 160px;
  border-radius: ${theme.radii['--corner-radius-8']};
  @media screen and (min-width: ${theme.breakpoints.md}) {
    height: 192px;
  }
  @media screen and (min-width: ${theme.breakpoints.lg}) {
    height: 372px;
  }
`

const StyledCarousel = styled(Carousel)(
  ({ theme }) => `
  position: relative;
  top: -${theme.space['--spacing-07']};
  @media screen and (min-width: ${theme.breakpoints.md}) {
    top: -${theme.space['--spacing-07']};
  }
  @media screen and (min-width: ${theme.breakpoints.lg}) {
    top: 0;
  }
  
`
)

const StyledIcon = styled(Box)`
  border-radius: 100%;
  img {
    width: 100%;
  }
`

const Edge = styled('div')`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100px;
  z-index: 1;
  background: linear-gradient(
    to ${({ side }) => side},
    rgba(255, 255, 255, 0),
    white
  );
  ${({ side }) => side}: -2px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    display: block;
  }
`

const RenderSide = ({ icon, headerText, checkItems, isFirst }) => (
  <Col
    xs={10}
    xsOffset={1}
    sm={8}
    smOffset={2}
    lg={5}
    lgOffset={isFirst ? 1 : 0}
  >
    <Box paddingX={[0, 0, '--spacing-07']}>
      <StyledIcon
        height="100px"
        width="100px"
        backgroundColor="white"
        marginTop={['--spacing-07', '--spacing-07', '--spacing-07', '56px']}
      >
        <img src={icon.url} alt={icon.alt} />
      </StyledIcon>
      <Heading variant="--heading-3" paddingY="--spacing-04">
        {headerText}
      </Heading>
      {checkItems.map((item, i) => (
        <Box marginBottom={i === checkItems.length - 1 ? 0 : '--spacing-04'}>
          <ChecklistItem labelText={item.text} />
        </Box>
      ))}
    </Box>
  </Col>
)

const BlockOurWorkCulture = ({
  headerText,
  paragraphText,
  leftIcon,
  leftHeaderText,
  leftChekmarks: leftCheckmarks,
  rightIcon,
  rightHeaderText,
  rightCheckmarks,
  images,
}) => (
  <Box as="section">
    <Box
      backgroundColor={mintSprig10}
      paddingTop={['--spacing-07', '--spacing-08']}
    >
      <Grid>
        <Row>
          <Col sm={12} lg={10} lgOffset={1}>
            <Card>
              <Box
                paddingX={['--spacing-04', '--spacing-07']}
                paddingY={['--spacing-07', '56px', '--spacing-07']}
              >
                <Heading variant="--heading-2" marginBottom="--spacing-03">
                  {headerText}
                </Heading>
                <Deck variant="--deck-regular">{paragraphText}</Deck>
              </Box>
            </Card>
          </Col>
        </Row>
      </Grid>
      <Box paddingBottom="6rem">
        <Grid>
          <Row>
            <RenderSide
              icon={leftIcon}
              headerText={leftHeaderText}
              checkItems={leftCheckmarks}
              isFirst={true}
            />
            <RenderSide
              icon={rightIcon}
              headerText={rightHeaderText}
              checkItems={rightCheckmarks}
              isFirst={false}
            />
          </Row>
        </Grid>
      </Box>
    </Box>
    <CarouselBox
      maxWidth={MAX_SCREEN_WIDTH}
      margin="0 auto"
      marginTop={[0, 0, 0, '--spacing-07']}
      as="section"
    >
      <Edge side="left" />
      <StyledCarousel responsive={responsive} {...MOVING_CAROUSEL_CONFIG_SLOW}>
        {images.map(image => (
          <Box marginRight="--spacing-04">
            <StyledImg
              fluid={image.fluid}
              alt={image.alt}
              key={image.fluid.src}
            />
          </Box>
        ))}
      </StyledCarousel>
      <Edge side="right" />
    </CarouselBox>
  </Box>
)
export default BlockOurWorkCulture

BlockOurWorkCulture.propTypes = {
  headerText: PropTypes.string,
  paragraphText: PropTypes.string,
  leftIcon: {
    url: PropTypes.string,
    alt: PropTypes.string,
  },
  leftHeaderText: PropTypes.string,
  leftChekmarks: PropTypes.arrayOf({ text: PropTypes.string }),
  rightIcon: {
    url: PropTypes.string,
    alt: PropTypes.string,
  },
  rightHeaderText: PropTypes.string,
  rightCheckmarks: PropTypes.arrayOf({ text: PropTypes.string }),
  images: PropTypes.arrayOf({ image: imageShape }),
}
BlockOurWorkCulture.defaultProps = {
  headerText: '',
  paragraphText: '',
  leftIcon: {
    url: '',
    alt: '',
  },
  leftHeaderText: '',
  leftChekmarks: [{ text: '' }],
  rightIcon: {
    url: '',
    alt: '',
  },
  rightHeaderText: '',
  rightCheckmarks: [{ text: '' }],
  images: [imageDefaultProps],
}
