import React from 'react'
import Grid from '@pbds/grid'
import Typography from '@pbds/typography'
import Switch from '@pbds/switch'
import Card from '@pbds/card'
import { FilterWrapper } from '../styles'

const { Row, Col } = Grid
const { Body } = Typography

const FilterSection = ({
  filterText,
  stickyRef,
  isSticky,
  toggleFilter,
  filterByPromotion,
}) => (
  <Row>
    <Col>
      <FilterWrapper
        ref={stickyRef}
        isSticky={isSticky}
        maxWidth={['100%', '100%', '472px', '472px', '276px']}
      >
        <Card
          paddingY={[10, 10, '--spacing-03', '--spacing-03', '--spacing-02']}
          paddingX="--spacing-03"
          elevation="--elevation-regular-3"
        >
          <Grid gutter={false} margin={false}>
            <Row verticalAlign="middle">
              <Col xl={9}>
                <Body variant="--body-01-semi-emphasized">{filterText}</Body>
              </Col>
              <Col xl={3} horizontalAlign="end">
                <Switch
                  name="Promotion"
                  onChange={toggleFilter}
                  checked={filterByPromotion}
                />
              </Col>
            </Row>
          </Grid>
        </Card>
      </FilterWrapper>
    </Col>
  </Row>
)

export default FilterSection
