import React from 'react'

import SidebarMenu from '@pbds/sidebar-menu'
import Box from '@pbds/box'
import Divider from '@pbds/divider'
import Link from '@pbds/link'
import { Link as GatsbyLink } from 'gatsby'
import { PrimaryButton } from '@pbds/buttons'

import LanguageSelector from '../LanguageSelector'
import {
  StyledUL,
  NavLink,
  StyledBody,
  DividerWrapper,
  ButtonWrapper,
  StyledBox,
} from './ComponentStyled'

import triggerGtmEvent from '../helpers'

const getMenuItems = (items, from, to) => items.slice(from, to)

const SideNav = ({
  handleClose,
  open,
  menuItems,
  buttonText,
  buttonHref,
  rightLinkText,
  rightLinkHref,
  location,
}) => {
  const params = location && location.search
  const topMenuItems = getMenuItems(menuItems, 0, 4)
  const bottomMenuItems = getMenuItems(menuItems, 4)
  return (
    <div>
      <SidebarMenu
        handleClose={handleClose}
        open={open}
        headerContent={
          <Box marginRight="auto">
            <LanguageSelector />
          </Box>
        }
        bodyContent={
          <>
            <Box
              marginX={['--spacing-02', '12px']}
              marginTop="--spacing-02"
              marginBottom="--spacing-02"
            >
              <StyledUL>
                {topMenuItems.map(item => {
                  const isInternalLink = item.linkHref.startsWith('/')
                  return (
                    <NavLink key={item.linkText}>
                      <Link
                        reactRouterLinkComponent={isInternalLink && GatsbyLink}
                        target={isInternalLink ? '_self' : '_blank'}
                        to={item.linkHref + params}
                      >
                        <StyledBody variant="--body-01-emphasized">
                          {item.linkText}
                        </StyledBody>
                      </Link>
                    </NavLink>
                  )
                })}
                <Box
                  marginBottom="--spacing-02"
                  marginX={['--spacing-02', '12px']}
                >
                </Box>
                {bottomMenuItems.map(item => {
                  const isInternalLink = item.linkHref.startsWith('/')
                  return (
                    <NavLink key={item.linkText}>
                      <Link
                        reactRouterLinkComponent={isInternalLink && GatsbyLink}
                        target={isInternalLink ? '_self' : '_blank'}
                        to={item.linkHref + params}
                      >
                        <StyledBody variant="--body-01-emphasized">
                          {item.linkText}
                        </StyledBody>
                      </Link>
                    </NavLink>
                  )
                })}
              </StyledUL>
            </Box>
            <DividerWrapper
              marginBottom="--spacing-05"
              marginX={['--spacing-03', '--spacing-04']}
            >
              <Divider />
            </DividerWrapper>
            <ButtonWrapper
              flexAlign="middleCenter"
              flexDirection="column"
              marginX={['--spacing-03', '--spacing-04']}
            >
              <PrimaryButton
                buttonType="a"
                target="_blank"
                href={buttonHref}
                buttonSize="--large-button"
                fullWidthOnMobile
              >
                {buttonText}
              </PrimaryButton>

              <StyledBox marginY="--spacing-05">
                <Link
                  onClick={() => triggerGtmEvent('B2C')}
                  target="_blank"
                  to={rightLinkHref}
                >
                  <StyledBody variant="--body-01-emphasized">
                    {rightLinkText}
                  </StyledBody>
                </Link>
              </StyledBox>
            </ButtonWrapper>
          </>
        }
      />
    </div>
  )
}

export default SideNav
