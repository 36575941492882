import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'

import Grid from '@pbds/grid'
import Box from '@pbds/box'
import Typography from '@pbds/typography'
import { imageShape, imageDefaultProps } from '../../shared/proptypes'

const { Row, Col } = Grid
const { Heading, Deck } = Typography

export const GatsbyImageHolder = styled(Box)`
  > div {
    width: 100%;
  }
`
export const SideImage = ({ image, position }) => {
  const posProps =
    position === 'top'
      ? {
          flexAlign: 'topCenter',
          paddingTop: ['0', '0', '--spacing-05', '0', '0'],
        }
      : {
          flexAlign: 'bottomCenter',
          paddingBottom: ['0', '0', '--spacing-05', '0', '0'],
        }
  return (
    <GatsbyImageHolder
      minHeight={['auto', 'auto', 'auto', '15rem']}
      height="100%"
      {...posProps}
    >
      <Img fluid={image.fluid} alt={image.alt} style={{ maxWidth: '100%' }} />
    </GatsbyImageHolder>
  )
}

SideImage.propTypes = {
  image: imageShape,
  position: PropTypes.oneOf(['top', 'bottom']),
}

SideImage.defaultProps = {
  image: imageDefaultProps,
  position: 'top',
}

const BlockCenteredSmallTextBanner = ({
  headerText,
  firstParagraphText,
  secondParagraphText,
  leftImage,
  rightImage,
}) => {
  return (
    <Box as="section">
      <Grid>
        <Row horizontalAlign="center">
          <Col xs={6} md={3} lg={2} lgOffset={0}>
            {leftImage.fluid && leftImage.fluid.src && (
              <SideImage image={leftImage} position="top" />
            )}
          </Col>
          <Col xs={12} lgOffset={0} lg={6}>
            <Box
              flexAlign="middleCenter"
              height="100%"
              paddingTop={[
                '--spacing-04',
                '--spacing-05',
                '--spacing-05',
                '--spacing-07',
              ]}
              paddingBottom={['0', '--spacing-07']}
            >
              <Heading variant="--heading-3" marginBottom="--spacing-03">
                {headerText}
              </Heading>
              <Deck variant="--deck-regular" marginBottom="--spacing-04">
                {firstParagraphText}
              </Deck>
              <Deck variant="--deck-regular" marginBottom="--spacing-04">
                {secondParagraphText}
              </Deck>
            </Box>
          </Col>
          <Col xs={0} lg={2}>
            {rightImage.fluid && rightImage.fluid.src && (
              <SideImage image={rightImage} position="bottom" />
            )}
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}

BlockCenteredSmallTextBanner.defaultProps = {
  headerText: '',
  firstParagraphText: '',
  secondParagraphText: '',
  leftImage: imageDefaultProps,
  rightImage: imageDefaultProps,
}

BlockCenteredSmallTextBanner.propTypes = {
  headerText: PropTypes.string,
  firstParagraphText: PropTypes.string,
  secondParagraphText: PropTypes.string,
  leftImage: imageShape,
  rightImage: imageShape,
}

export default BlockCenteredSmallTextBanner
