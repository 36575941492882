import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { ThemeContext } from 'styled-components'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { REGULAR_CAROUSEL_CONFIG } from '../../shared/carousel_config'

import { CarouselStyleBox } from './ComponentsStyled'

const getResponsiveProperties = theme => {
  const lg = parseInt(theme.breakpoints.lg)
  const sm = parseInt(theme.breakpoints.sm)

  return {
    desktop: {
      breakpoint: {
        max: 3000,
        min: lg,
      },
      items: 1,
    },
    tablet: {
      breakpoint: {
        max: lg,
        min: sm,
      },
      items: 1,
    },
    mobile: {
      breakpoint: {
        max: sm,
        min: 0,
      },
      items: 1,
    },
  }
}

const ElementCarouselRegular = ({
  children,
  removeArrowOnDeviceType,
  ...rest
}) => {
  const theme = useContext(ThemeContext)
  const responsive = getResponsiveProperties(theme)
  return (
    <CarouselStyleBox {...rest}>
      <Carousel
        responsive={responsive}
        {...REGULAR_CAROUSEL_CONFIG}
        removeArrowOnDeviceType={removeArrowOnDeviceType}
      >
        {children}
      </Carousel>
    </CarouselStyleBox>
  )
}

ElementCarouselRegular.propTypes = {
  removeArrowOnDeviceType: PropTypes.array,
}

ElementCarouselRegular.defaultProps = {
  removeArrowOnDeviceType: [],
}

export default ElementCarouselRegular
