import styled from 'styled-components'

export const ResponsiveMediaWrapper = styled.div(
  ({ theme, backgroundImageURL }) => `
  position: relative;
  border-radius: ${theme.radii['--corner-radius-8']};
  overflow:hidden;
  padding-top: 56.25%;
  .has-siblings & {
      padding-top:44.25%
  }
  width:100%;
  min-height: 439px;
  > div {
	  position: absolute;
	  top: 0;
	  left: 0;
	  background-size: cover;
	  background-position: center;
	  background-image: url(${backgroundImageURL});
  }
  &:not(.media-is-loaded) {
	  iframe {
		  opacity: 0.3;
	  }
  }
  .react-player__preview:focus {
	  outline: none;
	  img {
	      opacity: 1;
	  }
  }
  .finished {
	  react-player__preview {
		  pointer-events: none;
		  a {
			  pointer-events: all;
		  }
	  }
  }
`
)
