import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import BlockLoader from '../components/shared/BlockLoader'

const GenericPage = ({ pageContext, location }) => {
  const { blocks, seo, lang, announcementsBanner, footer, header } = pageContext
  return (
    <Layout
      lang={lang}
      seo={seo}
      announcementsBanner={announcementsBanner}
      footer={footer}
      header={header}
      location={location}
    >
      {blocks.map(block => (
        <BlockLoader
          lang={lang}
          key={block.id}
          {...block}
          location={location}
        />
      ))}
    </Layout>
  )
}

GenericPage.defaultProps = {
  pageContext: {
    blocks: [],
    seo: {},
    lang: 'en',
    footer: {},
  },
}

GenericPage.propTypes = {
  pageContext: PropTypes.shape({
    blocks: PropTypes.arrayOf(PropTypes.object),
    seo: PropTypes.object,
    lang: PropTypes.string,
    footer: PropTypes.object,
  }),
}

export default GenericPage
