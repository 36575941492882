import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'

import Grid from '@pbds/grid'
import Typography from '@pbds/typography'
import Box from '@pbds/box'

import { imageShape, imageDefaultProps } from '../../shared/proptypes'

const { Row, Col } = Grid
const { Heading, Caption } = Typography

const ImageHolderStyled = styled(Img)(
  ({ theme }) => `
  border-radius: ${theme.radii['--corner-radius-8']};
  margin-bottom: ${theme.space['--spacing-03']}`
)

const TeamMemberCol = ({ name, title, image }, index) => {
  const leftCol = index % 2 === 0
  return (
    <Col xs={6} md={3} xl={2}>
      <Box
        maxWidth={['10rem', '10rem', 'initial']}
        marginLeft={leftCol ? 'auto' : ''}
        marginRight={!leftCol ? 'auto' : ''}
      >
        <Box paddingBottom="--spacing-06">
          <ImageHolderStyled fluid={image.fluid} alt={image.alt} />
          <Caption variant="--caption-semi-emphasized">{name}</Caption>
          <Caption>{title}</Caption>
        </Box>
      </Box>
    </Col>
  )
}

const BlockLeadershipTeam = ({ headerText, leaders }) => (
  <Box as="section">
    <Grid>
      <Row>
        <Col xsOffset={1} xs={10}>
          <Heading
            variant="--heading-2"
            textAlign={['center', 'center', 'center', 'left']}
            marginBottom={['--spacing-04', '--spacing-04', '--spacing-07']}
          >
            {headerText}
          </Heading>
          <Grid margin={false}>
            <Row>{leaders.map(TeamMemberCol)}</Row>
          </Grid>
        </Col>
      </Row>
    </Grid>
  </Box>
)

BlockLeadershipTeam.defaultProps = {
  headerText: '',
  leaders: [
    {
      name: '',
      title: '',
      image: imageDefaultProps,
    },
  ],
}

BlockLeadershipTeam.propTypes = {
  headerText: PropTypes.string,
  leaders: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
      image: imageShape,
    })
  ),
}
export default BlockLeadershipTeam
