import React from 'react'

const VectorLine = () => (
  <svg
    width="2"
    height="30"
    viewBox="0 0 2 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.31823 0.0895996H0.87326V29.1985H1.31823V0.0895996Z"
      fill="#1F1E4E"
    />
  </svg>
)

export default VectorLine
