import React from 'react'
import Grid from '@pbds/grid'
import { PrimaryButton } from '@pbds/buttons'
import Box from '@pbds/box'
import { ShowMoreButtonWrapper } from '../styles'

const { Row, Col } = Grid

const shouldShowButton = (merchants, numberOfReturnedMerchants) => {
  // this is a condition where the number of items are complete,
  // example: 12, 24,
  // next time you click on show more it won't return any items therefore we hide it
  if (merchants.length % 12 === 0 && numberOfReturnedMerchants === 0) {
    return false
  }

  if (merchants.length % 12 === 0) {
    return true
  }

  return false
}

const ShowMoreButton = ({
  numberOfReturnedMerchants,
  showMoreButtonText,
  merchants,
  isLoading,
  showMore,
}) =>
  shouldShowButton(merchants, numberOfReturnedMerchants) && (
    <ShowMoreButtonWrapper>
      <Row>
        <Col horizontalAlign="center">
          <Box margin="auto" maxWidth={180}>
            <PrimaryButton
              buttonSize="--large-button"
              disabled={isLoading}
              isLoading={isLoading}
              onClick={showMore}
            >
              {showMoreButtonText}
            </PrimaryButton>
          </Box>
        </Col>
      </Row>
    </ShowMoreButtonWrapper>
  )

export default ShowMoreButton
