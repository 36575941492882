import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import styled, { keyframes, css } from 'styled-components'
import * as postMessager from '../../../../utils/messager'
import { PrimaryButton } from '@pbds/buttons'
import Typography from '@pbds/typography'
import Select from '@pbds/select'
import Input from '@pbds/input'
import Card from '@pbds/card'
import Box from '@pbds/box'
import { useInput } from '../../shared/hooks/useInput'
import verifyRecaptcha from '../../../../utils/verifyRecaptcha'

// above and below prop take [sm, md, lg, xl] breakpoint arguments
const Hidden = styled.div`
  ${({ theme, above, below }) => `
      @media screen and (min-width: ${theme.breakpoints[above]}) { display: none; }
      @media screen and (max-width: ${theme.breakpoints[below]}) { display: none; }
    `}
`

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translatey(1rem);
  }
  to{
      opacity: 1;
      transform: translatey(0);
  }
`

const getAnimation = (keyframe, ms, delay) => css`
  ${keyframe} ${ms}ms  ${delay}ms forwards ease-out
`

const StyledForm = styled('form')(
  () => css`
    opacity: 0;
    animation: ${getAnimation(fadeIn, 500, 0)};
  `
)

const filterDropDownEmptyFields = dropDownFields =>
  Array.isArray(dropDownFields)
    ? dropDownFields.filter(field => field.text)
    : []

const LeadFormCard = ({
  leadForm: {
    caslComplianceText,
    formName,
    formFirstHeader,
    firstPlaceHolder,
    firstFieldErrorMessage,
    secondPlaceHolder,
    secondFieldErrorMessage,
    thirdPlaceHolder,
    thirdFieldErrorMessage,
    fourthPlaceHolder,
    fourthFieldErrorMessage,
    formSecondHeader,
    fifthPlaceHolder,
    fifthFieldErrorMessage,
    sixthPlaceHolder,
    sixthFieldErrorMessage,
    dropDownFields,
    dropDownPlaceHolder,
    secondDropDownFields,
    secondDropDownPlaceHolder,
    textAreaPlaceHolder,
    buttonText,
  },
  location,
  formType,
  setFormSuccess,
}) => {
  // validation functions
  const maxLength40 = value => value.length > 40
  const maxLength80 = value => value.length > 80

  const { bindInput: bindFirstName } = useInput('', maxLength40)
  const { bindInput: bindLastName } = useInput('', maxLength80)
  const { bindInput: bindEmail } = useInput('', maxLength80)
  const { bindInput: bindPhoneNumber } = useInput('', maxLength40)
  const { bindInput: bindCompany } = useInput('', maxLength40)
  const { bindInput: bindWebsite } = useInput('', maxLength80)
  const [selectValue, setSelectValue] = useState('')
  const [industryValue, setIndustryValue] = useState('')
  const filteredDropDownFields = filterDropDownEmptyFields(dropDownFields)
  const filteredSecondDropDownFields = filterDropDownEmptyFields(
    secondDropDownFields
  )
  const [textAreaValue, setTextAreaValue] = useState('')
  const [isSubmitEnabled, setSubmitEnabled] = useState(false)
  const [recaptchaResponse, setRecaptchaResponse] = useState('')
  const [loadForm, setLoadForm] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  // account for SSR
  const currentUrl = location.href ? location.href : ''
  const currentPageWithParams = currentUrl ? currentUrl.split('/').pop() : ''
  const currentPageSplitAtQuery = currentPageWithParams.split('?')
  const currentPage = currentPageSplitAtQuery[0]
  const utm_info = currentPageSplitAtQuery[1] || ''
  // if we add a second form endpoint, this can be moved to the CMS
  const formVendor = 'salesforce'
  const queryParams = `crm=${formVendor}&form=${formName}&company=${bindCompany.value ||
    'form_missing_company'}`
  const redirectHref = location.search
    ? currentUrl + `&${queryParams}`
    : currentUrl + `?${queryParams}`
  const formActionUrl =
    'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8'

  useEffect(() => {
    setLoadForm(true)
  }, [])

  const handleRecaptchaChange = (val = '') => {
    const successVerfified = Boolean(val)
    setSubmitEnabled(successVerfified)
    setRecaptchaResponse(val)
  }

  const recaptchaSize = () =>
    typeof window !== 'undefined' && window.innerWidth < 600
      ? 'compact'
      : 'normal'
  const formEl = useRef(null)
  const { pathname } = location
  const lang = pathname.slice(1, 3)

  const handleFormSubmit = async event => {
    event.preventDefault()
    setFormSubmitted(true)
    try {
      const {
        data: { success: isHuman },
      } = await verifyRecaptcha({ recaptchaResponse })
      isHuman && formEl.current.submit()
    } catch (error) {
      console.error(error)
    }
  }
  const shortForm = () => (
    <>
      <Box marginBottom={['--spacing-01', '--spacing-02', '--spacing-03']}>
        <Hidden above="sm">
          <Typography.Heading
            variant="--heading-4"
            paddingBottom="--spacing-03"
          >
            {formFirstHeader}
          </Typography.Heading>
        </Hidden>
        <Hidden below="sm">
          <Typography.Heading
            variant="--heading-3"
            paddingBottom="--spacing-03"
          >
            {formFirstHeader}
          </Typography.Heading>
        </Hidden>
        <Box paddingBottom={['--spacing-01', '--spacing-02', '--spacing-03']}>
          <Input
            type="text"
            label={firstPlaceHolder}
            name="first_name"
            id="first_name"
            {...bindFirstName}
            errorHelperText={firstFieldErrorMessage}
            required
          />
        </Box>
        <Box paddingBottom={['--spacing-01', '--spacing-02', '--spacing-03']}>
          <Input
            type="text"
            label={secondPlaceHolder}
            name="last_name"
            id="last_name"
            {...bindLastName}
            errorHelperText={secondFieldErrorMessage}
            required
          />
        </Box>
        <Box paddingBottom={['--spacing-01', '--spacing-02', '--spacing-03']}>
          <Input
            type="email"
            label={thirdPlaceHolder}
            name="email"
            id="email"
            {...bindEmail}
            errorHelperText={thirdFieldErrorMessage}
            required
          />
        </Box>
        <Box paddingBottom={['--spacing-01', '--spacing-02', '--spacing-03']}>
          <Input
            type="text"
            label={fifthPlaceHolder}
            name="company"
            id="company"
            {...bindCompany}
            errorHelperText={fifthFieldErrorMessage}
            required
          />
        </Box>
      </Box>
    </>
  )

  const longForm = () => (
    <>
      <Box marginBottom={formSecondHeader ? '--spacing-07' : null}>
        {formFirstHeader && (
          <>
            <Hidden above="sm">
              <Typography.Heading
                variant="--heading-4"
                paddingBottom="--spacing-03"
              >
                {formFirstHeader}
              </Typography.Heading>
            </Hidden>
            <Hidden below="sm">
              <Typography.Heading
                variant="--heading-3"
                paddingBottom="--spacing-03"
              >
                {formFirstHeader}
              </Typography.Heading>
            </Hidden>
          </>
        )}
        {firstPlaceHolder && (
          <Box paddingBottom="--spacing-03">
            <Input
              type="text"
              label={firstPlaceHolder}
              name="first_name"
              id="first_name"
              {...bindFirstName}
              errorHelperText={firstFieldErrorMessage}
              required
            />
          </Box>
        )}
        {secondPlaceHolder && (
          <Box paddingBottom="--spacing-03">
            <Input
              type="text"
              label={secondPlaceHolder}
              name="last_name"
              id="last_name"
              {...bindLastName}
              errorHelperText={secondFieldErrorMessage}
              required
            />
          </Box>
        )}
        <Box marginBottom="--spacing-03">
          {thirdPlaceHolder && (
            <Input
              type="email"
              label={thirdPlaceHolder}
              name="email"
              id="email"
              {...bindEmail}
              errorHelperText={thirdFieldErrorMessage}
              required
            />
          )}
        </Box>
        {fourthPlaceHolder && (
          <Input
            type="tel"
            format="phone"
            label={fourthPlaceHolder}
            name="phone"
            id="phone"
            {...bindPhoneNumber}
            errorHelperText={fourthFieldErrorMessage}
            required
          />
        )}
      </Box>
      {formSecondHeader && (
        <>
          <Hidden above="sm">
            <Typography.Heading
              variant="--heading-4"
              paddingBottom="--spacing-03"
            >
              {formSecondHeader}
            </Typography.Heading>
          </Hidden>
          <Hidden below="md">
            <Typography.Heading
              variant="--heading-3"
              paddingBottom="--spacing-03"
            >
              {formSecondHeader}
            </Typography.Heading>
          </Hidden>
        </>
      )}
      {fifthPlaceHolder && (
        <Box paddingBottom="--spacing-03">
          <Input
            type="text"
            label={fifthPlaceHolder}
            name="company"
            id="company"
            {...bindCompany}
            errorHelperText={fifthFieldErrorMessage}
            required
          />
        </Box>
      )}
      {sixthPlaceHolder && (
        <Box paddingBottom="--spacing-03">
          <Input
            type="text"
            label={sixthPlaceHolder}
            name="website"
            id="website"
            {...bindWebsite}
            errorHelperText={sixthFieldErrorMessage}
            required
          />
        </Box>
      )}
      {secondDropDownPlaceHolder && filteredSecondDropDownFields.length && (
        <Box paddingBottom="--spacing-03">
          <Select
            label={secondDropDownPlaceHolder}
            name="00Nf400000VEnXf"
            id="00Nf400000VEnXf"
            options={filteredSecondDropDownFields}
            value={industryValue}
            onChange={event => setIndustryValue(event.target.value)}
            required
          />
        </Box>
      )}
      {dropDownPlaceHolder && filteredDropDownFields.length && (
        <Box paddingBottom="--spacing-03">
          <Select
            label={dropDownPlaceHolder}
            name="00Nf400000UKcyu"
            id="00Nf400000UKcyu"
            options={filteredDropDownFields}
            value={selectValue}
            onChange={event => setSelectValue(event.target.value)}
            required
          />
        </Box>
      )}
      {textAreaPlaceHolder && (
        <Box paddingBottom="--spacing-05">
          <textarea
            style={{ width: '100%', minHeight: 100 }}
            rows="3"
            placeholder={textAreaPlaceHolder}
            label={textAreaPlaceHolder}
            name="00Nf400000VERXZ"
            id="00Nf400000VERXZ"
            value={textAreaValue}
            onChange={event => setTextAreaValue(event.target.value)}
            maxLength={500}
          />
        </Box>
      )}
    </>
  )

  const resolveFormType = formType =>
    ({
      long: { formUI: longForm, lead_source: currentPage || 'Website' },
      short: {
        formUI: shortForm,
        lead_source: currentPage || 'Marketting Short Form',
      },
    }[formType])

  const { formUI, lead_source } = resolveFormType(formType)

  postMessager.post({ location, setFormSuccess })
  postMessager.listen({ location, setFormSuccess })

  return (
    <Card
      maxWidth={['90vw', '472px', '481px', '481px']}
      elevation="--elevation-regular-3"
      marginX={['auto', 'auto', 'auto', 0]}
      paddingX={[
        '--spacing-03',
        '--spacing-07',
        '--spacing-07',
        '--spacing-07',
      ]}
      paddingY="--spacing-07"
    >
      {loadForm ? (
        <>
          <StyledForm
            action={isSubmitEnabled ? formActionUrl : ''}
            method="POST"
            data-netlify-recaptcha="true"
            data-netlify="true"
            name="lead-form"
            onSubmit={handleFormSubmit}
            ref={formEl}
            target="hiddenFrame"
          >
            <input type="hidden" name="form-name" value="lead-form" />
            <input type="hidden" name="oid" value="00Df4000003j0Fe" />
            <input type="hidden" name="lead_source" value={lead_source} />
            <input type="hidden" name="retURL" value={redirectHref} />
            <input type="hidden" name="description" value={utm_info} />

            {formUI()}
            {process.env.GATSBY_RECAPTCHA_KEY && (
              <Box
                flexAlign="middleCenter"
                marginBottom={['--spacing-02', '--spacing-03', '--spacing-05']}
              >
                <ReCAPTCHA
                  sitekey={process.env.GATSBY_RECAPTCHA_KEY}
                  tabindex={0}
                  hl={lang}
                  onChange={handleRecaptchaChange}
                  size={recaptchaSize()}
                />
              </Box>
            )}
            <Typography.Caption
              variant="--caption-regular"
              paddingBottom="--spacing-03"
            >
              {caslComplianceText}
            </Typography.Caption>
            <PrimaryButton
              id="form_submit"
              type="submit"
              buttonSize="--large-button"
              width="100%"
              disabled={!isSubmitEnabled}
              isLoading={formSubmitted}
            >
              {buttonText}
            </PrimaryButton>
          </StyledForm>
        </>
      ) : (
        <div style={{ minHeight: '1100px' }}></div>
      )}
    </Card>
  )
}

LeadFormCard.propTypes = {
  leadForm: PropTypes.shape({
    caslComplianceText: PropTypes.string,
    formName: PropTypes.string,
    leadformFirstHeader: PropTypes.string,
    firstPlaceHolder: PropTypes.string,
    firstFieldErrorMessage: PropTypes.string,
    secondPlaceHolder: PropTypes.string,
    secondFieldErrorMessage: PropTypes.string,
    thirdPlaceHolder: PropTypes.string,
    thirdFieldErrorMessage: PropTypes.string,
    fourthPlaceHolder: PropTypes.string,
    fourthFieldErrorMessage: PropTypes.string,
    formSecondHeader: PropTypes.string,
    fifthPlaceHolder: PropTypes.string,
    fifthFieldErrorMessage: PropTypes.string,
    sixthPlaceHolder: PropTypes.string,
    sixthFieldErrorMessage: PropTypes.string,
    dropDownFields: PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
    dropDownPlaceHolder: PropTypes.string,
    dropDownErrorMessage: PropTypes.string,
    textAreaPlaceHolder: PropTypes.string,
    genericMissingErrorMessage: PropTypes.string,
    buttonText: PropTypes.string,
    location: PropTypes.object,
  }),
}

export default LeadFormCard
