import styled from 'styled-components'

export const ShadowStyled = styled.div(
  ({ inset }) => `
  position: absolute;
  content: '';
  width: 60%;
  height: 20px;
  left: 20%;
  bottom: ${inset ? '0' : '-20px'};
  border-radius: 100%;
  background-image: radial-gradient(
    rgba(0, 0, 0, 0.5) 5%,
    rgba(0, 0, 0, 0.4) 15%,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0) 70%
  );
  opacity: 0.7;
`
)

export const ImageShadowHolderStyled = styled.div(
  ({ shadowInset }) => `
  position: relative;
  ${!shadowInset ? 'padding-bottom: 20px;' : ''}
`
)
