import PropTypes from 'prop-types'

export const imageShape = PropTypes.shape({
  fluid: PropTypes.shape({
    aspectRatio: PropTypes.number,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    sizes: PropTypes.string,
  }),
  alt: PropTypes.string,
})

export const structuredTextShape = PropTypes.shape({
  value: PropTypes.shape({
    document: PropTypes.shape({
      children: PropTypes.array,
    }),
  }),
})

export const imageFixedShape = PropTypes.shape({
  fixed: PropTypes.shape({
    aspectRatio: PropTypes.number,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    sizes: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
  }),
  alt: PropTypes.string,
})

export const imageDefaultProps = {
  fluid: {
    aspectRatio: 1,
    src:
      'https://www.datocms-assets.com/19671/1583859342-awardslogo2-1.png?auto=format',
    srcSet:
      'https://www.datocms-assets.com/19671/1583859342-awardslogo2-1.png?auto=format&dpr=1&w=156 156w',
    sizes: '(max-width: 800px) 100vw, 800px',
  },
  alt: '',
}

export const imageFixedDefaultProps = {
  fixed: {
    aspectRatio: 1,
    src:
      'https://www.datocms-assets.com/19671/1583859342-awardslogo2-1.png?auto=format',
    srcSet:
      'https://www.datocms-assets.com/19671/1583859342-awardslogo2-1.png?auto=format&dpr=1&w=156 156w',
    sizes: '(max-width: 800px) 100vw, 800px',
    height: '156px',
    width: '156px',
  },
  alt: '',
}
