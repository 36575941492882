import React from 'react'
import PropTypes from 'prop-types'
import Box from '@pbds/box'
import Grid from '@pbds/grid'
import ElementMediaPlayer from '../../elements/ElementMediaPlayer'
import ElementShopCTABanner from '../../elements/ElementShopCTABanner'
import ElementMerchantCTABanner from '../../elements/ElementMerchantCTABanner'
import ElementBackgroundObjectsBanner from '../../elements/ElementBackgroundObjectsBanner'
import { ContainerStyled, StyledFlexContainer } from './ComponentStyled'

const { Row, Col } = Grid

const BlockVideoCampaign = ({
  elementShopCtaBanner,
  elementMerchantCtaBanner,
  elementBackgroundObjectsBanner,
  elementMediaPlayer,
  hasBackground,
  backgroundColor: { hex: backgroundColor },
}) => {
  const withBackground = () => (
    <ElementBackgroundObjectsBanner {...elementBackgroundObjectsBanner}>
      <ElementMediaPlayer {...elementMediaPlayer} />
      {elementMerchantCtaBanner && (
        <ElementMerchantCTABanner {...elementMerchantCtaBanner} />
      )}
    </ElementBackgroundObjectsBanner>
  )

  const withoutBackground = () => (
    <ContainerStyled
      hasBackground={hasBackground}
      backgroundColor={backgroundColor}
    >
      <Grid>
        <Row
          horizontalAlign="center"
          verticalAlign="middle"
          margin={false}
          gutter={false}
        >
          <Col md={12} lg={10}>
            <Box flexAlign="middleCenter">
              <StyledFlexContainer
                className={elementMerchantCtaBanner ? 'has-siblings' : null}
              >
                <ElementMediaPlayer {...elementMediaPlayer} />
                {elementMerchantCtaBanner && (
                  <ElementMerchantCTABanner {...elementMerchantCtaBanner} />
                )}
              </StyledFlexContainer>
            </Box>
          </Col>
        </Row>
        {elementShopCtaBanner && (
          <ElementShopCTABanner {...elementShopCtaBanner} />
        )}
      </Grid>
    </ContainerStyled>
  )

  return (
    <section>
      {elementBackgroundObjectsBanner ? withBackground() : withoutBackground()}
    </section>
  )
}

BlockVideoCampaign.propTypes = {
  elementShopCtaBanner: ElementShopCTABanner.propTypes,
  elementMerchantCtaBanner: ElementMerchantCTABanner.propTypes,
  elementBackgroundObjectsBanner: ElementBackgroundObjectsBanner.propTypes,
  elementMediaPlayer: ElementMediaPlayer.propTypes,
  hasBackground: PropTypes.bool,
  backgroundColor: PropTypes.shape({
    hex: PropTypes.string,
  }),
}

BlockVideoCampaign.defaultProps = {
  elementShopCtaBanner: ElementShopCTABanner.defaultProps,
  elementMerchantCtaBanner: ElementMerchantCTABanner.defaultProps,
  elementBackgroundObjectsBanner: ElementBackgroundObjectsBanner.defaultProps,
  elementMediaPlayer: ElementMediaPlayer.defaultProps,
  hasBackground: false,
  backgroundColor: {
    hex: '#E5E5E5',
  },
}

export default BlockVideoCampaign
