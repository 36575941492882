import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image/withIEPolyfill'
import Grid from '@pbds/grid'
import Card from '@pbds/card'
import { purpleJelly, purpleJelly10, beigeBread25 } from '@pbds/core-colors'
import Typography from '@pbds/typography'
import { imageFixedShape, imageFixedDefaultProps } from '../../shared/proptypes'
import ElementMarqueeAndCarousel from '../../elements/ElementMarqueeAndCarousel'

import { SectionBox } from './ComponentsStyled'

const { Row, Col } = Grid
const { Heading, Body } = Typography

const ReviewCard = ({
  reviewText,
  name,
  backgroundColor,
  textColor,
  offset,
}) => (
  <Card
    padding="--spacing-07"
    marginX={['--spacing-03', '--spacing-03', '--spacing-03', '--spacing-05']}
    backgroundColor={backgroundColor}
    marginBottom={[
      '--spacing-06',
      '--spacing-06',
      '--spacing-06',
      offset === 'bottom' ? '--spacing-08' : '--spacing-03',
    ]}
    marginTop={[
      '--spacing-03',
      '--spacing-03',
      '--spacing-03',
      offset === 'top' ? '--spacing-07' : '--spacing-03',
    ]}
  >
    <Body
      variant="--body-01-semi-emphasized"
      color={textColor}
      marginBottom="--spacing-03"
    >
      {reviewText}
    </Body>
    <Body color={textColor}>{name}</Body>
  </Card>
)

const BlockReviewCarousel = ({
  reviews,
  headerText,
  image,
  reviewBackgroundColor,
  reviewTextColor,
}) => (
  <SectionBox
    margin="0 auto"
    backgroundColor={beigeBread25}
    paddingTop="--spacing-08"
    paddingBottom={[
      '--spacing-06',
      '--spacing-06',
      '--spacing-06',
      '--spacing-04',
    ]}
    as="section"
  >
    {/* Heading  */}
    <Grid>
      <Row>
        <Col smOffset={2} sm={8} lgOffset={3} lg={6} horizontalAlign="center">
          <Img fixed={image.fixed} alt={image.alt} />
          <Heading
            variant="--heading-2"
            textAlign="center"
            marginBottom="--spacing-04"
            marginTop="--spacing-04"
          >
            {headerText}
          </Heading>
        </Col>
      </Row>
    </Grid>

    <ElementMarqueeAndCarousel>
      {reviews.map((review, index) => (
        <ReviewCard
          key={`${review}`}
          backgroundColor={reviewBackgroundColor.hex}
          textColor={reviewTextColor.hex}
          offset={index % 2 === 0 ? 'bottom' : 'top'}
          {...review}
        ></ReviewCard>
      ))}
    </ElementMarqueeAndCarousel>
  </SectionBox>
)

BlockReviewCarousel.propTypes = {
  image: imageFixedShape,
  headerText: PropTypes.string,
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      reviewText: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  reviewBackgroundColor: PropTypes.shape({
    hex: PropTypes.string,
  }),
  reviewTextColor: PropTypes.shape({
    hex: PropTypes.string,
  }),
}

BlockReviewCarousel.defaultProps = {
  image: imageFixedDefaultProps,
  headerText: '',
  reviews: [
    {
      reviewText: '',
      name: '',
    },
  ],
  reviewBackgroundColor: {
    hex: purpleJelly10,
  },
  reviewTextColor: {
    hex: purpleJelly,
  },
}

export default BlockReviewCarousel
