import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Box from '@pbds/box'
import Grid from '@pbds/grid'
import Card from '@pbds/card'
import { PrimaryButton } from '@pbds/buttons'
import Typography from '@pbds/typography'
import { Link } from 'gatsby'
import styled, { ThemeContext } from 'styled-components'
import { beigeBread } from '@pbds/core-colors'
import DarkDesktop from './Images/dark.svg'
import BeigeDesktop from './Images/beige.svg'
import DarkTablet from './Images/tablet-dark.svg'
import BeigeTablet from './Images/tablet-beige.svg'
import LeftPlantDesktop from './Images/left-plant.png'
import RightPlantDesktop from './Images/right-plant.png'
import LeftPlantTablet from './Images/tablet-left-plant.png'
import RightPlantTablet from './Images/tablet-right-plant.png'

const { Row, Col } = Grid
const { Heading } = Typography

const StyledBox = styled(Box)(
  ({ theme, isColorSchemeInverted }) => `
    display: none;

    @media screen and (min-width: ${theme.breakpoints.md}) {
      display: flex;
      background-color: ${
        isColorSchemeInverted ? theme.colors['--background-dark'] : beigeBread
      };
      background-image: url(${
        isColorSchemeInverted ? DarkTablet : BeigeTablet
      });
      h3 {
        margin: 0 32px;
      }
    }

    @media screen and (min-width: ${theme.breakpoints.xl}) {
      background-image: url(${
        isColorSchemeInverted ? DarkDesktop : BeigeDesktop
      });
      justify-content: space-evenly;
      h3 {
        margin: 0 40px;
      }
    }
  `
)

const LeftPlant = styled.div(
  ({ theme }) => `
    min-width: 47px;
    min-height: 70px;
    background-image: url(${LeftPlantTablet});
    background-size: contain;

    @media screen and (min-width: ${theme.breakpoints.xl}) {
      width: 49px;
      min-height: 72px;
      background-image: url(${LeftPlantDesktop});
    }
  `
)

const RightPlant = styled.div(
  ({ theme }) => `
    min-width: 46px;
    min-height: 60px;
    background-image: url(${RightPlantTablet});
    background-size: contain;
    margin-bottom: 32px;
    margin-right: 30px;
    
    @media screen and (min-width: ${theme.breakpoints.xl}) {
      width: 47px;
      min-height: 70px;
      background-image: url(${RightPlantDesktop});
      margin-bottom: 0px;
      margin-right: 42px;
    }
  `
)

const BannerTextHeader = ({ bannerText, isColorSchemeInverted, theme }) => {
  const color = isColorSchemeInverted
    ? theme.colors['--text-light']
    : theme.colors['--text-dark']
  return (
    <Heading variant="--heading-3" color={color} textAlign="left">
      {bannerText}
    </Heading>
  )
}

const ShopCTAButton = ({ isColorSchemeInverted, buttonText, buttonHref }) => (
  <PrimaryButton
    buttonSize="--large-button"
    buttonType={Link}
    useInverseColors={isColorSchemeInverted}
    isFullWidthOnMobile={true}
    to={buttonHref}
  >
    {buttonText}
  </PrimaryButton>
)

const ElementShopCTABanner = ({
  isColorSchemeInverted,
  buttonText,
  buttonHref,
  bannerText,
}) => {
  const theme = useContext(ThemeContext)

  return (
    <>
      <Row
        horizontalAlign="center"
        verticalAlign="middle"
        margin={false}
        gutter={false}
      >
        <Col xs={0} md={12} lg={10}>
          <Card
            marginBottom="--spacing-05"
            paddingX={0}
            elevation="--elevation-regular-3"
          >
            <StyledBox
              isColorSchemeInverted={isColorSchemeInverted}
              flexDirection="row"
              flexAlign="middleCenter"
              paddingY={['--spacing-02', '--spacing-02', '--spacing-03']}
              paddingX={[
                '--spacing-03',
                '--spacing-03',
                '--spacing-06',
                '--spacing-07',
              ]}
              borderRadius="--corner-radius-8"
            >
              <Box flexDirection="row" flexAlign="middleCenter">
                <LeftPlant />
                <BannerTextHeader
                  isColorSchemeInverted={isColorSchemeInverted}
                  bannerText={bannerText}
                  theme={theme}
                />
                <RightPlant />
              </Box>
              <ShopCTAButton
                isColorSchemeInverted={isColorSchemeInverted}
                buttonText={buttonText}
                buttonHref={buttonHref}
              />
            </StyledBox>
          </Card>
        </Col>
      </Row>
      <Row>
        {/* mobile button - always using default colors */}
        <Col md={0}>
          <Box marginBottom="--spacing-05">
            <ShopCTAButton buttonText={buttonText} buttonHref={buttonHref} />
          </Box>
        </Col>
      </Row>
    </>
  )
}

ElementShopCTABanner.propTypes = {
  isColorSchemeInverted: PropTypes.bool,
  bannerText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonHref: PropTypes.string,
}

ElementShopCTABanner.defaultProps = {
  isColorSchemeInverted: false,
  bannerText: '',
  buttonText: '',
  buttonHref: '',
}

export default ElementShopCTABanner
