export const SWIPING_CAROUSEL_CONFIG = {
  swipeable: true,
  draggable: true,
  infinite: false,
  removeArrowOnDeviceType: ['tablet', 'mobile'],
  slidesToSlide: 3,
  transitionDuration: 800,
}

export const MOVING_CAROUSEL_CONFIG = {
  arrows: false,
  autoPlay: true,
  autoPlaySpeed: 1,
  infinite: true,
  transitionDuration: 3000,
  customTransition: 'all 3s linear',
  ssr: true,
  keyBoardControl: false,
}

export const MOVING_CAROUSEL_CONFIG_SLOW = {
  arrows: false,
  autoPlay: true,
  autoPlaySpeed: 1,
  infinite: true,
  transitionDuration: 8000,
  customTransition: 'all 8s linear',
  ssr: true,
  keyBoardControl: false,
}

export const REGULAR_CAROUSEL_CONFIG = {
  arrows: true,
  infinite: false,
  transitionDuration: 100,
  ssr: true,
  showDots: true,
}
