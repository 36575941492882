import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import theme from '@pbds/theme'
import Card from '@pbds/card'
import Grid from '@pbds/grid'
import Typography from '@pbds/typography'
import { PrimaryButton } from '@pbds/buttons'
import Box from '@pbds/box'
import { imageShape, imageDefaultProps } from '../../shared/proptypes'

const { Row, Col } = Grid
const { Heading } = Typography

const BlockCoverImageBanner = styled(Box)`
  background-image: url(${({ backgroundImage }) => backgroundImage.fluid.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
`

const CardWrapper = styled(Box)`
  position: absolute;
  top: ${({ theme }) => theme.space['--spacing-07']};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    top: 125px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    top: 100px;
  }
`

const BlockCard = ({ headerText, buttonText, buttonHref }) => {
  const isInternalLink = buttonHref.startsWith('/')
  return (
    <Card
      backgroundColor={theme.colors['--background-light']}
      paddingX={[
        '--spacing-06',
        '--spacing-06',
        '--spacing-04',
        '--spacing-07',
      ]}
      paddingY="--spacing-07"
    >
      <Heading
        variant="--heading-1"
        as="h1"
        textAlign="center"
        marginX={[0, 0, 0, '--spacing-02']}
        marginTop={[0, 0, 0, '--spacing-02']}
        marginBottom={['--spacing-04', '--spacing-05']}
      >
        {headerText}
      </Heading>
      <Box flexAlign="middleCenter" marginBottom={[0, 0, '--spacing-02']}>
        <PrimaryButton
          to={isInternalLink ? buttonHref : null}
          href={isInternalLink ? null : buttonHref}
          buttonType={isInternalLink ? Link : 'a'}
          target={isInternalLink ? '_self' : '_blank'}
          buttonSize="--large-button"
          rightIcon="rightArrow"
          fullWidthOnMobile={false}
        >
          {buttonText}
        </PrimaryButton>
      </Box>
    </Card>
  )
}

const BlockCardTextCoverImageBanner = ({
  headerText,
  buttonText,
  buttonHref,
  backgroundImage,
}) => {
  const cardProps = {
    headerText,
    buttonText,
    buttonHref,
  }

  return (
    <Box
      height={['338px', '338px', '452px', '459px']}
      backgroundColor={theme.colors['--background-02']}
    >
      <BlockCoverImageBanner
        minHeight={['144px', '144px', '364px', '383px']}
        backgroundImage={backgroundImage}
      >
        <CardWrapper width="100%">
          <Grid>
            <Row>
              <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                <BlockCard {...cardProps}></BlockCard>
              </Col>
            </Row>
          </Grid>
        </CardWrapper>
      </BlockCoverImageBanner>
    </Box>
  )
}

BlockCardTextCoverImageBanner.propTypes = {
  headerText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonHref: PropTypes.string,
  backgroundImage: imageShape,
}

BlockCardTextCoverImageBanner.defaultProps = {
  headerText: '',
  buttonText: '',
  buttonHref: '',
  backgroundImage: imageDefaultProps,
}

export default BlockCardTextCoverImageBanner
