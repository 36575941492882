import gtmEvent from '../../shared/gtmEvent'

export default function triggerGtmEvent(event) {
  gtmEvent({
    event,
    label: event,
    action: event,
    category: event,
    value: event,
  })
}
