import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import Grid from '@pbds/grid'
import Typography from '@pbds/typography'
import PropTypes from 'prop-types'
import Box from '@pbds/box'
import styled from 'styled-components'
import { imageShape, imageDefaultProps } from '../../shared/proptypes'

const { Row, Col } = Grid
const { Heading, Body } = Typography

const AwardContainer = styled(Box)(
  ({ theme }) => `
  position: relative;
  &::after {
    display: block;
    content: '';
    position: absolute;
    height: 2px;
    width: 76px;
    background: ${theme.colors['--border-inactive-01']};
    bottom: ${theme.space['--spacing-04']};
    right: 50%;
    transform: translateX(50%);
    @media screen and (min-width: ${theme.breakpoints.sm}) {
      width: 2px;
      height: 76px;
      right: -16px;
      bottom: 50%;
      transform: translateY(50%);
    }
  }
  &.last {
    &::after {
      display: none;
    }
  }
`
)

const ImageTop = styled(Img)(
  ({ theme }) => `
  position: relative;
  @media screen and (min-width: ${theme.breakpoints.sm}) {
     bottom: ${theme.space['--spacing-04']};
  }
   @media screen and (min-width: ${theme.breakpoints.lg}) {
     bottom: 0;
  }
  margin: auto;
  max-width: 150px;
`
)

const ImageBottom = styled(Img)`
  width: 100%;
  margin-top: ${({ theme }) => theme.space['--spacing-04']};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 0;
  }
`
const AwardImage = styled(Img)`
  width: 150px;
`
const RenderAward = ({ awards, fluid, alt, awardTitle, index, key }) => (
  <React.Fragment key={key}>
    <Col xsOffset={2} smOffset={0} xs={8} sm={4} lg={3}>
      <AwardContainer
        className={index === awards.length - 1 && 'last'}
        height="100%"
        marginTop={['--spacing-04', 0]}
        flexAlign="topCenter"
      >
        <Box flexAlign="middleCenter">
          <AwardImage fluid={fluid} alt={alt} />
        </Box>
        <Box
          flexAlign="middleCenter"
          marginTop="--spacing-04"
          marginBottom={['--spacing-04', 0]}
          maxWidth="180px"
        >
          <Body variant="--body-01-regular" textAlign="center">
            {awardTitle}
          </Body>
        </Box>
      </AwardContainer>
    </Col>
    <Col xs={2} sm={0} />
  </React.Fragment>
)

const BlockAwardsAchievement = ({
  headerText,
  awards,
  leftTopImage,
  rightBottomImage,
}) => {
  return (
    <Box tag="section">
      <Grid>
        <Row horizontalAlign="center">
          <Col xs={3} sm={0}>
            <ImageTop fluid={leftTopImage.fluid} alt={leftTopImage.alt} />
          </Col>
        </Row>
        <Row gutter={false} horizontalAlign="center" verticalAlign="bottom">
          <Col xs={0} sm={2}>
            <ImageTop fluid={leftTopImage.fluid} alt={leftTopImage.alt} />
          </Col>
          <Col xs={10} sm={7} lg={6} lgOffset={1}>
            <Heading variant="--heading-2" marginY="--spacing-06">
              {headerText}
            </Heading>
          </Col>
          <Col xs={0} sm={2} lg={3} />
        </Row>
        <Row horizontalAlign="center">
          {awards.map(({ image, awardTitle }, i) => (
            <RenderAward
              key={image.fluid.src}
              index={i}
              awards={awards}
              fluid={image.fluid}
              alt={image.alt}
              awardTitle={awardTitle}
            />
          ))}
        </Row>
        <Row horizontalAlign="end">
          <Col xs={0} sm={2}>
            <ImageBottom
              fluid={rightBottomImage.fluid}
              alt={rightBottomImage.alt}
            />
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}

BlockAwardsAchievement.propTypes = {
  headerText: PropTypes.string,
  leftTopImage: imageShape,
  rightBottomImage: imageShape,
  awards: PropTypes.arrayOf(
    PropTypes.shape({
      image: imageShape,
      imageHeader: PropTypes.string,
    })
  ),
}

BlockAwardsAchievement.defaultProps = {
  headerText: '',
  leftTopImage: imageDefaultProps,
  rightBottomImage: imageDefaultProps,
  awards: [{ image: imageDefaultProps, awardTitle: '' }],
}

export default BlockAwardsAchievement
