import React, { useContext } from 'react'
import { ActionsContext } from '../../context'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Box from '@pbds/box'
import { PrimaryButton, SecondaryButton } from '@pbds/buttons'

export const ButtonWithAction = ({
  children,
  buttonTo,
  isPrimary,
  hasArrow,
  location,
  buttonAction,
  useInverseColors,
}) => {
  const { setModalActive } = useContext(ActionsContext)

  const actionsLookup = key =>
    ({
      openModal: { name: 'openModal', action: setModalActive },
    }[key] || null)

  const { name, action } = actionsLookup(buttonAction) || {}
  const params = location?.search
  const isInternalLink = buttonTo && buttonTo.startsWith('/')
  const Button = isPrimary ? PrimaryButton : SecondaryButton
  let to, href, buttonType
  if (action) {
    to = null
    href = null
    buttonType = 'button'
  } else {
    to = isInternalLink ? buttonTo + params : null
    href = isInternalLink ? null : buttonTo
    buttonType = isInternalLink ? Link : 'a'
  }
  return (
    <Box
      marginY={['--spacing-02', '--spacing-03']}
      marginRight={['0', '--spacing-03']}
      width={['100%', 'auto']}
    >
      <Button
        to={to}
        {...(buttonType === 'a'
          ? {
              href,
              target: href?.startsWith('/') ? '_self' : '_blank',
            }
          : { type: 'button' })}
        buttonType={buttonType}
        useInverseColors={useInverseColors}
        buttonSize="--large-button"
        rightIcon={hasArrow ? 'rightArrow' : null}
        width="100%"
        onClick={() => {
          if (!action) return
          if (name === 'openModal') {
            action(prev => !prev)
          }
        }}
      >
        {children}
      </Button>
    </Box>
  )
}

ButtonWithAction.defaultProps = {
  children: '',
  buttonTo: '',
  isPrimary: true,
  hasArrow: false,
  location: {},
  buttonAction: '',
  useInverseColors: false,
}

ButtonWithAction.propTypes = {
  children: PropTypes.node,
  buttonTo: PropTypes.string,
  isPrimary: PropTypes.bool,
  hasArrow: PropTypes.bool,
  location: { search: PropTypes.string },
  buttonAction: PropTypes.string,
  useInverseColors: PropTypes.bool,
}
