const RECAPTCHA_URL = '/.netlify/functions/recaptcha'

const verifyRecaptcha = async ({url = RECAPTCHA_URL, recaptchaResponse})=>{
	const body = JSON.stringify({recaptchaResponse});
    const fetchOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
		body,
	};
	const response = await fetch(url, fetchOptions);
	if (!response.ok) {
		const errorMessage = await response.text();
		throw new Error(errorMessage);
	}
	return response.json();
  }

  export default verifyRecaptcha;