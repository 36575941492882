import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import { ResponsiveMediaWrapper } from './ComponentStyled'
import { imageShape, imageDefaultProps } from '../../shared/proptypes'
import ElemenentMediaOverlay from '../../elements/ElementMediaOverlay'

const ElementMediaPlayer = ({
  initialHeading,
  initialParagraph,
  initialButtonText,
  initialBackgroundImage,
  endHeading,
  endParagraph,
  endLinkText,
  endLinkHref,
  endBackgroundImage,
  youtubeVideoId,
}) => {
  const player = useRef(null)
  const [hasLoaded, setLoaded] = useState(false)
  const [isFinished, setFinished] = useState(false)

  const handleEnded = () => {
    setFinished(true)
    player.current.showPreview()
  }

  const handlePlay = () => !hasLoaded && setLoaded(true)

  const overlayProps = {
    initial: {
      heading: initialHeading,
      subHeading: initialParagraph,
      linkText: initialButtonText,
    },
    ending: {
      heading: endHeading,
      subHeading: endParagraph,
      linkText: endLinkText,
      linkHref: endLinkHref,
    },
  }[isFinished ? 'ending' : 'initial']

  const backgroundImageURL = (isFinished
    ? endBackgroundImage
    : initialBackgroundImage
  ).fluid.src

  return (
    <ResponsiveMediaWrapper
      className={[
        hasLoaded ? 'media-is-loaded' : '',
        isFinished ? 'finished' : '',
      ].join(' ')}
      backgroundImageURL={backgroundImageURL}
    >
      <ReactPlayer
        ref={player}
        width="100%"
        height="100%"
        playing={true}
        controls={true}
        className="player"
        onPlay={handlePlay}
        onEnded={handleEnded}
        light={backgroundImageURL}
        playIcon={<ElemenentMediaOverlay {...overlayProps} />}
        url={`https://www.youtube.com/watch?v=${youtubeVideoId}`}
      />
    </ResponsiveMediaWrapper>
  )
}

ElementMediaPlayer.defaultProps = {
  initialHeading: '',
  initialParagraph: '',
  initialButtonText: '',
  initialBackgroundImage: imageDefaultProps,
  endHeading: '',
  endParagraph: '',
  endLinkText: '',
  endLinkHref: '',
  endBackgroundImage: imageDefaultProps,
  youtubeVideoId: '',
}

ElementMediaPlayer.propTypes = {
  initialHeading: PropTypes.string,
  initialParagraph: PropTypes.string,
  initialButtonText: PropTypes.string,
  initialBackgroundImage: imageShape,
  endHeading: PropTypes.string,
  endParagraph: PropTypes.string,
  endLinkText: PropTypes.string,
  endLinkHre: PropTypes.string,
  endBackgroundImage: imageShape,
  youtubeVideoId: PropTypes.string,
}

export default ElementMediaPlayer
