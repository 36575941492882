import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components'

import Box from '@pbds/box'
import Grid from '@pbds/grid'
import Link from '@pbds/link'
import Typography from '@pbds/typography'
import { Close } from '@pbds/icons'

const { Body } = Typography
const { Row, Col } = Grid

const BoxStyled = styled(Box)(
  ({ color }) => `
  position: relative;
  a span {
    color: ${color};
    text-decoration: underline;
  }

  img {
    vertical-align: text-bottom;
  }
`
)

const CloseButtonStyled = styled.button(
  ({ color }) => `
  position: absolute;
  top: 50%; 
  right: 0;
  border: 0;
  transform: translateY(-50%);
  background: transparent;
  cursor: pointer;
  width: 32px;
  height: 22px;

  svg {
    color: ${color};
    width: 38px;
    height: 38px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
)

const AnnouncementsBanner = ({
  paragraphText,
  linkText,
  linkHref,
  hasCloseButton,
  backgroundColor,
  textColor,
  imageLeft,
  imageRight,
}) => {
  const [isVisible, setVisible] = useState(true)
  const theme = useContext(ThemeContext)

  return isVisible ? (
    <Box
      as="section"
      backgroundColor={backgroundColor.hex || theme.colors['--background-03']}
      padding="--spacing-03"
    >
      <Grid margin={false} gutter={false}>
        <Row>
          <Col>
            <BoxStyled
              flexAlign={['middleLeft', 'middleCenter']}
              flexDirection="row"
              color={textColor.hex || 'white'}
              paddingRight={hasCloseButton ? '--spacing-05' : 0}
              paddingLeft={hasCloseButton ? [0, '--spacing-05'] : 0}
            >
              <Body variant="--body-01-semi-emphasized">
                {imageLeft && imageLeft.url && (
                  <>
                    <img src={imageLeft.url} alt={imageLeft.alt} left={true} />{' '}
                  </>
                )}
                {paragraphText}
                {linkText && linkHref && (
                  <>
                    {' '}
                    <Link to={linkHref}>
                      <Body variant="--body-01-semi-emphasized">
                        {linkText}
                      </Body>
                    </Link>
                  </>
                )}
                {imageRight && imageRight.url && (
                  <>
                    {' '}
                    <img src={imageRight.url} alt={imageRight.alt} />
                  </>
                )}
              </Body>

              {hasCloseButton && (
                <CloseButtonStyled
                  aria-labelledby="banner-close-button-label"
                  color={textColor.hex || 'white'}
                  onClick={() => setVisible(false)}
                >
                  <Close />
                  <span id="banner-close-button-label" hidden>
                    {/* Assistive text */}
                    Close banner
                  </span>
                </CloseButtonStyled>
              )}
            </BoxStyled>
          </Col>
        </Row>
      </Grid>
    </Box>
  ) : null
}

AnnouncementsBanner.defaultProps = {
  paragraphText: '',
  linkText: '',
  linkHref: '',
  backgroundColor: { hex: '' },
  textColor: { hex: '' },
  hasCloseButton: false,
  imageLeft: { url: '', alt: '' },
  imageRight: { url: '', alt: '' },
}

AnnouncementsBanner.propTypes = {
  paragraphText: PropTypes.string,
  linkText: PropTypes.string,
  linkHref: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  hasCloseButton: PropTypes.bool,
  imageLeft: PropTypes.shape({ url: PropTypes.string, alt: PropTypes.string }),
  imageRight: PropTypes.shape({ url: PropTypes.string, alt: PropTypes.string }),
}

export default AnnouncementsBanner
