import Blocknotfound from '../blocks/BlockNotFound'
import Blockawardsachievement from '../blocks/BlockAwardsAchievement'
import Blockawardsbanner from '../blocks/BlockAwardsBanner'
import Blockcardtextcoverimagebanner from '../blocks/BlockCardTextCoverImageBanner'
import Blockcardtextleftimagebanner from '../blocks/BlockCardTextLeftImageBanner'
import Blockcardtextrightimagebanner from '../blocks/BlockCardTextRightImageBanner'
import Blockcardtextleftimageright from '../blocks/BlockCardTextLeftImageRight'
import Blockcasestudiesslider from '../blocks/BlockCaseStudiesSlider'
import Blockcareerscoverbanner from '../blocks/BlockCareersCoverBanner'
import Blockcenteredcardbanner from '../blocks/BlockCenteredCardBanner'
import Blockcenteredsmalltextbanner from '../blocks/BlockCenteredSmallTextBanner'
import Blockdivider from '../blocks/BlockDivider'
import Blockhalfcard from '../blocks/BlockHalfCard'
import Blockhalfhelpform from '../blocks/BlockHalfHelpForm'
import Blockhalftextleftimageright from '../blocks/BlockHalfTextLeftImageRight'
import Blockherobanner from '../blocks/BlockHeroBanner'
import Blockiconstext from '../blocks/BlockIconsText'
import Blockleadershipteam from '../blocks/BlockLeadershipTeam'
import Blockleadform from '../blocks/BlockLeadForm'
import Blockmainhomeslider from '../blocks/BlockMainHomeSlider'
import Blockmarkdown from '../blocks/BlockMarkdown'
import Blockmediacard from '../blocks/BlockMediaCard'
import Blockmerchantdirectory from '../blocks/BlockMerchantDirectory'
import Blockmerchantlogoscarousel from '../blocks/BlockMerchantLogosCarousel'
import Blockmerchantslinkscarousel from '../blocks/BlockMerchantsLinksCarousel'
import Blockmetricscard from '../blocks/BlockMetricsCard'
import Blockmissionicon from '../blocks/BlockMissionIcon'
import Blockmodalform from '../blocks/BlockModalForm'
import Blockourworkculture from '../blocks/BlockOurWorkCulture'
import Blockpaymentsbanner from '../blocks/BlockPaymentsBanner'
import Blockpaymentoption from '../blocks/BlockPaymentOption'
import Blocksimplebutton from '../blocks/BlockSimpleButton'
import Blocksimpleheading from '../blocks/BlockSimpleHeading'
import Blocksimpleimage from '../blocks/BlockSimpleImage'
import Blocksimpleparagraph from '../blocks/BlockSimpleParagraph'
import Blocksingleheading from '../blocks/BlockSingleHeading'
import Blocktextcenteredbanner from '../blocks/BlockTextCenteredBanner'
import Blocktextcenteredtwoimagesbanner from '../blocks/BlockTextCenteredTwoImagesBanner'
import Blocktextisoimage from '../blocks/BlockTextIsoImage'
import Blocktextleftimagerightbanner from '../blocks/BlockTextLeftImageRightBanner'
import Blocktextrightimageleftcarousel from '../blocks/BlockTextRightImageLeftCarousel'
import Blocktitlewithdate from '../blocks/BlockTitleWithDate'
import Blocktwocolumnformlefttwosectionsright from '../blocks/BlockTwoColumnFormLeftTwoSectionsRight'
import Blockreviewcarousel from '../blocks/BlockReviewCarousel'
import Blockpersonquotecarousel from '../blocks/BlockPersonQuoteCarousel'
import Blockvaluescarousel from '../blocks/BlockValuesCarousel'
import Blockvideocampaign from '../blocks/BlockVideoCampaign'
import Blockpageanalytic from '../blocks/BlockPageAnalytic'

// order should be alphabetic
const blocks = {
  Blocknotfound,
  Blockawardsachievement,
  Blockawardsbanner,
  Blockcardtextcoverimagebanner,
  Blockcardtextleftimagebanner,
  Blockcardtextrightimagebanner,
  Blockcardtextleftimageright,
  Blockcareerscoverbanner,
  Blockcasestudiesslider,
  Blockcenteredcardbanner,
  Blockcenteredsmalltextbanner,
  Blockdivider,
  Blockhalfcard,
  Blockhalfhelpform,
  Blockhalftextleftimageright,
  Blockherobanner,
  Blockiconstext,
  Blockleadershipteam,
  Blockleadform,
  Blockmainhomeslider,
  Blockmarkdown,
  Blockmediacard,
  Blockmerchantdirectory,
  Blockmerchantlogoscarousel,
  Blockmerchantslinkscarousel,
  Blockmetricscard,
  Blockmissionicon,
  Blockmodalform,
  Blockourworkculture,
  Blockpageanalytic,
  Blockpaymentoption,
  Blockpaymentsbanner,
  Blocksimplebutton,
  Blocksimpleheading,
  Blocksimpleimage,
  Blocksimpleparagraph,
  Blocksingleheading,
  Blocktextcenteredbanner,
  Blocktextcenteredtwoimagesbanner,
  Blocktextisoimage,
  Blocktextleftimagerightbanner,
  Blocktextrightimageleftcarousel,
  Blocktitlewithdate,
  Blocktwocolumnformlefttwosectionsright,
  Blockreviewcarousel,
  Blockpersonquotecarousel,
  Blockvaluescarousel,
  Blockvideocampaign,
}

const getBlockFromName = name => {
  const Block = blocks[name]
  if (Block) {
    return Block
  }

  return null
}

export default getBlockFromName
