import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@pbds/grid'
import Box from '@pbds/box'
import Typography from '@pbds/typography'
import Link from '@pbds/link'
import { purpleJelly10, ink0 } from '@pbds/core-colors'
import { ButtonWithAction } from '../../shared/ButtonWithAction'

const { Row, Col } = Grid
const { Heading, Deck } = Typography

const BlockTextCenteredBanner = ({
  buttonAction,
  headerText,
  firstParagraph,
  firstLinkText,
  firstLinkHref,
  secondParagraph,
  secondLinkText,
  secondLinkHref,
  buttonText,
  buttonHref,
  isWhiteBackground,
}) => (
  <Box
    as="section"
    backgroundColor={isWhiteBackground ? ink0 : purpleJelly10}
    paddingY={['--spacing-08', '--spacing-08', '--spacing-08', '--spacing-09']}
  >
    <Grid>
      <Row>
        <Col lgOffset={2} lg={8}>
          <Box flexAlign="middleCenter">
            <Heading
              variant="--heading-2"
              textAlign="center"
              marginBottom="--spacing-02"
            >
              {headerText}
            </Heading>
            {firstParagraph ? (
              <Deck textAlign="center" marginBottom="--spacing-04">
                {firstParagraph}
                {firstLinkHref && firstLinkText && (
                  <>
                    {' '}
                    <Link to={firstLinkHref}>{firstLinkText}</Link>
                  </>
                )}
                .
              </Deck>
            ) : null}
            {secondParagraph ? (
              <Deck textAlign="center" marginBottom="--spacing-04">
                {secondParagraph}
                {secondLinkHref && secondLinkText && (
                  <>
                    {' '}
                    <Link to={secondLinkHref}>{secondLinkText}</Link>
                  </>
                )}
                .
              </Deck>
            ) : null}
            <ButtonWithAction
              buttonTo={buttonHref}
              isPrimary={true}
              hasArrow={true}
              buttonAction={buttonAction}
              useInverseColors={false}
            >
              {buttonText}
            </ButtonWithAction>
          </Box>
        </Col>
      </Row>
    </Grid>
  </Box>
)

BlockTextCenteredBanner.propTypes = {
  buttonAction: PropTypes.string,
  headerText: PropTypes.string,
  firstParagraph: PropTypes.string,
  firstLinkText: PropTypes.string,
  firstLinkHref: PropTypes.string,
  isWhiteBackground: PropTypes.string,
  secondParagraph: PropTypes.string,
  secondLinkText: PropTypes.string,
  secondLinkHref: PropTypes.string,
  buttonText: PropTypes.string,
  buttonHref: PropTypes.string,
}

BlockTextCenteredBanner.defaultProps = {
  buttonAction: '',
  headerText: '',
  firstParagraph: '',
  firstLinkText: '',
  firstLinkHref: '',
  isWhiteBackground: false,
  secondParagraph: '',
  secondLinkText: '',
  secondLinkHref: '',
  buttonText: '',
  buttonHref: '',
}
export default BlockTextCenteredBanner
